import { css } from '@emotion/react/macro';

export const styles = {
  titleWrapper: css({
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    gap: '5px',
    fontWeight: 600,
    border: '1px solid #BDBDBD',
    borderRadius: '30px',
    boxSizing: 'border-box',
    padding: '0.125rem 0.75rem',
    marginLeft: '1rem',
    lineHeight: '30px',
}),
  title: css({
    fontSize: '24px',
    fontWeight: 600,
    lineHeight: '36px',
    letterSpacing: '0em',
    textAlign: 'center',
  }),
  tableWrapper: css({
    borderRadius: '30px',
  }),
  cardWrapper: css({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '58px',
  }),
  subtitleWrapper: css({
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    margin: '0 15% 5rem 15%',
    flex: 1,
  }),
  subtitleWrapperModal: css({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '3rem',
    marginTop: '1rem',
    flex: 1,
  }),
  subtitleContainer: css({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    height: 'full',
  }),
  subtitleTitle: css({
    fontWeight: 600,
    fontSize: '14px',
  }),
  subtitleValue: css({
    fontSize: '24px',
    fontWeight: 600,
  }),
  subtitle: css({
    fontSize: '10px',
  }),
  subtitleRevenue: css({
    fontSize: '10px',
    fontWeight: 600,
    color: '#313B5E',
  }),
  projectIcon: css({ height: '24px', width: '24px' }),
  projectName: css({
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    letterSpacing: '0em',
    textAlign: 'left',
  }),
  contentContainer: css({
    justifyContent: 'space-between',
    height: '100%',
    width: '100%',
  }),
  rightColumn: css({ height: '100%' }),
  affix: css({ height: '100%' }),
  icon: css({
    height: '32px',
    width: '32px',
  }),
  divider: css({
    height: '150px',
  }),
  noMargin: css({
    margin: 0,
  }),
  marginTop: css({
    marginTop: 20,
  }),
  subtitleData: css({
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '10px',
    color: '#828282',
    marginTop: '1rem',
  }),
  subtitleDataLeft: css({
    display: 'flex',
    flexDirection: 'column',
  }),
  subtitleDataRight: css({
    fontWeight: 600,
    marginLeft: '2rem',
  }),
  iconBtn: css({
    backgroundColor: 'transparent',
    border: 'none',
    boxShadow: 'none',
    color: '#828282',
  }),
};
