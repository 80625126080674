import { FC } from 'react';
import {
  ArrowsPointingOutIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';
import { Checkbox, Tooltip } from 'antd';
import { useStoreActions, useStoreState } from '../../../../hooks';

const handlePropagation = (e?: MouseEvent): void => {
  e?.stopPropagation();
};

type DefaultCardOptionsProps = {
  moreOptions?: boolean;
  handleMaximize?: () => void;
};

export const DefaultCardOptions: FC<DefaultCardOptionsProps> = ({
  moreOptions = false,
  handleMaximize,
}) => {
  const { t } = useTranslation();
  const { includeReservations } = useStoreState((state) => state.dashboard);
  const { setIncludeReservations } = useStoreActions(
    (actions) => actions.dashboard,
  );
  return (
    <div className='flex gap-4'>
      {moreOptions ? (
        <Tooltip
          placement='bottom'
          trigger='click'
          title={
            <div
              className='flex gap-4'
              onClick={() => setIncludeReservations(!includeReservations)}
            >
              <span>
                {t(
                  'dashboard.more_options.include_reservations',
                  'Include Reservations',
                )}
              </span>
              <Checkbox checked={includeReservations} />
            </div>
          }
        >
          <div role='button' tabIndex={0} onClick={() => handlePropagation()}>
            <EllipsisHorizontalIcon className='stroke-2 w-10 h-10' />
          </div>
        </Tooltip>
      ) : null}
      {handleMaximize && (
        <ArrowsPointingOutIcon
          className='stroke-2 w-10 h-10 cursor-pointer'
          onClick={handleMaximize}
        />
      )}
    </div>
  );
};
