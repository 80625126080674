/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import * as XLSX from 'xlsx';
import { ExportToCsv, Options } from 'export-to-csv';
import { useStoreState, useRegionFormatting, useFlatLayout } from '../../hooks';
import { formatPercent } from '../../utils';
import { ExportDropdown } from '../ExportDropdown/ExportDropdown';
import { roundUpdate } from '../../utils/utils';

const options: Options = {
  filename: `${new Date()
    .toLocaleDateString('en-GB')
    .split('/')
    .reverse()
    .join('')}-price_list`,
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useBom: true,
  useKeysAsHeaders: true,
};

export const csvExporter = new ExportToCsv(options);

type Props = {
  type: 'priceList' | 'priceUpdate';
};

export const PriceExportButton: FC<Props> = ({ type }) => {
  const { getCurrency, getVATText, applyRounding } = useRegionFormatting();
  const withVAT = useStoreState((state) => state.user.profile?.VAT_included);
  const { getLayoutAbbr } = useFlatLayout();
  const priceListData = useStoreState(
    (state) => state.priceList.pricingData?.units,
  );
  const priceUpdateData = useStoreState(
    (state) => state.priceList.updateDataUpdated,
  );
  const pricesDecreases = useStoreState(
    (state) => state.filters.priceDecreases,
  );

  const data = type === 'priceList' ? priceListData : priceUpdateData?.units;

  const mappedData = data?.map(
    ({
      name: internal_id,
      layout,
      floor_area,
      exterior_area,
      floor,
      availability,
      recommended_price,
      current_price,
      update_percentage,
      building_name,
      floor_count,
    }) => {
      const new_price = recommended_price
        ? applyRounding(Number(recommended_price.toFixed(0)))
        : '';
      const update = roundUpdate(
        recommended_price,
        update_percentage,
        current_price,
        applyRounding,
      );
      return {
        id: internal_id,
        layout: getLayoutAbbr(`layout_${layout}`, true),
        floor_area,
        exterior_area: exterior_area ?? 0,
        floor: floor ?? floor_count,
        availability,
        building_name,
        ...{
          ...(withVAT
            ? {
                new_price_with_vat:
                  !pricesDecreases && update < 0
                    ? current_price.toFixed(0)
                    : new_price,
                current_price_with_vat: current_price.toFixed(0),
              }
            : {
                new_price_without_vat:
                  !pricesDecreases && update < 0
                    ? current_price.toFixed(0)
                    : new_price,
                current_price_without_vat: current_price.toFixed(0),
              }),
        },
        currency: getCurrency(),
        price_difference: formatPercent(
          !pricesDecreases && update < 0 ? 0 : update ?? 0,
        ),
      };
    },
  );
  const handleClick = (format: string): void => {
    if (mappedData) {
      if (format === 'csv') {
        csvExporter.options.filename = `${options.filename}${getVATText()}`;
        csvExporter.generateCsv(mappedData);
        return;
      }
      if (format === 'xlsx') {
        const worksheet = XLSX.utils.json_to_sheet(mappedData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
        XLSX.writeFile(workbook, `${options.filename}.xlsx`);
        return;
      }
      const CRMData = data?.map((d) => ({
        internalId: d.name,
        buildingNo: d.building_name,
        floorNo: d.floor,
        price: d.recommended_price ? applyRounding(d.recommended_price) : '',
      }));
      if (CRMData) {
        csvExporter.options.filename = `${options.filename}${getVATText()}`;
        csvExporter.generateCsv(CRMData);
      }
    }
  };
  return (
    <>
      <ExportDropdown type={type} onDownload={handleClick} />
    </>
  );
};
