import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { FC } from 'react';
import clsx from 'clsx';

type Props = {
  checked: boolean;
  onChange?: () => void;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean
}

export const Checkbox: FC<Props> = ({ checked, onChange, style, className, disabled = false }) =>
<CheckCircleIcon
  className={clsx(
    'w-10 h-10 self-center rounded-full',
    checked ? 'text-[#5A72B1]' : 'text-[#D9D9D9]',
    !disabled ? 'hover:shadow-xl hover:text-[#979ba8] cursor-pointer ' : 'cursor-default',
    className,
  )}
  style={style}
  onClick={onChange}
/>;
