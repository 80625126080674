import { Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { SegmentedValue } from 'antd/lib/segmented';
import { ChevronDownIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { useStoreActions, useStoreState } from '../../hooks';
import { FilterEnum, LanguageEnum } from '../../api/enums';

const { Option } = Select;

export const VATSwitch: FC = () => {
  const { t } = useTranslation();
  const { updateProfile } = useStoreActions((action) => action.user);
  const { setIsLoaded } = useStoreActions((action) => action.global);
  const { VAT_included, reserved_as_sold, language } = useStoreState(
    (state) =>
      state.user.profile ?? {
        VAT_included: true,
        reserved_as_sold: true,
        language: LanguageEnum.EN,
      },
  );

  const handleChange = (value: SegmentedValue): void => {
    setIsLoaded(false);
    try {
      updateProfile({
        VAT_included: value === FilterEnum.VAT,
        reserved_as_sold,
        language,
      });
    } catch (error) {
      setIsLoaded(true);
      console.error('Error switching VAT toggle');
    } finally {
      setIsLoaded(true);
    }
  };

  const options = [
    {
      value: FilterEnum.VAT,
      name: t('menu.settings.popover.vat_in_prices.with_vat', 'With VAT'),
    },
    {
      value: FilterEnum.NONVAT,
      name: t(
        'menu.settings.popover.vat_in_prices.without_vat',
        'Without VAT',
      ),
    },
  ];

  return (
    <div className='flex items-center justify-center'>
      <Cog6ToothIcon className='filters-icon' />
      <Select
        bordered={false}
        value={VAT_included ? FilterEnum.VAT : FilterEnum.NONVAT}
        onChange={handleChange}
        suffixIcon={<ChevronDownIcon className='filters-chevron-icon' />}
        dropdownMatchSelectWidth={false}
      >
        {options.map(({ value, name }) => (
          <Option key={value} value={value}>
            {name}
          </Option>
        ))}
      </Select>
    </div>
  );
};
