export const BLUE_1 = '#ccd9ec';
export const BLUE_2 = '#9eb9da';
export const BLUE_3 = '#869cbf';
export const BLUE_4 = '#5a72b1';
export const BLUE_5 = '#313b5e';

export const WHITE = '#fff';

export const GREY_1 = '#C8CBD0';
export const GREY_2 = '#828282';
export const GREY_3 = '#4F4F4F';
export const GREY_4 = '#333333';
export const GREY_5 = '#DEDEDE';

export const NORMAL_FONT_WEIGHT = 400;
export const BOLD_FONT_WEIGHT = 600;

export const SOLD = '#C9CBD0';
export const AVAILABLE = '#5A72B1';
export const RESERVED = '#9EB9DA';

export const COMPETITOR_1 = '#FF7369';
export const COMPETITOR_2 = '#6FCF97';
export const COMPETITOR_3 = '#FFAB54';
export const COMPETITOR_4 = '#A777E9';
export const COMPETITOR_5 = '#BDBDBD';
export const COMPETITOR_6 = '#A48476';
export const COMPETITOR_7 = '#FFDC49';

export const COMPETITOR_COLORS = Object.freeze([
  COMPETITOR_1,
  COMPETITOR_2,
  COMPETITOR_3,
  COMPETITOR_4,
  COMPETITOR_5,
  COMPETITOR_6,
  COMPETITOR_7,
]);
