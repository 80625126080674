import React, { PropsWithChildren } from 'react';
import { Col, Popover, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { Chevron } from '../../../secondaryMarket/blocks/Overview/Chevron';
import { GREY_2 } from '../../../../styles/constants';

interface Props {
  isSummary?: boolean;
  values: {
    old: number | string | null;
    current: number | string | null;
    change: number | string | null;
  };
  currentDate?: string;
  previousDate?: string;
  isOverview?: boolean;
}

export const HistoryPopover: React.FC<PropsWithChildren<Props>> = ({ isSummary = false, values, currentDate, previousDate, isOverview, children }) => {
  const { t } = useTranslation();

  const changeValue = values.change !== null ? parseFloat(values.change as string) : null;
  let icon = null;

  const ignoreSmallChange = changeValue !== null && (isOverview || Math.round(changeValue * 10) / 10 !== 0);

  if (changeValue !== null && !Number.isNaN(changeValue) && ignoreSmallChange) {
    icon = changeValue > 0 ? <CaretUpOutlined style={{ marginLeft: '4px' }} /> : <CaretDownOutlined style={{ color: GREY_2, marginLeft: '4px' }} />;
  } else {
    return (
      <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', fontWeight: isSummary ? 'bold' : 'normal', cursor: 'pointer' }}>
        <p>{children || values.current}</p>
      </span>
    );
  }

  return (
    <Popover content={
      <div style={{ width: '220px' }}>
        <Row>
          <Col span={12}>{previousDate}</Col>
          <Col span={12} style={{ textAlign: 'right' }}>{values.old}</Col>
        </Row>
        <Row>
          <Col span={12}>{currentDate}</Col>
          <Col span={12} style={{ textAlign: 'right' }}>{values.current}</Col>
        </Row>
        <Row>
          <Col span={12}>{t('enums.state.change')}</Col>
          <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {changeValue !== null && !Number.isNaN(changeValue) && (
              <Chevron number={changeValue} />
            )}
          </Col>
        </Row>
      </div>
    }
    >
      <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', fontWeight: isSummary ? 'bold' : 'normal', cursor: 'pointer' }}>
        <p>{children || values.current}</p>
        {!children && icon}
      </span>
    </Popover>
  );
};
