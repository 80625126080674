export enum LanguageEnum {
  SK = 'sk',
  CZ = 'cs',
  EN = 'en',
  DE = 'de',
}
export enum CurrencyEnum {
  EUR = 'EUR',
  CZK = 'CZK',
  USD = 'USD',
  GBP = 'GBP',
}
export enum MetricSystemEnum {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}
export enum TargetType {
  UNITS = 'units',
  FLOOR_AREA = 'floor_area',
  REVENUE = 'revenue',
}
export enum PricePerSMCalcEnum {
  WITH_EXTERIOR = 'half_exteriors',
  WITHOUT_EXTERIOR = 'interiors',
}
export enum MarketDashboardBlockType {
  PRICE_COMPARISON = 'price_comparison',
  SIZE_COMPARISON = 'size_comparison',
  UNIT_MIX = 'unit_mix',
  MARKET_SHARE = 'market_share',
  MARKET_SHARE_SALE_SPEED = 'market_share_sale_speed',
  PRICE_DISTRIBUTION = 'price_distribution',
  MAP = 'map',
  PRICE_HISTORY = 'price_history',
  SALE_SPEED = 'sale_speed',
  SALE_EXPORT = 'sale_export',
  PROJECTS_DETAIL = 'projects_detail',
  OVERVIEW = 'overview',
  DEMAND = 'demand',
  SUPPLY = 'supply',
  SUPPLY_HISTORY = 'supply_history',
  SALE_DETAIL = 'sale_detail',
  FLOOR_OVERVIEW = 'floor_overview',
  DAYS_ON_MARKET = 'days_on_market',
}
export enum Permit {
  REGULAR = 'regular',
  IRREGULAR = 'irregular',
}
export enum MaterialQuality {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}
export enum State {
  SOLD = 'sold',
  RESERVED = 'reserved',
  AVAILABLE = 'available',
  PRICE_UPDATE = 'price_update',
  PRE_RESERVED = 'pre_reserved',
  UNAVAILABLE = 'unavailable',
  LEASED = 'leased',
  RECOMMENDATION = 'recommendation',
}

export enum HouseArea {
  INTERIOR = 'interior',
  EXTERIOR = 'exterior',
  FLOOR = 'floor',
  ORIENTATION = 'orientation',
  LAYOUT = 'layout',
  ANALYTICS = 'analytics',
}

export enum LayoutEnum {
  LAYOUT_1 = 'layout_1',
  LAYOUT_1_5 = 'layout_1_5',
  LAYOUT_2 = 'layout_2',
  LAYOUT_3 = 'layout_3',
  LAYOUT_4 = 'layout_4',
  LAYOUT_5 = 'layout_5',
  LAYOUT_6 = 'layout_6',
  LAYOUT_7 = 'layout_7',
}

export enum MarketDashboardTemplateEnum {
  BASIC = 'basic',
  ADVANCED = 'advanced',
  COMPLETE = 'complete',
}

export enum CountryEnum {
  CZECHIA = 'czechia',
  SLOVAKIA = 'slovakia',
  US = 'us',
  UK = 'uk',
  LATVIA = 'latvia',
  AUSTRIA = 'austria',
  GERMANY = 'germany',
  CROATIA = 'croatia',
}

export enum FilterEnum {
  VAT,
  NONVAT,
  AVAILABLE,
  SOLD,
}

export enum PricesRounding {
  NONE = 'none',
  HUNDREDS = 'hundreds',
  THOUSANDS = 'thousands',
  TENS_OF_THOUSANDS = 'tens_of_thousands',
}

export enum SubareaEnum {
  ALL = 'ALL',
  CITY = 'CITY',
  OUTSKIRT = 'OUTSKIRTS',
}

export enum MarketTypeEnum {
  PRIMARY = 'primary',
  ANALYSIS = 'analysis',
  SECONDARY = 'sale',
  RENTAL = 'rent',
}

export enum BuildingConditionEnum {
  NEW = 'NEW',
  UNKNOWN = 'UNKNOWN',
}

export enum ProjectTypeEnum {
  UNITS = 'flat',
  HOUSES = 'house',
}

export enum ConstructionTypeEnum {
  NEW = 'Newly built',
  RENOVATION = 'Renovation',
}

export enum PriceDetailFilter {
  PRICE = 'price',
  PRICE_PER_SM = 'price_per_sm',
  PERCENTAGE = 'percentage',
}

export enum UpdateDates {
  LAST_5_DAYS = 5,
}
