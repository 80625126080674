import { FC, useEffect, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { Button, DatePicker, InputNumber, Modal, Popover, Select, Spin, Tooltip, message } from 'antd';
import { ChevronDownIcon, TrashIcon } from '@heroicons/react/24/outline';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Checkbox } from '../../../Checkbox/Checkbox';
import { AVAILABLE, SOLD } from '../../../../styles/constants';
import { useRegionFormatting, useStoreActions, useStoreState } from '../../../../hooks';
import { Target, Targets } from '../../../../api/mockData/sales';
import { TargetType } from '../plans/PlanSection';
import { round } from '../../../../utils/formatters';
import styles from './Targets.module.css';

type TargetRowProps = {
  target?: Target;
  targets: Targets;
  isEditing?: boolean;
  removeTarget: () => void;
  onEdit?: () => void;
  onEditCancel?: () => void;
}

const targetTypes: TargetType[] = [
  {
    label: 'Units',
    value: 'units',
  },
  {
    label: 'Floor Area',
    value: 'floor_area',
  },
  {
    label: 'Revenue',
    value: 'revenue',
  },
];

export const TargetRow: FC<TargetRowProps> = ({ target, targets, removeTarget, isEditing, onEdit, onEditCancel }) => {
  const { Option } = Select;
  const { getCurrency, formatBigNumber } = useRegionFormatting();
  const { t } = useTranslation();
  const currency = getCurrency(true);
  const language = useStoreState((state) => state.user.profile?.language);
  const { phaseId, projectId } = useStoreState(
    (state) => state.filters,
  );
  const { updateTargets, createTarget, deleteTarget, fetchTargets, fetchPlanData } = useStoreActions((actions) => actions.sales);
  const [isHovered, setIsHovered] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isDeletingModalOpen, setIsDeletingModalOpen] = useState(false);
  const [targetType, setTargetType] = useState<'units' | 'floor_area' | 'revenue' | null>(target ? target.target_type : null);
  const [targetValueType, setTargetValueType] = useState<string>('');
  const [targetValue, setTargetValue] = useState(target ? target.target_value : null);
  const [deadline, setDeadline] = useState(target ? target.target_month : '');
  const [isChecked, setIsChecked] = useState(target ? target.mandatory : false);
  const [popoverVisible, setPopoverVisible] = useState({ visible: false, content: '' });

  const targetTypeValues: { [key: string]: string } = {
    floor_area: 'm2',
    revenue: currency,
  };

  const targetsLimit = {
    units: targets.maximal_number_of_units,
    floor_area: targets.maximal_floor_area,
    revenue: targets.maximal_revenue,
  };

  useEffect(() => {
    if (target && isChecked !== target.mandatory && !isEditing) {
      setIsChecked(target.mandatory);
    }
  }, [target, isChecked, isEditing]);

  const showPopover = (content: string): void => {
    setPopoverVisible({ visible: true, content });
    setTimeout(() => {
      setPopoverVisible({ visible: false, content });
    }, 3000);
  };

  const calculateRatio = (item: number): string | number => {
    const bigNumberFormatter = (num: number): string => {
      if (num > 1e6) {
        return `${round(num / 1e6, 2)}m`;
      } if (num < 1e6 && num > 1e4) {
        return `${round(num / 1e3, 2)}K`;
      }
        return `${round(num, 2)}`;
    };
    switch (targetType) {
      case 'revenue':
        return bigNumberFormatter(item);
      case 'floor_area':
        return bigNumberFormatter(item);
      default:
        return round(item, 2);
    }
  };

  const formatNumber = (value: number | bigint): string => new Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(value).replace(/,/g, ' ');

  const handleTargetValue = (value: string | number | null): void => {
    const stringValue = value ? value.toString() : '';
    if (/^\d*([.,]?\d+)?%?$/.test(stringValue)) {
      setTargetValue(Number(stringValue));
    }
  };

  const formatter = (value: string | number | undefined): string => value ? `${value}` : '';

  const parser = (value: string | undefined): number => {
    if (value && targetType) {
      const valueDecimal = value.replace(',', '.');
      if (valueDecimal.includes('%')) {
        const numericValue = valueDecimal.replace('%', '');
        if (Number(numericValue) >= 100) {
          showPopover(`100% = ${formatNumber(targetsLimit[targetType])}`);
          return targetsLimit[targetType];
        }
        showPopover(`${numericValue}% * ${formatNumber(targetsLimit[targetType])} = ${formatNumber(round(((parseFloat(numericValue) / 100) * targetsLimit[targetType]), 2))} ${targetType !== 'units' ? targetTypeValues[targetType] : ''}`);
        return round(((parseFloat(numericValue) / 100) * targetsLimit[targetType]), 2);
      }
      return round(parseFloat(valueDecimal), 2);
    }
    return 0;
  };

  const handleTarget = (value: 'units' | 'floor_area' | 'revenue' | null): void => {
    setTargetType(value);
    if (value === 'floor_area') {
      setTargetValueType('m2');
    } else if (value === 'revenue') {
      setTargetValueType(currency);
    } else {
      setTargetValueType('');
    }
    setTargetValue(null);
  };

  const handleSaveTarget = async (): Promise<void> => {
    if (!projectId || !phaseId) {
      message.error(t('sales.error.no_project', 'No project or phase found.'), 1);
      return;
    }
    if (!targetType || !targetValue || !deadline) {
      message.error(t('sales.error.empty_fields', 'Please fill all fields.'), 3);
      return;
    }
    if (targetValue < 1) {
      message.error(t('sales.error.target_null', 'Target value must be greater than 0.'), 3);
      return;
    }
    if (targetType === 'units' && targetValue > targets.maximal_number_of_units) {
      message.error(`${t('sales.error.target_max_units', 'Maximum number of available units is')} ${targets.maximal_number_of_units}.`, 3);
      return;
    }
    if (targetType === 'floor_area' && targetValue > targets.maximal_floor_area) {
      message.error(`${t('sales.error.target_max_floor_area', 'Maximum number of available floor area is')} ${targets.maximal_floor_area}.`, 3);
      return;
    }
    if (targetType === 'revenue' && targetValue > targets.maximal_revenue) {
      message.error(`${t('sales.error.target_max_revenue', 'Maximum number of possible revenue is')} ${formatBigNumber(targets.maximal_revenue)}.`, 3);
      return;
    }
    setIsProcessing(true);
    try {
      const response = await createTarget({
        project_id: projectId,
        phase_id: phaseId,
        target_type: targetType,
        target_value: targetValue,
        target_month: deadline,
        mandatory: isChecked,
      });
      removeTarget();
      if (response.status === 200) {
        fetchTargets();
        fetchPlanData();
      }
    } catch (e) {
      message.error(t('sales.error.target_create', 'Failed to create target.'), 1);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUpdateTarget = async (): Promise<void> => {
    if (!projectId || !phaseId) {
      message.error(t('sales.error.no_project', 'No project or phase found.'), 1);
      return;
    }
    if (!target || !targetValue || !deadline) {
      message.error(t('sales.error.empty_fields', 'Please fill all fields.'), 3);
      return;
    }
    if (targetValue < 1) {
      message.error(t('sales.error.target_null', 'Target value must be greater than 0.'), 3);
      return;
    }
    if (targetType === 'units' && targetValue > targets.maximal_number_of_units) {
      message.error(`${t('sales.error.target_max_units', 'Maximum number of available units is')} ${targets.maximal_number_of_units}.`, 3);
      return;
    }
    if (targetType === 'floor_area' && targetValue > targets.maximal_floor_area) {
      message.error(`${t('sales.error.target_max_floor_area', 'Maximum number of available floor area is')} ${targets.maximal_floor_area}.`, 3);
      return;
    }
    if (targetType === 'revenue' && targetValue > targets.maximal_revenue) {
      message.error(`${t('sales.error.target_max_revenue', 'Maximum number of possible revenue is')} ${targets.maximal_revenue}.`, 3);
      return;
    }
    setIsProcessing(true);
    try {
      const response = await updateTargets({
        sale_target_id: target.id,
        project_id: projectId,
        phase_id: phaseId,
        target_type: target.target_type,
        target_value: targetValue,
        mandatory: isChecked,
        target_month: deadline,
      });
      if (response.status === 200) {
        fetchTargets();
        fetchPlanData();
      }
    } catch (e) {
      message.error(t('sales.error.target_update', 'Failed to update target.'), 1);
    } finally {
      if (onEditCancel) {
        onEditCancel();
      }
      setIsProcessing(false);
    }
  };

  const handleDeleteTarget = async (): Promise<void> => {
    if (!target) {
      message.error(t('sales.error.target_missing', 'No target found.'), 1);
      return;
    }
    if (onEditCancel) {
      onEditCancel();
    }
    setIsDeletingModalOpen(false);
    setIsDeleting(true);
    try {
      const response = await deleteTarget({ sale_target_id: target.id });
      if (response.status === 200) {
        fetchTargets();
        fetchPlanData();
      }
    } catch (e) {
      message.error(t('sales.error.target_delete', 'Failed to delete target.'), 1);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleEditing = (): void => {
    if (target && onEdit) {
      onEdit();
    }
  };

  const handleCancelEditing = (): void => {
    if (target && onEditCancel) {
      setIsChecked(target.mandatory);
      setDeadline(target.target_month);
      setTargetValue(target.target_value);
      onEditCancel();
    }
  };

  // const startDate = dayjs(targets.date_start_sale);
  const endDate = dayjs(targets.date_end_sale);

  return (
    <>
    <div
      className={styles.rowContainer}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {!target ? (
        <>
        <div className={styles.rowFlex}>
          <Select
            bordered={false}
            value={targetType}
            placeholder='Select'
            onChange={handleTarget}
            className='sales-modal-select'
            dropdownMatchSelectWidth={false}
            suffixIcon={<ChevronDownIcon className='text-[#BDBDBD] w-5 h-5 ml-2' />}
          >
            {targetTypes.map((v) => (
              <Option key={v.value} value={v.value}>
                {t(`sales.targets.subtitle.${v.value}`, v.label)}
              </Option>
            ))}
          </Select>
        </div>
        <div className={styles.rowFlexSpaced}>
          <Popover content={<span className={styles.fontBold}>{popoverVisible.content}</span>} open={popoverVisible.visible}>
            <Tooltip title={t('sales.targets.value.description')} placement='bottom'>
              <InputNumber bordered={false} placeholder='0' controls={false} className='pl-2' value={targetValue} parser={parser} formatter={formatter} onChange={(value) => handleTargetValue(value || null)} disabled={!targetType} />
            </Tooltip>
          </Popover>
          { targetValueType && <span className='text-bmgray'>{targetValueType}</span>}
        </div>
        <div className={styles.rowFlexSpaced}>
          <span className={styles.fontBold}>{targetType ? calculateRatio(targetsLimit[targetType]) : '-'}</span>
          {targetType && <span>{targetTypeValues[targetType]}</span>}
        </div>
        <div className={styles.rowFlexCentered}>
          <DatePicker
            bordered={false}
            picker='month'
            suffixIcon={undefined}
            format='MMM YYYY'
            onChange={(date) => setDeadline(date ? date.date(1).format('YYYY-MM-DD') : '')}
            className={styles.rowDatePicker}
            disabledDate={(current) => current > endDate || current < dayjs().add(1, 'month')}
          />
        </div>
        {targetType && (
          <div className={styles.rowFlexCentered}>
            <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
          </div>
        )}
        </>
      ) : (
        <>
          <div className={styles.rowFlex}>
            <span className='font-bold capitalize'>{t(`sales.targets.subtitle.${target.target_type}`)}</span>
          </div>
          {isEditing ? (
            <div className={styles.rowFlexSpaced}>
              <Popover content={<span className={styles.fontBold}>{popoverVisible.content}</span>} open={popoverVisible.visible}>
                <Tooltip title={t('sales.targets.value.description')} placement='bottom'>
                  <InputNumber bordered={false} placeholder='0' controls={false} value={targetValue} parser={parser} formatter={formatter} onChange={(value) => handleTargetValue(value || null)} />
                  <span>{targetTypeValues[target.target_type]}</span>
                </Tooltip>
              </Popover>
            </div>
          ) : (
            <div className={styles.rowFlexSpacedEdit} onClick={() => handleEditing()}>
              <span className={styles.fontBold}>{calculateRatio(target.target_value)}</span>
              <span>{targetTypeValues[target.target_type]}</span>
            </div>
          )}
          <div className={styles.rowFlexSpaced}>
            <span className={styles.fontBold}>{targetType ? calculateRatio(targetsLimit[targetType]) : '-'}</span>
            <span>{targetTypeValues[target.target_type]}</span>
          </div>
          {isEditing ? (
            <div className={styles.rowFlexCentered}>
              <DatePicker
                bordered={false}
                picker='month'
                suffixIcon={undefined}
                format='MMM YYYY'
                value={dayjs(deadline)}
                onChange={(date) => setDeadline(date ? date.date(1).format('YYYY-MM-DD') : target.target_month)}
                className={styles.rowDatePicker}
                disabledDate={(current) => current > endDate || current < dayjs().add(1, 'month')}
              />
            </div>
          ) : (
            <div className={styles.rowFlexSpacedEdit} onClick={() => handleEditing()}>
              <span className={styles.fontBold}>{dayjs(target.target_month).format('MMM YYYY')}</span>
            </div>
          )}
          <div className={styles.rowFlexCentered} onClick={() => handleEditing()}>
            <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />
          </div>
          <div className={styles.rowFlexCenteredSpaced}>
            <PieChart
              lineWidth={60}
              startAngle={-90}
              animate
              className='w-10 h-10'
              data={[
                { value: Math.round(target.progress || 0), color: AVAILABLE },
                { value: 100 - Math.round(target.progress || 0), color: SOLD },
              ]}
            />
            <span className={styles.fontBold}>{Math.round(target.progress || 0)}%</span>
          </div>
        </>
      )}
      {(!target || isHovered || isEditing || isProcessing || isDeleting) && (
        // eslint-disable-next-line no-nested-ternary
        <div className={`absolute ${target ? isEditing ? '-right-[19rem]' : '-right-[2rem]' : '-right-[16rem]'} top-1 flex space-x-4 items-center`}>
          {target ? (
            <>
              {isDeleting ? <Spin /> : <TrashIcon className='w-10 h-10 stroke-2 cursor-pointer text-bmgray' onClick={() => setIsDeletingModalOpen(true)} />}
              {isEditing && (
                <>
                  <Button className='bm-default-btn' onClick={() => handleCancelEditing()}>
                    {t('common.cancel')}
                  </Button>
                  <Button className='bm-default-btn' onClick={() => handleUpdateTarget()} loading={isProcessing}>
                    {t('common.save')}
                  </Button>
                </>
              )}
            </>
           ) : (
            <>
              <Button className='bm-default-btn' onClick={removeTarget}>
                {t('common.cancel')}
              </Button>
              <Button className='bm-default-btn' onClick={() => handleSaveTarget()} loading={isProcessing}>
                {t('common.save')}
              </Button>
            </>
          )}
        </div>
      )}
    </div>
      {isDeletingModalOpen && target && (
        <Modal open={isDeletingModalOpen} footer={null} centered closable={false} className='sales-target-delete'>
          <div className={styles.rowDeleteModalContent}>
            <div className='text-center text-bmgray'>{t('sales.target.delete.message', 'Are you sure you want to delete this target?')}</div>
            <div className={styles.rowDeleteContainer}>
              <div className={styles.rowFlex}>
                <span className='font-bold capitalize'>{t(`sales.targets.subtitle.${target.target_type}`)}</span>
              </div>
              <div className={styles.rowFlexSpaced}>
                <span className={styles.fontBold}>{target.target_type === 'revenue' ? formatBigNumber(target.target_value) : target.target_value}</span>
                {target.target_type === 'floor_area' && <span>{targetTypeValues[target.target_type]}</span>}
              </div>
              <div className={styles.rowFlexCentered}>
                <span className={styles.fontBold}>{dayjs(target.target_month).format('MMM YYYY')}</span>
              </div>
              <div className={styles.rowFlexCentered}>
                <Checkbox checked={isChecked} disabled />
              </div>
              <div className={styles.rowFlexCenteredSpaced}>
              <PieChart
                lineWidth={60}
                startAngle={-90}
                animate
                className={styles.icon}
                data={[
                  { value: Math.round(target.progress || 0), color: AVAILABLE },
                  { value: 100 - Math.round(target.progress || 0), color: SOLD },
                ]}
              />
              <span className={styles.fontBold}>{Math.round(target.progress || 0)}%</span>
              </div>
            </div>
            <div className={styles.rowBtnWrapper}>
              <Button className='bm-default-btn' onClick={() => setIsDeletingModalOpen(false)}>
                {t('common.cancel')}
              </Button>
              <Button className='bm-submit-btn' onClick={() => handleDeleteTarget()} loading={isProcessing}>
                {t('common.confirm')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
};
