import { useState } from 'react';
import { LatLng } from '../utils/types';
import { Territory } from '../store/types';
import { territoriesApi } from '../api/secondary';
import {
  getCountryIsoCode,
  parseGeometry,
  simplifyPolygon,
} from '../utils/utils';
import {
  BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdCountryIsoCodeEnum,
  MultiPolygonalSchema,
  TerritoryGeomsResponseSchema,
} from '../api/secondaryClient';
import { useStoreActions, useStoreState } from '.';

type useTerritorySelectionProps = {
  handleTerritorySelect: (territory: Territory) => Promise<void>;
  loading: boolean;
};

export const useTerritorySelection = (): useTerritorySelectionProps => {
  const { profile } = useStoreState((state) => state.user);
  const { polygons } = useStoreState((state) => state.market);
  const { setPolygons } = useStoreActions((actions) => actions.market);
  const [loading, setLoading] = useState(false);

  const handleTerritorySelect = async (territory: Territory): Promise<void> => {
    if (!profile) return;
    const newPolygons: { key: string; polygon: LatLng[] }[] = [];
    let lastNum = 0;
    let requests: string[] = [];
    let fetching = false;

    for (const [index, id] of (territory?.territoryId ?? []).entries()) {
      if (
        (requests.length === 5 && !fetching) ||
        (territory?.territoryId.length ?? 1) - 1 === index
      ) {
        if ((territory?.territoryId.length ?? 1) - 1 === index) {
          requests.push(id);
        }
        fetching = true;
        setLoading(true);

        // Fetch territories data
        // eslint-disable-next-line no-await-in-loop
        const datasets =
          /* eslint-disable no-await-in-loop */
          (
            await Promise.allSettled([
              ...requests.map(async (req) => {
                try {
                  const response =
                    territoriesApi.territoriesEndpointsGetMultipolygonByTerritoryId(
                      {
                        countryIsoCode: getCountryIsoCode(
                          profile.localization,
                        ) as BuiltmindApiTerritoriesEndpointsGetMultipolygonByTerritoryIdCountryIsoCodeEnum,
                        territoryId: req,
                      },
                    );
                  return response;
                } catch (err) {
                  console.log(err);
                  return {} as TerritoryGeomsResponseSchema;
                }
              }),
            ])
          ).map((res) => {
            if (res.status === 'fulfilled') {
              return res.value;
            }
            return {} as TerritoryGeomsResponseSchema;
          });

        /* eslint-disable no-loop-func */
        datasets.forEach((dataset) => {
          const currentPolygons = Object.keys(polygons);
          if (currentPolygons.length > 0) {
            const lastPoly = currentPolygons[currentPolygons.length - 1];
            if (lastNum === 0) {
              lastNum = Number(lastPoly.split('polygon-')[1]);
            }
          }
          lastNum += 1;

          dataset.geom.coordinates = [
            [simplifyPolygon(dataset.geom.coordinates[0][0])],
          ];
          const { parsedPolygons } = parseGeometry(
            dataset.geom as MultiPolygonalSchema,
          );

          const polygon = parsedPolygons['Custom polygon 1'];

          newPolygons.push({
            key: dataset.name ?? `Custom polygon ${lastNum}`,
            polygon,
          });
        });
        requests = [id];
        fetching = false;
        setLoading(false);
      } else {
        requests.push(id);
      }
    }

    const finalPolygons = newPolygons.reduce((result, curr) => {
      result[curr.key] = curr.polygon;
      return result;
    }, {} as Record<string, LatLng[]>);

    if (Object.keys(polygons).length > 0) {
      setPolygons(
        JSON.parse(JSON.stringify({ ...polygons, ...finalPolygons })),
      );
    } else {
      setPolygons(JSON.parse(JSON.stringify(finalPolygons)));
    }
  };

  return {
    handleTerritorySelect,
    loading,
  };
};
