import { FC, useMemo } from 'react';
import { Pie, PieConfig } from '@ant-design/plots';
import { Empty, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlatLayout, useStoreState } from '../../hooks';
import { formatPercent, getSum } from '../../utils';
import { Availability } from '../../store/types';
import { BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5 } from '../../styles/constants';
import { FilterEnum, useFilter } from './useFilter';
import { ChartCard } from '../ChartCard';
import { getPieOptions } from '../../utils/defaultChartConfig';
import { useReservedData } from '../../hooks/useReservedData';
import { sortByString } from '../../utils/utils';
import { CardPopover } from '../market/blocks/CardPopover';

interface Data {
  layout: string;
  availability: string;
  available: number;
}

export const UnitMix: FC = () => {
  const { filterValue, handleChange } = useFilter();
  const data: Data[] = useStoreState((state) =>
    state.project.aggregatedTableData.map(
      ({ layout, availability, available }) => ({
        layout,
        availability,
        available,
      }),
    ),
  );
  const { displayValues } = useStoreState((state) => state.filters);
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);

  const { getLayout } = useFlatLayout();
  const { t } = useTranslation();
  const { mapReservedData } = useReservedData();

  const chartData: Data[] = useMemo(() => {
    const mappedData = mapReservedData(data);
    const getReduceCallback =
      (availabilityValue: string) =>
      (previous: Data[], current: Data): Data[] => {
        const index = previous.findIndex((v) => v.layout === current.layout);
        if (index > -1) {
          const newArr: Data[] = [...previous];
          const { availability, available, layout } = previous[index];
          const newData: Data = {
            availability,
            available: available + current.available,
            layout,
          };
          newArr[index] = newData;
          return newArr;
        }
        return [...previous, { ...current, availability: availabilityValue }];
      };
    switch (filterValue) {
      case FilterEnum.AVAILABLE:
        return mappedData
          .filter(({ availability }) => availability === Availability.AVAILABLE)
          .reduce(getReduceCallback(Availability.AVAILABLE), [])
          .sort((a, b) => sortByString(a.layout, b.layout));
      case FilterEnum.SOLD:
        return mappedData
          .filter(({ availability }) => availability === Availability.SOLD)
          .reduce(getReduceCallback(Availability.SOLD), [])
          .sort((a, b) => sortByString(a.layout, b.layout));
      case FilterEnum.TOTAL:
        return mappedData
          .reduce(getReduceCallback('total'), [])
          .sort((a, b) => sortByString(a.layout, b.layout));
      default:
        return [];
    }
  }, [data, filterValue, mapReservedData]);

  const total = getSum(chartData.map((v) => v.available));

  const config: PieConfig = getPieOptions({
    appendPadding: 10,
    data: chartData,
    angleField: 'available',
    colorField: 'layout',
    label: displayValues
      ? {
          type: 'spider',
          content: ({ percent }) =>
            `${Math.round(percent * 100 * 10) / 10} %`,
        }
      : undefined,
    legend: {
      itemName: {
        formatter: (value) => getLayout(value),
      },
    },
    color: [BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5],
    tooltip: {
      customContent: (title, values) =>
        `<div class="bm-tooltip-2">
            <div class="bmt-title">${getLayout(title)}</div>
            <div class="bmt-subtitle">${values[0]?.value}/${total}</div>
            <div class="bmt-content">${formatPercent(
              (values[0]?.value ?? 0) / total,
            )}</div>
                </div>`,
    },
  });

  return (
    <ChartCard
      title={t('project.unit_mix.title', 'Unit Mix')}
      extra={<CardPopover showValues />}
      zoomInHeader
      subtitle={t(
        'project.unit_mix.subtitle',
        'The comparison of the unit mix of  the available and sold units in the project.',
      )}
      chart={
        chartData.length > 0 ? (
          <Pie className='chart-container' {...config} />
        ) : (
          <Empty className='center' image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      controls={
        <Segmented
          value={filterValue}
          options={[
            {
              value: FilterEnum.AVAILABLE,
              label: t('enums.state.available', 'Available'),
            },
            {
              value: FilterEnum.SOLD,
              label: isSold
                ? t('enums.state.sales_reservations')
                : t('enums.state.sales'),
            },
            {
              value: FilterEnum.TOTAL,
              label: t('project.unit_mix.total', 'Total'),
            },
          ]}
          onChange={handleChange}
        />
      }
    />
  );
};
