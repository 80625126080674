import React, { useEffect, useMemo } from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import { Page } from '../components';
import { useBreakpoints, useStoreActions, useStoreState } from '../hooks';
import { DashboardFilters } from '../components/dashboard/DashboardFilters';
import { SubscribePage } from '../components/SubscribePage/SubscribePage';
import { Sales } from '../components/dashboard/sales/Sales';
import { Metrics } from '../components/dashboard/sales/metrics/Metrics';
import { Layouts } from '../components/dashboard/sales/layouts/Layouts';
import { SalesByFloorArea } from '../components/dashboard/SalesByFloorArea';
import { PriceUpdates } from '../components/dashboard/PriceUpdates';
import { FloorOverviewUpdated } from '../components/dashboard/FloorOverviewUpdated';
import { SaleProgressUpdated } from '../components/dashboard/saleProgressUpdated/SaleProgressUpdated';
import { Positioning } from '../components/dashboard/positioning/Positioning';

export const DashboardPage: React.FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const { projectId, isLoading } = useStoreState((state) => state.filters);
  const { t } = useTranslation();
  const { fetchDashboardData } = useStoreActions(
    (actions) => actions.dashboard,
  );
  const { fetchTargets, fetchPlanData } = useStoreActions(
    (actions) => actions.sales,
  );
  const VAT_included = useStoreState(
    (state) => state.user.profile?.VAT_included,
  );
  const targets = useStoreState((state) =>
    state.sales.targetsData?.projects.find(
      (item) => item.project_id === projectId,
    ),
  );
  const planData = useStoreState(
    (state) =>
      state.sales.planData?.projects.find(
        (item) => item.project_id === projectId,
      )?.sale_plan,
  );
  const isDashboardEnabled = useStoreState(
    (state) => state.user.profile?.dashboard_enabled,
  );

  const overviewData = useStoreState((state) => state.filters.overviewData);
  const projectUpdate = useMemo(
    () =>
      overviewData?.updates.find((update) => update.project_id === projectId),
    [overviewData, projectId],
  );
  const fetchUpdateData = useStoreActions(
    (actions) => actions.priceList.fetchUpdateDataUpdated,
  );

  useEffect(() => {
    if (projectId) {
      fetchDashboardData({ projectId });
      if (projectUpdate) {
        fetchUpdateData({ projectId });
      }
    }
  }, [projectId, VAT_included]);

  useEffect(() => {
    if (!targets) {
      fetchTargets();
    }
  }, [fetchTargets, isLoading, targets]);

  useEffect(() => {
    if (!planData) {
      fetchPlanData();
    }
  }, [fetchPlanData, isLoading, planData]);

  const breakpoints = {
    xs: 24,
    lg: 12,
    xxl: 6,
  };

  return (
    <Page
      pageClassname='dashboard'
      title={!isSmallScreen && t('dashboard.title')}
      filters={isDashboardEnabled && <DashboardFilters />}
    >
      {isDashboardEnabled ? (
        <Row className='dashboard-container' gutter={[16, 16]}>
          <Col {...breakpoints}>
            <Metrics />
          </Col>
          <Col {...breakpoints}>
            <Layouts />
          </Col>
          <Col {...breakpoints}>
            <Sales targetsData={targets} planData={planData} type='plan' />
          </Col>
          <Col {...breakpoints}>
            <Sales targetsData={targets} planData={planData} type='targets' />
          </Col>
          <Col xs={24} xl={12}>
            <SaleProgressUpdated />
          </Col>
          <Col xs={24} xl={12}>
            <Positioning />
          </Col>
          <Col xs={24} xl={12} xxl={8}>
            <PriceUpdates />
          </Col>
          <Col xs={24} xl={12} xxl={8}>
            <SalesByFloorArea />
          </Col>
          <Col xs={24} xl={12} xxl={8}>
            <FloorOverviewUpdated />
          </Col>
        </Row>
      ) : (
        <SubscribePage featureType='dashboard' />
      )}
    </Page>
  );
};
