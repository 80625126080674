import { FC } from 'react';
import { Empty } from 'antd';
import { Bar, BarConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { formatPercent } from '../../../../utils';
import { useFlatLayout, useStoreState } from '../../../../hooks';
import { AVAILABLE, RESERVED, SOLD } from '../../../../styles/constants';
import { getBarOptions } from '../../../../utils/defaultChartConfig';
import { Data } from '.';
import { SaleDetailData } from '../../../../api/types';

type Props = {
  data: SaleDetailData[];
};

export const SaleDetailChart: FC<Props> = ({ data }) => {
  const { t } = useTranslation();
  const { getLayout } = useFlatLayout();
  const { displayValues } = useStoreState((state) => state.filters);
  const config: BarConfig = getBarOptions({
    data,
    isStack: true,
    isPercent: true,
    xField: 'value',
    yField: 'category',
    seriesField: 'state',
    color: ({ state }) => {
      switch (state) {
        case 'available':
          return AVAILABLE;
        case 'reserved':
          return RESERVED;
        default:
          return SOLD;
      }
    },
    meta: {
      state: {
        alias: 'State',
        values: ['available', 'reserved', 'sold'],
      },
    },
    label: displayValues ? {
      content: ({ value, state }) => {
        if (state === 'reserved') return '';
        return `${Math.round(value * 100 * 10) / 10} %`;
      },
    } : undefined,
    legend: {
      itemName: {
        formatter: (value) => t(`charts.state.${value}`),
      },
      padding: [20, 0, 0, 0],
      position: 'bottom',
    },
    yAxis: {
      label: {
        formatter: (value) => getLayout(value),
      },
    },
    tooltip: {
      formatter: (passedData) => {
        const value = passedData as Data;
        return {
          name: t(`charts.state.${value.state}`),
          value: formatPercent(value.value),
        };
      },
      title: (title) => getLayout(title),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color}"></div>
              <div class="bmt-label">${item?.name}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
  });
  return data.length > 0 ? (
    <Bar {...config} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
