import { FC, useCallback, useEffect, useMemo } from 'react';
import { BuildingOfficeIcon, DocumentPlusIcon } from '@heroicons/react/24/outline';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MenuItem, SidebarMenu } from './SidebarMenu';
import { useStoreActions, useStoreState } from '../hooks';
import { Paths } from '../routes/Paths';

type Props = {
  isPriceUpdate?: boolean;
  type: 'dashboard' | 'price-list' | 'price-update';
}

export const ProjectSelectMenu: FC<Props> = ({ isPriceUpdate = false, type }) => {
  const { t } = useTranslation();
  const { overviewData, projectId } = useStoreState(
    (state) => state.filters,
  );
  const location = useLocation();
  const navigate = useNavigate();
  const { setProjectId } = useStoreActions((actions) => actions.filters);
  const { setIsLoaded } = useStoreActions((actions) => actions.global);

  const projectsItems = useMemo(() => (
    isPriceUpdate
    ? overviewData?.updates
    : overviewData?.projects) || [],
    [isPriceUpdate, overviewData],
  );

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const isValidOption = projectsItems.find((option) => option.project_id === projectId);
    if (!isValidOption && projectsItems.length > 0) {
      setProjectId(projectsItems[0].project_id);
    }
  }, [projectsItems, projectId, setProjectId]);

  const isItemActive = useCallback(
    (item: MenuItem) => {
      if (location.pathname !== `/${type}`) {
        return false;
      }
      if (item.type === 'button') {
        return item.key === projectId?.toString();
      }
      return false;
    },
    [location.pathname, type, projectId],
  );

  const handleProjectSelect = useCallback((id: number): void => {
    try {
      setIsLoaded(false);
      if (location.pathname !== `/${type}`) {
        navigate(`/${type}`);
      }
      if (id !== projectId) {
        setProjectId(id);
      }
    } catch (error) {
      setIsLoaded(true);
      console.error('Error loading projectId');
    } finally {
      setIsLoaded(true);
    }
  }, [location.pathname, navigate, projectId, setIsLoaded, setProjectId, type]);

  const items: MenuItem[] = useMemo(
    () =>
      projectsItems.map((project, idx) => ({
        type: 'button',
        key: `${project.project_id}`,
        label: `${project.name}`,
        sortIdx: idx,
        onClick: () => handleProjectSelect(project.project_id),
        icon: <BuildingOfficeIcon className='filters-icon' />,
        inactiveClass: 'font-normal',
      })) ?? [],
    [handleProjectSelect, projectsItems],
  );

  // const addReportButton: MenuItem =
  //   {
  //     type: 'button',
  //     key: 'add-new-project',
  //     label: t('common.add_project', 'Add Project'),
  //     onClick: () => navigate(Paths.DYNAMIC_PRICING_ONBOARDING),
  //     icon: <DocumentPlusIcon className='filters-icon' />,
  //   };

  return (
    <SidebarMenu items={items} itemSize='medium' isItemActive={isItemActive} />
  );
};
