import MapIcon from '@heroicons/react/24/outline/MapIcon';
import { Select, message } from 'antd';
import { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../hooks';
import { getUpdateFrequencyDates, sortByString } from '../../utils/utils';
import { Mode } from '../../store/types';
import { MergedReport } from '../../components/market/reports';
import { MarketTypeEnum } from '../../api/enums';
import { ExportDropdown } from '../../components/ExportDropdown/ExportDropdown';
import { ProjectsExport } from './ProjectsExport';
import { VATSwitch } from '../../components/VATSwitch/VATSwitch';

export const MarketProjectsFilters: FC = () => {
  const { t } = useTranslation();
  const reportsOrder = useStoreState((state) => state.market.reportsOrder);
  const marketDashboards = useStoreState(
    (state) => state.filters.overviewData?.market_dashboards,
  );
  const SMReports = useStoreState((state) => state.secondaryMarket.reports);

  const secondaryReports = useMemo(
    () =>
      SMReports?.filter(
        (report) => report.reportType === MarketTypeEnum.SECONDARY,
      ),
    [SMReports],
  );

  const rentalReports = useMemo(
    () =>
      SMReports?.filter(
        (report) => report.reportType === MarketTypeEnum.RENTAL,
      ),
    [SMReports],
  );

  const sortReportsByOrder = (
    reports: MergedReport[],
    order: string[],
  ): MergedReport[] => {
    if (order.length === 0) return reports;
    const orderMap = new Map(order.map((name, index) => [name, index]));
    return reports.sort(
      (a, b) =>
        (orderMap.get(a.name) ?? Number.MAX_SAFE_INTEGER) -
        (orderMap.get(b.name) ?? Number.MAX_SAFE_INTEGER),
    );
  };

  const mergedReports: MergedReport[] = useMemo(() => {
    const merged = [
      ...(marketDashboards || []),
      ...(secondaryReports || []),
      ...(rentalReports || []),
    ].reduce((acc, report) => {
      const mergedReport: MergedReport = {
        name: report.name,
        primary: null,
        secondary: null,
        rental: null,
      };
      if (acc.find((v) => v.name === report.name)) {
        return acc;
      }
      const pReport = marketDashboards?.find((v) => v.name === report.name);
      const sReport = secondaryReports?.find((v) => v.name === report.name);
      const rReport = rentalReports?.find((v) => v.name === report.name);
      if (pReport) {
        mergedReport.primary = pReport;
      }
      if (sReport) {
        mergedReport.secondary = sReport;
      }
      if (rReport) {
        mergedReport.rental = rReport;
      }
      return [...acc, mergedReport];
    }, [] as MergedReport[]);

    return merged;
  }, [marketDashboards, secondaryReports, rentalReports]);

  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const paramsSM = useStoreState((state) => state.filters.paramsSM);
  const setParamsPM = useStoreActions((actions) => actions.filters.setParamsPM);
  const setParamsSM = useStoreActions((actions) => actions.filters.setParamsSM);
  const setActiveReportId = useStoreActions(
    (actions) => actions.secondaryMarket.setActiveReportId,
  );

  const setMode = useStoreActions((actions) => actions.market.setMode);
  const mode = useStoreState((state) => state.market.mode);
  const setSelectedReportName = useStoreActions(
    (actions) => actions.filters.setSelectedReportName,
  );
  const selectedReportName = useStoreState(
    (state) => state.filters.selectedReportName,
  );

  const resetPolygons = useStoreActions(
    (actions) => actions.market.resetPolygons,
  );

  const overviewData = useStoreState((state) => state.filters.overviewData);
  const { market_dashboard_frequency: frequency = 'daily' } =
    overviewData || {};

  const handleReportChange = useCallback(
    (value: string) => {
      const report = mergedReports?.find(
        (mergedReport) => mergedReport.name === value,
      );

      if (report) {
        const { name, primary, secondary, rental } = report;
        const validDates =
          primary?.dates &&
          getUpdateFrequencyDates(primary.dates.sort(sortByString), frequency);
        const primaryInitialDate =
          validDates && validDates[validDates.length - 1];

        setMode(Mode.READ);
        resetPolygons();
        if (primary && (!primary.dates || primary.dates.length === 0)) {
          message.error(
            t('market.error', 'Data is not ready yet, try again later.'),
          );
          return;
        }

        setSelectedReportName(name);
        if (primaryInitialDate && primary) {
          setParamsPM({
            dashboardId: primary.id,
            date: primaryInitialDate,
            name,
          });
        } else {
          setParamsPM(null);
        }

        const reportTypes = [
          ...(primary ? [MarketTypeEnum.PRIMARY] : []),
          ...(secondary ? [MarketTypeEnum.SECONDARY] : []),
          ...(rental ? [MarketTypeEnum.RENTAL] : []),
        ];

        if (secondary?.reportId || rental?.reportId) {
          setParamsSM({
            reportId: secondary?.reportId || rental?.reportId || '',
            name,
            reportTypes,
            date: paramsSM?.date || new Date().toISOString().split('T')[0],
          });
          setActiveReportId(secondary?.reportId || rental?.reportId || '');
        } else {
          setParamsSM(null);
        }
      }
    },
    [
      mergedReports,
      frequency,
      setMode,
      resetPolygons,
      setSelectedReportName,
      setParamsPM,
      setParamsSM,
      setActiveReportId,
      t,
    ],
  );

  useEffect(() => {
    const firstReport = sortReportsByOrder(
      mergedReports.filter((report) => !!report.primary),
      reportsOrder,
    )[0];
    setSelectedReportName(firstReport?.name);
  }, [mergedReports, reportsOrder]);

  const hasMounted = useRef(false);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    const report =
      selectedReportName ||
      sortReportsByOrder(mergedReports, reportsOrder)?.[0]?.name;
    if (report && mode === Mode.READ) {
      handleReportChange(report);
    }
  }, [mergedReports, handleReportChange, selectedReportName, mode]);

  return (
    <div className='flex justify-between w-full'>
      {mode === Mode.READ && (
          <div className='flex'>
            <MapIcon className='w-8 stroke-2 ml-8' />
            <Select
              dropdownMatchSelectWidth={false}
              bordered={false}
              value={selectedReportName}
              onChange={handleReportChange}
            >
              {sortReportsByOrder(
                mergedReports.filter((report) => !!report.primary),
                reportsOrder,
              ).map((report) => (
                <Select.Option key={report.name} value={report.name}>
                  {report.name}
                </Select.Option>
              ))}
            </Select>
          </div>
      )}
      {mode === Mode.READ && (
        <div className='flex'>
          <VATSwitch />
          <ProjectsExport />
        </div>
      )}
    </div>
  );
};
