/** @jsxImportSource @emotion/react */
import { FC, ReactNode, RefObject, useEffect, useMemo, useState } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import * as Plots from '@ant-design/plots';
import dayjs from 'dayjs';
import { Empty } from 'antd';
import { Card, CardProps } from '../Card';
import { Zoom } from '../Zoom';
import { styles } from './styles';
import { useReservedData } from '../../hooks/useReservedData';
import { PriceType } from '../market/blocks/PriceHistory';
import { Data as SaleSpeedType } from '../market/blocks/SaleSpeed/SaleSpeedChart';

type ChartConfig = {
  [K in keyof typeof Plots as K extends `${string}Config`
    ? never
    : K]: typeof Plots[K] extends React.ForwardRefExoticComponent<infer P>
    ? P
    : never;
};

type Props<
  T extends { date: string } = { date: string },
  C extends keyof ChartConfig = keyof ChartConfig
> = {
  chart: C;
  CustomComponent?: any & Record<string, unknown>;
  config?: Omit<ChartConfig[C], 'data'>;
  data: T[] | null;
  modalData?: SaleSpeedType;
  isOverall?: boolean;
  controls?: ReactNode;
  showPrivate?: boolean;
  showB2B?: boolean;
  isModalVisible?: boolean;
  setIsModalVisible?: (value: boolean) => void;
  isSold?: boolean;
  infoPopoverContent?: ReactNode;
  infoPopoverTitle?: string;
  showZoom?: boolean;
  zoomInHeader?: boolean;
  priceType?: PriceType;
  ref?: RefObject<HTMLDivElement>;
  date?: string,
} & CardProps;

export const PaginatedChartCard: FC<Props> = ({
  chart,
  CustomComponent,
  data,
  config,
  controls,
  isSold,
  infoPopoverContent,
  infoPopoverTitle,
  zoomInHeader = true,
  showZoom = true,
  extra,
  ref,
  ...rest
}) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const ChartComponent = Plots[chart] as React.FC<any>;
  const { paginateItemsByYearRange } = useReservedData();
  const paginatedData = data ? paginateItemsByYearRange(data) : null;
  const pagesCount = useMemo(() => paginatedData ? Object.keys(paginatedData).length : 1, [paginatedData]);
  const [page, setPage] = useState(1);

  const onPagination = (dir: 'prev' | 'next'): void => {
    if (dir === 'prev') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  useEffect(() => {
    if (page > pagesCount) {
      setPage(1);
    }
  }, [page, pagesCount]);

  const chartData = paginatedData && paginatedData[page] ? paginatedData[page].sort((a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()) : [];
  const getComponent = (): ReactNode => {
    if (paginatedData && paginatedData[page]) {
      if (CustomComponent) {
        return (
          <CustomComponent
            data={chartData}
            config={config}
            isSold={isSold}
            {...rest}
          />
        );
      }
      if (config) {
        return <ChartComponent {...config} data={chartData} />;
      }
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };

  return (
    <Card
      ref={ref}
      css={styles.card}
      infoPopoverContent={infoPopoverContent}
      infoPopoverTitle={infoPopoverTitle}
      {...rest}
      titleExtra={
        <div className='flex items-center space-x-2'>
        {showZoom && paginatedData && (
          <div css={styles.searchContainer}>
            <Zoom>
              <Card css={styles.card} {...rest}>
                <div css={styles.chartContainerPaginated}>
                  {page !== pagesCount && <ChevronLeftIcon className='absolute left-0 top-[40%] chevron-paginate' onClick={() => onPagination('prev')} />}
                    {getComponent()}
                  {page !== 1 && <ChevronRightIcon className='absolute right-0 top-[40%] chevron-paginate' onClick={() => onPagination('next')} />}
                </div>
              </Card>
            </Zoom>
          </div>
        )}
        {extra}
        </div>
      }
    >
      <div css={styles.controlsContainer}>
        {controls}
        {!zoomInHeader && showZoom && paginatedData && (
          <div css={styles.searchContainer}>
            <Zoom>
              <Card css={styles.card} {...rest}>
                <div css={styles.chartContainerPaginated}>
                  {page !== pagesCount && <ChevronLeftIcon className='absolute left-0 top-[40%] chevron-paginate' onClick={() => onPagination('prev')} />}
                    {getComponent()}
                  {page !== 1 && <ChevronRightIcon className='absolute right-0 top-[40%] chevron-paginate' onClick={() => onPagination('next')} />}
                </div>
              </Card>
            </Zoom>
          </div>
        )}
      </div>
        <div css={styles.chartContainerPaginated}>
        {page < pagesCount && <ChevronLeftIcon className='absolute left-0 top-[40%] chevron-paginate' onClick={() => onPagination('prev')} />}
          {getComponent()}
        {page !== 1 && <ChevronRightIcon className='absolute right-0 top-[40%] chevron-paginate' onClick={() => onPagination('next')} />}
        </div>
    </Card>
  );
};
