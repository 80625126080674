import {
  ChevronDoubleUpIcon,
  ChevronDoubleDownIcon,
  CheckCircleIcon as CheckFilledIcon,
} from '@heroicons/react/24/solid';
import {
  BuildingOfficeIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { message, Spin, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import {
  Dispatch,
  FC,
  SetStateAction,
  useRef,
  useState,
  useEffect,
} from 'react';
import { useStoreActions, useStoreState } from '../../../hooks';

type InputProps = {
  setSearchValue: Dispatch<SetStateAction<string>>;
};

const Input: FC<InputProps> = ({ setSearchValue }) => {
  const { t } = useTranslation();

  return (
    <div className='flex'>
      <MagnifyingGlassIcon
        aria-hidden='true'
        className='w-8 stroke-2 text-[#BDBDBD]'
      />
      <input
        id='name'
        name='name'
        type='text'
        placeholder={t('menu.search')}
        className='block w-full bg-white px-4 py-1.5 placeholder:text-[#BDBDBD] focus:outline focus:outline-0'
        onChange={(e) => setSearchValue(e.target.value)}
      />
    </div>
  );
};

export const AddCompetitors: FC = () => {
  const { t } = useTranslation();

  const projectId = useStoreState((state) => state.filters.projectId ?? 0);
  const positioning = useStoreState(
    (state) => state.dashboard.dashboardData?.positioning,
  );
  const updatingCompetitors = useStoreState(
    (state) => state.dashboard.updatingCompetitors,
  );
  const updateSelectedCompetitors = useStoreActions(
    (actions) => actions.dashboard.updateSelectedCompetitors,
  );

  const potentialCompetitors = positioning?.potential_competitors ?? [];
  const selectedCompetitors = positioning?.selected_competitors ?? [];

  const [clickedCompetitor, setClickedCompetitor] = useState(-1);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const dropdownRef = useRef<HTMLDivElement>(null);

  const filteredPotentialCompetitors = potentialCompetitors.filter(({ name }) =>
    name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(
        searchValue
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      ),
  );

  const filteredselectedCompetitors = selectedCompetitors.filter(({ name }) =>
    name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .includes(
        searchValue
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      ),
  );

  const addSelectedCompetitor = async (competitorId: number): Promise<void> => {
    setClickedCompetitor(competitorId);
    if (selectedCompetitors.length >= 5) {
      message.info('You can’t select more than 5 competitors');
      return;
    }

    const updatedCompetitors = [
      ...selectedCompetitors.map(({ id }) => id),
      competitorId,
    ];

    await updateSelectedCompetitors({
      projectId,
      competitors: updatedCompetitors,
    });
    setClickedCompetitor(-1);
  };

  const removeSelectedCompetitor = async (
    competitorId: number,
  ): Promise<void> => {
    setClickedCompetitor(competitorId);
    const updatedCompetitors = selectedCompetitors
      .filter((selectedCompetitor) => selectedCompetitor.id !== competitorId)
      .map(({ id }) => id);

    await updateSelectedCompetitors({
      projectId,
      competitors: updatedCompetitors,
    });
    setClickedCompetitor(-1);
  };

  const handleOpen = (): void => {
    setOpen((prev) => !prev);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className='relative pointer-events-auto h-fit' ref={dropdownRef}>
      {/* Dropdown Content */}
      {open && (
        <div className='absolute z-50 top-full left-0 w-full bg-white drop-shadow-lg border border-bmgrey rounded-b-[15px] flex flex-col overflow-hidden truncate'>
          <div className='flex gap-4 border-b border-bmlightgrey px-4 py-1 text-bmblue font-bold'>
            <Input setSearchValue={setSearchValue} />
          </div>

          {/* Pinned Selected Competitors */}
          <div className='border-y border-bmlightgrey bg-white'>
            {filteredselectedCompetitors.map(({ name, id }) => (
              <div
                key={id}
                className={clsx(
                  'flex gap-4 font-bold px-4 py-1 text-bmblue cursor-pointer',
                )}
                /* eslint-disable react/jsx-curly-newline */
                onClick={() =>
                  !updatingCompetitors && removeSelectedCompetitor(id)
                }
              >
                <div className='w-10 h-10 flex-shrink-0'>
                  {updatingCompetitors && clickedCompetitor === id ? (
                    <div className='flex items-center justify-center w-full h-full pt-2'>
                      <Spin />
                    </div>
                  ) : (
                    <CheckFilledIcon className='w-10 text-[#5A72B1]' />
                  )}
                </div>
                <Tooltip
                  title={
                    <div className='font-bold'>
                      {t('dashboard.positioning.add_competitors.tooltip', name)}
                    </div>
                  }
                >
                  <span
                    className={clsx(
                      !updatingCompetitors && 'hover:text-[#9EB9DA]',
                      'truncate',
                    )}
                  >
                    {name}
                  </span>
                </Tooltip>
              </div>
            ))}
          </div>

          {/* Scrollable Potential Competitors */}
          <div className='overflow-y-scroll flex flex-col max-h-[250px]'>
            {filteredPotentialCompetitors.map(
              ({ name, id, can_be_selected }) => (
                <div
                  key={id}
                  className={clsx(
                    !can_be_selected ? 'cursor-default' : 'cursor-pointer',
                    'flex gap-4 border-t border-bmlightgrey px-4 py-1 text-bmblue',
                  )}
                  onClick={() =>
                    !updatingCompetitors &&
                    can_be_selected &&
                    addSelectedCompetitor(id)
                  }
                >
                  <div className='w-10 h-10 flex-shrink-0'>
                    {updatingCompetitors && clickedCompetitor === id ? (
                      <div className='flex items-center justify-center w-full h-full pt-2'>
                        <Spin />
                      </div>
                    ) : (
                      <div className='' />
                    )}
                  </div>
                  {!can_be_selected ? (
                    <Tooltip
                      title={
                        <div>
                          <span className='font-bold'>{name}</span>
                          <p>
                            {t(
                              'dashboard.positioning.add_competitors.disabled',
                              'There are incomplete data about this project.',
                            )}
                          </p>
                        </div>
                      }
                    >
                      <span className='text-[#DBDBDB] cursor-default truncate'>
                        {name}
                      </span>
                    </Tooltip>
                  ) : (
                    <Tooltip
                      title={
                        <div className='font-bold'>
                          {t(
                            'dashboard.positioning.add_competitors.tooltip',
                            name,
                          )}
                        </div>
                      }
                    >
                      <span
                        className={clsx(
                          !updatingCompetitors && 'hover:text-[#9EB9DA]',
                          'truncate',
                        )}
                      >
                        {name}
                      </span>
                    </Tooltip>
                  )}
                </div>
              ),
            )}
          </div>
        </div>
      )}

      {/* Main Content */}
      <div
        className={clsx(
          !updatingCompetitors ? 'cursor-pointer' : 'cursor-default',
          'px-8 py-1 flex justify-between bg-white gap-4 items-center border-[1px] border-bmgrey h-full',
          !open ? 'rounded-[15px]' : 'rounded-tl-[15px] rounded-tr-[15px]',
        )}
        onClick={handleOpen}
      >
        <div className='flex gap-4'>
          <BuildingOfficeIcon width={24} color='#313B5E' className='stroke-2' />
          <span className='font-bold text-bmblue whitespace-nowrap'>
            {t('dashboard.positioning.add_competitors', 'Add Competitors')}
          </span>
        </div>
        <div className='flex gap-4'>
          {!open ? (
            <ChevronDoubleDownIcon
              width={24}
              className='stroke-2'
              color='#BDBDBD'
            />
          ) : (
            <ChevronDoubleUpIcon
              width={24}
              className='stroke-2'
              color='#BDBDBD'
            />
          )}
        </div>
      </div>
    </div>
  );
};
