import { css } from '@emotion/react/macro';

export const styles = {
  card: css({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    '.ant-card-body': {
      flex: 'auto',
      display: 'flex',
      flexDirection: 'column',
    },
    border: 'none',
  }),
  chartContainer: css({
    width: '100%',
    marginTop: '1.2rem',
    flex: 'auto',
  }),
  chartContainerPaginated: css({
    position: 'relative',
    width: '100%',
    padding: '0 5rem',
    marginTop: '1.2rem',
    flex: 'auto',
  }),
  controlsContainer: css({
    margin: '-1.2rem 0 1.2rem',
    display: 'flex',
    alignItems: 'flex-start',
  }),
  searchContainer: css({ marginLeft: 'auto' }),
};
