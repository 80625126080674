import { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import { useFlatLayout, useRegionFormatting } from '../../../../hooks';
import {
  AVAILABLE,
  SOLD,
  COMPETITOR_COLORS,
} from '../../../../styles/constants';
import { translateBlockChartValue } from '../../../../utils/utils';
import { getColumnOptions } from '../../../../utils/defaultChartConfig';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

export interface Data {
  value: number;
  layout: string;
  series: string;
}

interface Props {
  data: Data[];
}
export const SizeComparisonChart: FC<Props> = ({ data }) => {
  const { isRentals } = useMarketDetails();
  const { t } = useTranslation();
  const { getLayout } = useFlatLayout();
  const { formatAreaUnits } = useRegionFormatting();

  const translateValue = (value: string): string =>
    translateBlockChartValue(value, t, isRentals);

  const config: ColumnConfig = getColumnOptions({
    data,
    isGroup: true,
    xField: 'layout',
    yField: 'value',
    seriesField: 'series',
    color: [AVAILABLE, SOLD, ...COMPETITOR_COLORS],
    legend: {
      itemName: {
        formatter: (value) => translateValue(value),
      },
    },
    yAxis: {
      label: {
        formatter: (value) => formatAreaUnits(Number(value)),
      },
    },
    xAxis: {
      label: {
        formatter: (value) => getLayout(value),
      },
    },
    tooltip: {
      formatter: (datum) => {
        const value = datum as Data;
        return {
          name: translateValue(value.series),
          value: formatAreaUnits(value.value) ?? '',
        };
      },
      title: (title) => getLayout(title),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${translateBlockChartValue(
                item?.name,
                t,
              )}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
  });
  return data.length > 0 ? (
    <Column {...config} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
