/** @jsxImportSource @emotion/react */
import { FC, useEffect, useState } from 'react';
import { Button } from 'antd';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/react/macro';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { useLocation } from 'react-router-dom';
import { useStoreActions, useStoreState } from '../../hooks';
import { OverviewLoading } from '../OverviewLoading';
import { BOLD_FONT_WEIGHT, GREY_2 } from '../../styles/constants';
import { getUpdateFrequencyDates } from '../../utils/utils';
import { MarketExport } from './MarketExport';
import { Paths } from '../../routes/Paths';
import { DatePickerFilter } from '../DatePickerFilter/DatePickerFilter';
import { VATSwitch } from '../VATSwitch/VATSwitch';

dayjs.extend(utc);

const styles = {
  datePicker: css({
    input: {
      cursor: 'pointer',
      width: '90px !important',
      fontWeight: BOLD_FONT_WEIGHT,
      fontSize: '13.5rem',
    },
  }),
  updateFrequencyWrapper: css({
    padding: '0.5rem 2.5rem 1rem 2.5rem',
    color: GREY_2,
    fontWeight: '400',
    fontSize: '12px',
  }),
  updateFrequency: css({
    fontWeight: '700',
  }),
  updateFrequencyInfo: css({
    lineHeight: '16px',
    marginTop: '0.5rem',
    marginBottom: '1rem',
  }),
  updateFrequencyEmail: css({
    color: GREY_2,
    textDecoration: 'underline',
  }),
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
  }),
  icon: css({
    width: 24,
    height: 24,
    marginRight: '1rem',
  }),
  pdfDownload: css({
    display: 'flex',
    alignItems: 'center',
    borderRadius: '15px',
    fontSize: '14px',
    fontWeight: 700,
    padding: '0px 10px',
    marginRight: '1.5rem',
  }),
  downloadIcon: css({
    width: '20px',
    height: '20px',
    strokeWidth: '2px',
    marginRight: '5px',
  }),
};

type Props = {
  onDownloadPDF: () => void
  isLoadingDownload: boolean
  filterValue?: string | number
}

export const MarketHeaderFilters: FC<Props> = ({ onDownloadPDF, isLoadingDownload, filterValue }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [initialParamsSet, setInitialParamsSet] = useState(false);
  const { marketDates, paramsPM, overviewData } = useStoreState((state) => state.filters);
  const setParamsPM = useStoreActions(
    (actions) => actions.filters.setParamsPM,
  );
  const validDates = getUpdateFrequencyDates(
    marketDates,
    (overviewData?.market_dashboard_frequency ?? null),
  );

  const compareDates = validDates[validDates.length - 1] === paramsPM?.date;

  const showMarketExport = pathname === Paths.MARKET_REPORTS;

  useEffect(() => {
    if (!initialParamsSet && paramsPM && validDates.length > 0 && !compareDates) {
      setParamsPM({
        dashboardId: paramsPM.dashboardId,
        date: validDates[validDates.length - 1],
        name: paramsPM.name,
      });
      setInitialParamsSet(true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={styles.wrapper}>
      <div>
        <VATSwitch />
      </div>
      <OverviewLoading>
          <Button
            css={styles.pdfDownload}
            icon={<ArrowDownTrayIcon css={styles.downloadIcon} />}
            onClick={onDownloadPDF}
            disabled={isLoadingDownload}
          >
            {isLoadingDownload ? `${t('download.processing_data', 'Preparing your data')}...` : 'PDF'}
          </Button>
          <DatePickerFilter />
      </OverviewLoading>
        {
          showMarketExport && (
            <div>
              <MarketExport />
            </div>
          )
        }
    </div>
  );
};
