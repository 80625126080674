/** @jsxImportSource @emotion/react */
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import {
  Cog6ToothIcon,
  PresentationChartBarIcon,
  TagIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import { Button, message, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Segmented, { SegmentedValue } from 'antd/lib/segmented';
import { useLocation } from 'react-router-dom';
import { styles } from './styles';
import { InfoPopover, Page } from '../../components';
import {
  FilterEnum,
  PricePerSMCalcEnum,
  LanguageEnum,
  PricesRounding,
  CurrencyEnum,
} from '../../api/enums';
import {
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../../hooks';
import { formatBreaks } from '../../utils/formatBreaks';
import { PricePerSmCalcType } from '../../components/TopBar/SettingsPopover';

type Data = {
  label: string;
  labelTooltip: ReactNode;
  value: ReactNode;
  valueTooltip?: ReactNode;
  element?: ReactNode;
};

type SettingsData = {
  global: Data[];
  market: Data[];
  dynamicPricing: Data[];
};

type SettingsSectionProps = {
  icon?: ReactNode;
  title: string;
  children: ReactNode;
};

const WithExteriorSwitch: FC = () => {
  const { t } = useTranslation();
  const { profile } = useStoreState((state) => state.user);
  const { updateProfile } = useStoreActions((action) => action.user);
  const location = useLocation();
  const isHome = location.pathname.startsWith('/home');
  const calcExterior =
    (profile?.price_per_sm_calculation ?? PricePerSMCalcEnum.WITH_EXTERIOR) ===
    PricePerSMCalcEnum.WITH_EXTERIOR;
  const handleChange = (value: SegmentedValue): void => {
    updateProfile({
      price_per_sm_calculation: value as PricePerSmCalcType,
    });
  };
  return (
    <Segmented
      value={
        profile?.price_per_sm_calculation ?? PricePerSMCalcEnum.WITH_EXTERIOR
      }
      options={[
        calcExterior
          ? {
              value: PricePerSMCalcEnum.WITH_EXTERIOR,
              label: isHome
                ? t(
                    'enums.state.with_exterior_extended',
                    'With Exterior (50% + 20% Method)',
                  )
                : t('enums.state.with_exterior', 'With exterior'),
            }
          : {
              value: PricePerSMCalcEnum.WITHOUT_EXTERIOR,
              label: t('enums.state.without_exterior', 'Without exterior'),
            },
      ]}
      onChange={handleChange}
    />
  );
};

const LanguageSwitch: FC = () => {
  const { VAT_included, reserved_as_sold, language } = useStoreState(
    (state) =>
      state.user.profile ?? {
        VAT_included: true,
        reserved_as_sold: true,
        language: LanguageEnum.EN,
      },
  );
  const { updateProfile } = useStoreActions((action) => action.user);
  const handleChange = (value: SegmentedValue): void => {
    updateProfile({
      VAT_included,
      reserved_as_sold,
      language: value as LanguageEnum,
    });
  };
  return (
    <Select
      value={language}
      onChange={handleChange}
      style={{ width: 150 }}
      options={[
        {
          value: LanguageEnum.SK,
          label: 'Slovak',
        },
        {
          value: LanguageEnum.CZ,
          label: 'Czech',
        },
        {
          value: LanguageEnum.EN,
          label: 'English',
        },
        {
          value: LanguageEnum.DE,
          label: 'German',
        },
      ].sort((a, b) => (a.label < b.label ? -1 : 1))}
    />
  );
};

const SettingsSwitch: FC = () => {
  const { t } = useTranslation();
  const { VAT_included, reserved_as_sold, language } = useStoreState(
    (state) =>
      state.user.profile ?? {
        VAT_included: true,
        reserved_as_sold: true,
        language: LanguageEnum.EN,
      },
  );
  const { updateProfile } = useStoreActions((action) => action.user);
  const handleChange = (value: SegmentedValue): void => {
    updateProfile({
      VAT_included: value === FilterEnum.VAT,
      reserved_as_sold,
      language,
    });
  };
  return (
    <Segmented
      value={VAT_included ? FilterEnum.VAT : FilterEnum.NONVAT}
      options={[
        {
          value: FilterEnum.VAT,
          label: t('menu.settings.popover.vat_in_prices.with_vat', 'With VAT'),
        },
        {
          value: FilterEnum.NONVAT,
          label: t(
            'menu.settings.popover.vat_in_prices.without_vat',
            'Without VAT',
          ),
        },
      ]}
      onChange={handleChange}
    />
  );
};

const RoundingSwitch: FC = () => {
  const { t } = useTranslation();
  const { VAT_included, reserved_as_sold, price_rounding, currency } =
    useStoreState(
      (state) =>
        state.user.profile ?? {
          VAT_included: true,
          reserved_as_sold: true,
          price_rounding: PricesRounding.NONE,
          currency: CurrencyEnum.EUR,
        },
    );
  const { updateProfile } = useStoreActions((action) => action.user);
  const handleChange = (value: SegmentedValue): void => {
    updateProfile({
      VAT_included,
      reserved_as_sold,
      price_rounding: String(value),
    });
  };
  return (
    <Select
      value={price_rounding}
      style={{ width: 180 }}
      options={[
        {
          value: PricesRounding.NONE,
          label: t('menu.settings.prices_rounding.none', 'None'),
        },
        {
          value: PricesRounding.HUNDREDS,
          label: t('menu.settings.prices_rounding.hundreds', 'Hundreds'),
        },
        {
          value: PricesRounding.THOUSANDS,
          label: t('menu.settings.prices_rounding.thousands', 'Thousands'),
        },
        ...(currency === CurrencyEnum.CZK
          ? [
              {
                value: PricesRounding.TENS_OF_THOUSANDS,
                label: t(
                  'menu.settings.prices_rounding.tens_of_thousands',
                  'Tens of Thousands',
                ),
              },
            ]
          : []),
      ]}
      onChange={handleChange}
    />
  );
};

const ReservedSwitch: FC = () => {
  const { t } = useTranslation();
  const { VAT_included, reserved_as_sold, language } = useStoreState(
    (state) =>
      state.user.profile ?? {
        VAT_included: true,
        reserved_as_sold: true,
        language: LanguageEnum.EN,
      },
  );
  const { updateProfile } = useStoreActions((action) => action.user);
  const handleChange = (value: SegmentedValue): void => {
    updateProfile({
      VAT_included,
      reserved_as_sold: value === FilterEnum.SOLD,
      language,
    });
  };
  return (
    <Segmented
      value={reserved_as_sold ? FilterEnum.SOLD : FilterEnum.AVAILABLE} // WIP
      options={[
        {
          value: FilterEnum.SOLD,
          label: t('enums.state.sold'),
        },
        {
          value: FilterEnum.AVAILABLE,
          label: t('enums.state.available'),
        },
      ]}
      onChange={handleChange}
    />
  );
};

export const SettingsPage: FC = () => {
  const { t } = useTranslation();
  const { areaUnit, isUsRegion } = useRegionFormatting();
  const developerId = useStoreState(
    (state) => state.user.profile?.developer_id,
  );
  const fetchSubscriptionEmails = useStoreActions(
    (actions) => actions.subscribe.fetchSubscribedEmails,
  );
  const deleteSubscribedEmails = useStoreActions(
    (actions) => actions.subscribe.deleteSubscribedEmail,
  );
  const subscribedEmails = useStoreState((state) => state.subscribe.data);
  const isLoaded = useStoreState((state) => state.global.isLoaded);
  const [isDeleting, setIsDeleting] = useState(false);
  const [removedEmails, setRemovedEmails] = useState<string[]>([]);
  const [emailsToRemove, setEmailsToRemove] = useState<string[]>([]);

  useEffect(() => {
    if (developerId && isLoaded) {
      fetchSubscriptionEmails(developerId);
    }
  }, [developerId, fetchSubscriptionEmails, isDeleting, isLoaded]);

  const handleRemoveEmail = (email: string): void => {
    if (emailsToRemove.includes(email)) {
      setEmailsToRemove(emailsToRemove.filter((e) => e !== email));
    } else {
      setEmailsToRemove([...emailsToRemove, email]);
    }
  };

  const handleRemoveEmailSubmit = (): void => {
    setIsDeleting(true);
    try {
      if (developerId) {
        deleteSubscribedEmails({
          developerId,
          emails: emailsToRemove,
        });
        setRemovedEmails(emailsToRemove);
        setEmailsToRemove([]);
        fetchSubscriptionEmails(developerId);
      }
    } catch (e) {
      message.error('This email was already deleted', 1);
    } finally {
      setIsDeleting(false);
    }
  };

  const subscriptionsExist =
    subscribedEmails && subscribedEmails.email_notification_list.length > 0;
  const filteredEmails =
    subscriptionsExist &&
    subscribedEmails.email_notification_list.filter(
      (email) => !removedEmails.includes(email),
    );

  const data: SettingsData = useMemo(() => {
    const globalData: Data[] = [
      {
        label: t('menu.settings.popover.language.label', 'Language'),
        labelTooltip: t(
          'menu.settings.popover.language.tooltip',
          'Language of the web app.',
        ),
        value: null,
        element: <LanguageSwitch />,
      },
      {
        label: t('menu.settings.popover.vat_in_prices.label', 'VAT in Prices'),
        labelTooltip: t(
          'menu.settings.popover.vat_in_prices.tooltip',
          'The option to include the Value Added Tax in prices displayed in the web app.',
        ),
        value: null,
        element: <SettingsSwitch />,
      },
      ...(!isUsRegion
        ? [
            {
              label: t(
                'menu.settings.popover.price_per_sm_calc.label',
                'Price per {{areaUnit}} Calculation',
                { areaUnit },
              ),
              labelTooltip: t(
                'menu.settings.popover.price_per_sm_calc.tooltip',
              ),
              value: null,
              element: <WithExteriorSwitch />,
            },
          ]
        : []),
    ];

    const marketData: Data[] = [
      {
        label: t(
          'menu.settings.popover.reserved_units.label',
          'Reserved units displayed as',
        ),
        labelTooltip: t(
          'menu.settings.popover.reserved_units.tooltip',
          'Select whether you want to include the reserved units as sold or available.',
        ),
        value: null,
        element: <ReservedSwitch />,
      },
    ];

    const dynamicPricingData: Data[] = [
      {
        label: t(
          'menu.settings.popover.prices_rounding.label',
          'Prices Rounding',
        ),
        labelTooltip: t(
          'menu.settings.popover.prices_rounding.tooltip',
          'Determines whether you want to round the prices.',
        ),
        value: null,
        element: <RoundingSwitch />,
      },
    ];

    return {
      global: globalData,
      market: marketData,
      dynamicPricing: dynamicPricingData,
    };
  }, [areaUnit, isUsRegion, t]);

  const SettingsSection: FC<SettingsSectionProps> = ({
    icon,
    title,
    children,
  }) => (
    <div className='flex flex-col space-y-2'>
      <div className={`flex items-center ${icon && 'space-x-4'}`}>
        {icon && icon}
        <div css={styles.title}>{title}</div>
      </div>
      {children}
    </div>
  );

  return (
    <Page pageClassname='settings' title='Settings'>
      <div css={styles.content}>
        <SettingsSection
          icon={<Cog6ToothIcon className='h-10 w-10 stroke-2' />}
          title={t('menu.settings.global', 'Global')}
        >
          {data.global.map(
            ({ label, labelTooltip, value, valueTooltip, element }) => (
              <div key={label}>
                <div css={styles.label}>
                  {label}
                  <InfoPopover
                    popoverProps={{
                      content: (
                        <div css={styles.tooltip}>
                          {labelTooltip ===
                          t('menu.settings.popover.price_per_sm_calc.tooltip')
                            ? formatBreaks(
                                labelTooltip?.toString() || '',
                                '**',
                                true,
                                1,
                              )
                            : labelTooltip}
                        </div>
                      ),
                    }}
                  />
                </div>
                {element}
                <div css={styles.value}>
                  {value}
                  {valueTooltip && (
                    <InfoPopover
                      popoverProps={{
                        content: <div css={styles.tooltip}>{valueTooltip}</div>,
                      }}
                    />
                  )}
                </div>
              </div>
            ),
          )}
        </SettingsSection>
        <SettingsSection
          icon={<PresentationChartBarIcon className='w-10 h-10 stroke-2' />}
          title={t('menu.settings.market_data', 'Market Data')}
        >
          {data.market.map(
            ({ label, labelTooltip, value, valueTooltip, element }) => (
              <div key={label}>
                <div css={styles.label}>
                  {label}
                  <InfoPopover
                    popoverProps={{
                      content: <div css={styles.tooltip}>{labelTooltip}</div>,
                    }}
                  />
                </div>
                {element}
                <div css={styles.value}>
                  {value}
                  {valueTooltip && (
                    <InfoPopover
                      popoverProps={{
                        content: <div css={styles.tooltip}>{valueTooltip}</div>,
                      }}
                    />
                  )}
                </div>
              </div>
            ),
          )}
        </SettingsSection>
        <SettingsSection
          icon={<TagIcon className='h-10 w-10 stroke-2' />}
          title={t('menu.settings.dynamic_pricing', 'Dynamic Pricing')}
        >
          <div className='pb-2'>
            {data.dynamicPricing.map(
              ({ label, labelTooltip, value, valueTooltip, element }) => (
                <div key={label}>
                  <div css={styles.label}>
                    {label}
                    <InfoPopover
                      popoverProps={{
                        content: <div css={styles.tooltip}>{labelTooltip}</div>,
                      }}
                    />
                  </div>
                  {element}
                  <div css={styles.value}>
                    {value}
                    {valueTooltip && (
                      <InfoPopover
                        popoverProps={{
                          content: (
                            <div css={styles.tooltip}>{valueTooltip}</div>
                          ),
                        }}
                      />
                    )}
                  </div>
                </div>
              ),
            )}
          </div>
          <div css={styles.label}>
            {t('menu.settings.popover.recipients', 'Price Update Recipients')}
            <InfoPopover
              popoverProps={{
                content: (
                  <div css={styles.tooltip}>
                    {t('menu.settings.popover.recipients.tooltip')}
                  </div>
                ),
              }}
            />
          </div>
          <div css={styles.recipientsContainer}>
            {filteredEmails ? (
              filteredEmails.map((email) => (
                <div
                  css={styles.recipients}
                  style={{ opacity: emailsToRemove.includes(email) ? 0.5 : 1 }}
                  key={email}
                >
                  <span className='text-ellipsis max-w-[30rem] truncate'>
                    {email}
                  </span>
                  <Button
                    css={styles.removeButton}
                    onClick={() => handleRemoveEmail(email)}
                  >
                    <XMarkIcon css={styles.removeIcon} />
                  </Button>
                </div>
              ))
            ) : (
              <div css={styles.noRecipients}>
                {t(
                  'menu.settings.popover.recipients.no_recipients',
                  'No recipients',
                )}
              </div>
            )}
          </div>
          {subscriptionsExist && emailsToRemove.length !== 0 && (
            <div css={styles.buttonsWrapper}>
              <Button
                className='bm-default-btn'
                onClick={() => setEmailsToRemove([])}
              >
                Clear
              </Button>
              <Button
                className='bm-submit-btn'
                loading={isDeleting}
                onClick={() => handleRemoveEmailSubmit()}
              >
                Submit
              </Button>
            </div>
          )}
        </SettingsSection>
      </div>
    </Page>
  );
};
