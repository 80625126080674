import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ChartCard } from '../../ChartCard';
import { SaleDetailChart } from '../../market/blocks/SaleDetail/SaleDetailChart';
import { useStoreState } from '../../../hooks';

type SaleDetailProjectData = {
  category: string;
  state: string;
  value: number;
};

export const SaleDetail: FC = () => {
  const { t } = useTranslation();
  const data: SaleDetailProjectData[] = useStoreState((state) =>
    state.project.aggregatedTableData.map(
      ({ layout, availability, available }) => ({
        category: layout,
        state: availability,
        value: available,
      }),
    ).sort((a, b) => a.category.localeCompare(b.category)),
  );
  return (
    <ChartCard
      title={t('dashboard.sale_detail.title')}
      subtitle={t('dashboard.sale_detail.subtitle')}
      zoomInHeader
      chart={data.length > 0 ? <SaleDetailChart data={data} /> : null}
    />
  );
};
