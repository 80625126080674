import { css } from '@emotion/react/macro';
import { GREY_2 } from '../../styles/constants';

export const styles = {
  container: css({
    position: 'relative',
    width: 'inherit',
    canvas: {
      height: '350px !important',
    },
  }),
  modal: css({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '.ant-modal-body': { maxHeight: '70vh', width: '70vw' },
  }),
  searchButton: css({
    color: GREY_2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '0.5rem',
    marginTop: '0.5rem',
  }),
};
