import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Map } from '../components/addReport/Map';
import { useStoreActions, useStoreState } from '../hooks';
import { Mode } from '../store/types';
import { AddReport } from '../components/market/reports/AddReport';
import { Paths } from '../routes/Paths';
import { ConstructionTypeEnum, MarketTypeEnum } from '../api/enums';
import { ReportInProgress } from '../components/addReport/ReportInProgress';
import { MapType } from '../components/MapControls/MapProjectStatusTags';
import { ProjectPhase } from '../api/types';

export const AddReportPage: FC = () => {
  // TODO: once simplified EP will support fetch of all cities at one, adapt fetchMapProject
  const { fetchAllProjects, fetchMapProjects, fetchSecondaryPolygons } =
    useStoreActions((actions) => actions.market);
  const navigate = useNavigate();

  const { mode, isReportCreating, loadingFilters } = useStoreState(
    (store) => store.market,
  );
  const { setMode, resetCircles, resetPolygons } = useStoreActions(
    (action) => action.market,
  );
  const isInProgress = isReportCreating;
  const isStart = mode === Mode.EDITSTART || mode === Mode.START;
  const isCreating = mode === Mode.CREATE;
  const isEditing = mode === Mode.EDIT;
  const { constructionType, unitsType } = useStoreState(
    (state) => state.filters,
  );
  const simplifiedProjects = useStoreState((state) =>
    state.market.simplifiedProjects.filter(
      ({ gps_latitude, gps_longitude, project_phase, unit_type }) =>
        gps_latitude &&
        gps_longitude &&
        project_phase !== ProjectPhase.ARCHIVED &&
        unitsType.includes(unit_type),
    ),
  );

  const filteredProjectsByTypes = simplifiedProjects.filter(
    ({ renovation }) => {
      if (renovation === null) {
        return true;
      }
      // If both 'New' and 'Renovation' are in constructionType, return everything
      if (
        constructionType.includes(ConstructionTypeEnum.NEW) &&
        constructionType.includes(ConstructionTypeEnum.RENOVATION)
      ) {
        return true;
      }

      // If only 'New' is in constructionType, return projects with renovation = true (or undefined treated as false)
      if (constructionType.includes(ConstructionTypeEnum.NEW)) {
        return !renovation;
      }

      // If only 'Renovation' is in constructionType, return projects with renovation = false (or undefined treated as false)
      if (constructionType.includes(ConstructionTypeEnum.RENOVATION)) {
        return !!renovation;
      }

      return true;
    },
  );

  const setSimplifiedProjects = useStoreActions(
    (actions) => actions.market.setSimplifiedProjects,
  );

  const selectedReportName = useStoreState(
    (state) => state.filters.selectedReportName,
  );
  const secondaryReports = useStoreState(
    (state) => state.secondaryMarket.reports,
  );
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const allReports = [
    ...(overviewData?.market_dashboards || []).map((d) => ({
      name: d.name,
      reportType: MarketTypeEnum.PRIMARY,
    })),
    ...(secondaryReports || []).map((d) => ({
      name: d.name,
      reportType: d.reportType,
    })),
  ] as { name: string; reportType: MarketTypeEnum }[];

  const [markets, setMarkets] = useState<MarketTypeEnum[]>(
    allReports
      .filter((r) => r.name === selectedReportName)
      .map((r) => r.reportType),
  );

  useEffect(
    () => () => {
      setMode(Mode.READ);
      resetCircles();
      resetPolygons();
    },
    [],
  );

  useEffect(() => {
    if (mode === Mode.READ) {
      navigate(Paths.MARKET_PROJECTS);
    }
    if (isCreating || isEditing) {
      if (markets.includes(MarketTypeEnum.PRIMARY)) {
        fetchAllProjects();
      } else {
        setSimplifiedProjects([]);
        resetPolygons();
      }
    } else {
      setSimplifiedProjects([]);
      fetchSecondaryPolygons();
    }
  }, [mode]);

  return (
    <>
      {isStart && !isInProgress && !loadingFilters && (
        <div className='h-full w-full flex justify-center items-center'>
          <AddReport markets={markets} setMarkets={setMarkets} />
        </div>
      )}
      {(isCreating || isEditing) && !isInProgress && (
        <div className='h-full'>
          <Map
            projects={filteredProjectsByTypes}
            type={MapType.CREATE_REPOROT}
          />
        </div>
      )}
      {isInProgress && (
        <div className='h-full w-full flex justify-center items-center'>
          <ReportInProgress />
        </div>
      )}
    </>
  );
};
