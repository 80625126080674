import { FC, ReactNode, useEffect, useMemo, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useDraggable } from 'react-use-draggable-scroll';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import clsx from 'clsx';
import { ArrowPathIcon, Cog6ToothIcon } from '@heroicons/react/24/outline';
import { PlanData } from '../../../../api/mockData/sales';
import { InfoPopover } from '../../../InfoPopover';
import { round } from '../../../../utils/formatters';
import { PlanSpeedRow } from './PlanSpeedRow';
import { ButtonWithTooltip } from '../../../ButtonWithTooltip';
import { getFrequencyData, isCurrentPeriod } from './paginatePlan';

type Props = {
  data: PlanData[];
  target: 'revenue' | 'floor_area' | 'units';
  frequency: 'monthly' | 'quarterly' | 'semiannualy';
  ratios: { [key in 'revenue' | 'floor_area']: number };
  isEditing: boolean
  onEdit: (id: number, planSpeed: number) => void;
  handleRestore: () => void;
  handleEditing: () => void;
};

type PlanCellProps = {
  children?: ReactNode;
  isGrey?: boolean;
  isTableCell?: boolean;
  popoverContent?: string;
  isHeader?: boolean;
  isOld?: boolean;
  isCurrentMonth?: boolean;
  boldFont?: boolean;
};

export const PlanTable: FC<Props> = ({ data, target, frequency, ratios, isEditing, onEdit, handleEditing, handleRestore }) => {
  const { t } = useTranslation();
  const scrollRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLInputElement>;
  const { events } = useDraggable(scrollRef);
  const planCellRef = useRef<HTMLDivElement>(null);
  const [isHovered, setIsHovered] = useState(false);
  const [scrollStep, setScrollStep] = useState(0);
  const [isAtStart, setIsAtStart] = useState(true);
  const [isAtEnd, setIsAtEnd] = useState(false);
  const freqData = useMemo(() => frequency !== 'monthly' ? getFrequencyData(data, frequency) : data, [data, frequency]);

  const bigNumberFormatter = (num: number): string => {
    if (num > 1e6) {
      return `${round(num / 1e6, 2)}m`;
    } if (num < 1e6 && num > 1e3) {
      return `${round(num / 1e3, 2)}K`;
    }
      return `${num.toFixed(0)}`;
  };

  const calculateRatio = (item: number): string | number => {
    switch (target) {
      case 'revenue':
        return bigNumberFormatter(item * ratios.revenue);
      case 'floor_area':
        return bigNumberFormatter(item * ratios.floor_area);
      default:
        return round(item, 1);
    }
  };

  const currentMonthIndex = freqData.findIndex((plan) => dayjs(plan.month).isSame(dayjs(), 'month'));
  const currentPeriodIndex = freqData.findIndex((plan) => frequency !== 'monthly' && isCurrentPeriod(plan.period || '', frequency));
  const currentTimeIndex = frequency === 'monthly' ? currentMonthIndex : currentPeriodIndex;
  const isOldIndex = (index: number): boolean => index < currentTimeIndex;

  useEffect(() => {
    if (planCellRef.current) {
      const cellWidth = planCellRef.current.getBoundingClientRect().width;
      setScrollStep(cellWidth);
    }
  }, []);

  useEffect(() => {
    if (scrollRef.current) {
      // eslint-disable-next-line no-nested-ternary
      const oldMonths = currentTimeIndex > 3 ? 3 : currentTimeIndex > 2 ? 2 : 1;
      const scrollAmountInPx = (currentTimeIndex - oldMonths) * scrollStep;

      scrollRef.current.scrollLeft = scrollAmountInPx;
    }
  }, [currentTimeIndex, scrollStep]);

  useEffect(() => {
    const handleScroll = (): void => {
      if (scrollRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = scrollRef.current;

        // Check if at the start
        setIsAtStart(scrollLeft === 0);

        // Check if at the end
        setIsAtEnd(scrollLeft + clientWidth >= scrollWidth);
      }
    };

    scrollRef.current?.addEventListener('scroll', handleScroll);

    return () => {
      scrollRef.current?.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleWheel = (e: React.WheelEvent<HTMLDivElement>): void => {
    if (scrollRef.current && scrollStep > 0) {
      e.preventDefault();
      const scrollAmount = scrollStep;

      if (e.deltaY > 0) {
        scrollRef.current.scrollLeft += scrollAmount; // Scroll right
      } else {
        scrollRef.current.scrollLeft -= scrollAmount; // Scroll left
      }
    }
  };

  const PlanCell: FC<PlanCellProps> = ({
    children,
    isGrey = false,
    isTableCell = false,
    popoverContent,
    isHeader = false,
    isOld = false,
    isCurrentMonth = false,
    boldFont = false,
  }) => (
    <span
      ref={planCellRef}
      className={clsx(
        'flex px-6 border-[#F1F1F1] items-center min-h-[4rem]',
        isGrey && 'bg-[#F8F8F8]',
        isTableCell && 'w-[8.5rem] justify-center',
        popoverContent && 'justify-between pr-2',
        isHeader ? 'border-b-4' : 'border-b',
        isOld && 'text-[#828282]',
        isCurrentMonth && 'border-l-4',
        boldFont && 'font-bold',
      )}
    >
      {children}
      {popoverContent && (
        <InfoPopover
          popoverProps={{
            content: t(`sales.plan.subtitle.${popoverContent}.description`),
          }}
        />
      )}
    </span>
  );

  return (
    <div
      className='flex w-full overflow-hidden'
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='flex flex-col justify-center min-w-[18rem]'>
        <PlanCell isHeader>
          <div className='flex items-center justify-end w-full'>
            {!isAtStart && <div className='font-bold text-3xl'>{'<'}</div>}
          </div>
        </PlanCell>
        <PlanCell popoverContent='plan'>{t('sales.plans.subtitle.plan_speed', 'Plan Speed')}</PlanCell>
        <PlanCell isGrey popoverContent='realized'>{t('sales.plans.subtitle.realised', 'Realised')}</PlanCell>
        <PlanCell isGrey popoverContent='normal_speed'>{t('sales.plans.subtitle.normal_speed', 'Normal Speed')}</PlanCell>
        <PlanCell isGrey popoverContent='price_effect'>{t('sales.plans.subtitle.price_effect', 'Price Effect')}</PlanCell>
      </div>
      <div
        className='flex w-full overflow-x-scroll scrollbar-hide cursor-grab'
        {...events}
        ref={scrollRef}
        onWheel={handleWheel}
      >
        {freqData.map((plan, index) => (
          <div className='flex flex-col'>
            <PlanCell isTableCell isHeader boldFont>
              {frequency === 'monthly' ? dayjs(plan.month).format('MM/YY') : plan.period}
            </PlanCell>
              {isEditing ? (
                <div
                  className={clsx(
                    'flex px-6 border-[#F1F1F1] items-center min-h-[4rem] w-[8.5rem] justify-center',
                    index === currentTimeIndex && 'border-l-4',
                    isOldIndex(index) && 'text-[#828282]',
                  )}
                >
                  <PlanSpeedRow month={plan.month} planId={plan.id} planSpeed={round(plan.plan_speed, 1)} isEditing={isEditing} onEdit={onEdit} />
                </div>
              ) : (
                <PlanCell isTableCell isOld={isOldIndex(index)} isCurrentMonth={index === currentTimeIndex}>
                  <span>{calculateRatio(plan.plan_speed)}</span>
                </PlanCell>
              )}
            <PlanCell isGrey isTableCell isOld={isOldIndex(index)} isCurrentMonth={index === currentTimeIndex}>
              <span>{dayjs(plan.month).isAfter(dayjs()) ? '' : bigNumberFormatter(plan[`realized_${target}`] || 0)}</span>
            </PlanCell>
            <PlanCell isGrey isTableCell isOld={isOldIndex(index)} isCurrentMonth={index === currentTimeIndex}>
              <span>{calculateRatio(plan.normal_speed)}</span>
            </PlanCell>
            <PlanCell
              isGrey
              isTableCell
              isOld={isOldIndex(index)}
              isCurrentMonth={index === currentTimeIndex}
              boldFont={!isOldIndex(index)}
            >
              {round(plan.price_effect, 1)}%
            </PlanCell>
          </div>
        ))}
      </div>
      <div className='flex flex-col justify-start items-start pl-5 py-3 w-[5rem]'>
        <div className='font-bold text-3xl h-[2rem]'>{!isAtEnd ? '>' : ''}</div>
        {(isHovered || isEditing) && (
          <div className='flex mt-5'>
            {isEditing && (
              <ButtonWithTooltip
                buttonContent={
                  <ArrowPathIcon className='w-10 h-10 text-bmgray stroke-2 cursor-pointer' />
                }
                title={t('sales.plan.restore', 'Restore')}
                onClick={() => handleRestore()}
              />
            )}
            {!isEditing && (
              <ButtonWithTooltip
                buttonContent={
                  <Cog6ToothIcon className='w-10 h-10 text-bmgray stroke-2 cursor-pointer' />
                }
                title={t('sales.plan.edit_all', 'Edit All')}
                onClick={() => handleEditing()}
              />
            )}
          </div>
        )}
      </div>

    </div>
  );
};
