import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Card, Spin } from 'antd';
import { ChartPieIcon } from '@heroicons/react/24/outline';
import { useFlatLayout, useStoreState } from '../../../../hooks';
import { DefaultCardOptions } from '../../card/cardOptions/DefaultCardOptions';
import { LayoutsCol } from './LayoutsCol';

export const Layouts: FC = () => {
  const { t } = useTranslation();
  const { getLayoutAbbr } = useFlatLayout();

  const layout_sales = useStoreState(
    (state) => state.dashboard.dashboardData?.layout_sales,
  );
  const floor_count_overview = useStoreState(
    (state) => state.dashboard.dashboardData?.floor_count_overview,
  );
  const isHouse = !!floor_count_overview;
  const { dashboardLoading } = useStoreState((state) => state.dashboard);
  const layoutSalesArray = layout_sales
    ? Object.entries(layout_sales).map(
        ([layout, { sold, reserved, total }]) => ({
          layout,
          sold,
          reserved,
          total,
        }),
      )
    : [];
  return (
    <Card bordered={false} className='flex flex-col h-full rounded-[1.6rem]'>
      <div className='flex items-center justify-between pb-8'>
        <div className='flex gap-4 items-center'>
          <div className='flex items-center bg-[#9EB9DA] rounded-full p-2'>
            <ChartPieIcon className='stroke-2 w-10 h-10' />
          </div>
          <div className='font-bold'>{t('dashboard.kpi.layout_sales')}</div>
        </div>
        <DefaultCardOptions moreOptions />
      </div>
      {!dashboardLoading ? (
        <div
          className={clsx(
            isHouse ? 'grid-cols-3' : 'grid-cols-4',
            'grid gap-4',
          )}
        >
          {layout_sales
            ? layoutSalesArray.map(({ layout, sold, total, reserved }) => (
                <LayoutsCol
                  key={layout}
                  layout={getLayoutAbbr(layout, isHouse)}
                  sold={sold}
                  total={total}
                  reserved={reserved}
                />
              ))
            : null}
        </div>
      ) : (
        <div className='flex w-full justify-center items-center'>
          <Spin spinning />
        </div>
      )}
    </Card>
  );
};
