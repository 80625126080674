import { ChevronRightIcon } from '@heroicons/react/20/solid';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Disclosure, Popover } from '@headlessui/react';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Divider from 'antd/lib/divider';
import EllipsisHorizontalIcon from '@heroicons/react/24/solid/EllipsisHorizontalIcon';
import { Popover as AntdPopover } from 'antd';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';
import { AddProject } from './AddProject';

type ItemSize = 'small' | 'medium';

interface LinkItem {
  type: 'link';
  key: string;
  label: string | JSX.Element;
  href: string;
  disabled?: boolean;
  icon?: JSX.Element;
  target?: string;
  class?: string;
  inactive?: boolean;
}

interface DividerItem {
  key: string;
  type: 'divider';
}

interface SubmenuItem {
  type: 'submenu';
  key: string;
  label: string | JSX.Element;
  children: JSX.Element;
  defaultOpened?: () => boolean;
  icon?: JSX.Element;
  href?: string;
}

interface ButtonItem {
  type: 'button';
  key: string;
  label: string | JSX.Element;
  onClick?: () => void;
  actionsMenu?: JSX.Element;
  disabled?: boolean;
  icon?: JSX.Element;
  iconTooltip?: string;
  class?: string;
  sortIdx?: number;
  dataCy?: string;
}

export type MenuItem = LinkItem | DividerItem | SubmenuItem | ButtonItem;

interface ItemProps<T> {
  item: T;
  itemSize: ItemSize;
  isActive: boolean;
}

function classNames(...classes: string[]): string {
  return classes.filter(Boolean).join(' ');
}

const MenuLinkItem = React.memo(
  ({ item, isActive, itemSize }: ItemProps<LinkItem>) => (
    <Link
      className={item.disabled ? 'pointer-events-none' : ''}
      to={item.href}
      target={item.target}
    >
      <div
        className={classNames(
          isActive ? 'bg-slate-100' : 'hover:bg-gray-100',
          `flex rounded-xl py-2 pr-2 pl-2 items-center ${item.class}`,
          item.inactive
            ? 'text-[#828282] font-normal'
            : 'text-bmblue font-semibold',
        )}
        style={{
          height: itemSize === 'medium' ? '40px' : '32px',
        }}
      >
        {item.icon && <div className='w-[24px] mr-4 shrink-0'>{item.icon}</div>}
        <div className='whitespace-nowrap text-ellipsis overflow-hidden'>
          {item.label}
        </div>
      </div>
    </Link>
  ),
);

const DraggableButtonItem = React.memo(
  ({
    item,
    itemSize,
    isActive,
    index,
  }: {
    item: ButtonItem;
    itemSize: ItemSize;
    isActive: boolean;
    index: number;
  }) => (
    <Draggable draggableId={item.key} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.dragHandleProps}
          {...provided.draggableProps}
        >
          <ButtonItem item={item} itemSize={itemSize} isActive={isActive} />{' '}
        </div>
      )}
    </Draggable>
  ),
);

const ButtonItem = React.memo(
  ({
    item,
    itemSize,
    isActive,
  }: {
    item: ButtonItem;
    itemSize: ItemSize;
    isActive: boolean;
  }) => (
    <div
      role='button'
      tabIndex={0}
      className={classNames(
        isActive ? 'bg-slate-100' : 'hover:bg-gray-100',
        item.disabled ? 'pointer-events-none' : '',
        `flex group rounded-xl w-full py-2 pr-2 pl-2 items-center text-bmblue ${item.class}`,
      )}
      style={{
        height: itemSize === 'medium' ? '40px' : '32px',
      }}
      data-cy={item.dataCy}
      onClick={item.onClick}
    >
      {item.icon && item.iconTooltip && (
        <AntdPopover
          placement='bottom'
          mouseEnterDelay={1.0}
          content={
            <div className='flex text-gray-500 items-center'>
              <div className='w-[24px] mr-4 shrink-0'>{item.icon}</div>
              {item.iconTooltip}
            </div>
          }
        >
          <div className='w-[24px] mr-4 shrink-0'>{item.icon}</div>
        </AntdPopover>
      )}
      {item.icon && !item.iconTooltip && (
        <div className='w-[24px] mr-4 shrink-0'>{item.icon}</div>
      )}
      <div className='whitespace-nowrap text-ellipsis overflow-hidden'>
        {item.label}
      </div>
      {item.actionsMenu && (
        <Popover className='flex flex-col items-end ml-auto h-8'>
          <Popover.Button data-cy='report-item-elipsis'>
            <EllipsisHorizontalIcon className='group-hover:visible hover:bg-gray-200 rounded-lg invisible h-8 w-8 shrink-0 text-bmblue' />
          </Popover.Button>
          <Popover.Panel className='relative z-10 mr-12 mt-2'>
            {item.actionsMenu}
          </Popover.Panel>
        </Popover>
      )}
    </div>
  ),
);

export const SidebarMenu = React.memo(
  ({
    items,
    itemSize,
    isItemActive,
    onDragEnd,
  }: {
    items: MenuItem[];
    itemSize: 'small' | 'medium';
    isItemActive: (item: MenuItem) => boolean;
    onDragEnd?: (result: DropResult) => void;
  }) => {
    const [activeSubmenu, setActiveSubmenu] = useState<string | null>(null);

    const onDragEndHandler = useCallback(
      (result: DropResult) => {
        if (onDragEnd) {
          onDragEnd(result);
        }
      },
      [onDragEnd],
    );

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
      if (location.pathname !== activeSubmenu) {
        setActiveSubmenu(location.pathname);
      }
    }, [location.pathname]);

    return (
      <DragDropContext onDragEnd={onDragEndHandler}>
        <Droppable droppableId='SidebarMenu'>
          {(droppableProvided) => (
            <div
              ref={droppableProvided.innerRef}
              className='flex flex-col gap-y-5 bg-white font-size-14 leading-10 overflow-hidden'
            >
              <nav className='flex flex-1 flex-col overflow-hidden'>
                <ul className='flex flex-col space-y-2 overflow-y-auto'>
                  {items.map((item) => {
                    const isActive = isItemActive(item);
                    const isSubmenuOpen = item.key === activeSubmenu;

                    return (
                      <li
                        className='flex flex-col'
                        role='menuitem'
                        key={item.key}
                      >
                        {item.type === 'link' && (
                          <MenuLinkItem
                            item={item}
                            itemSize={itemSize}
                            isActive={isActive}
                          />
                        )}
                        {item.type === 'divider' && <Divider />}
                        {item.type === 'button' &&
                          item.sortIdx === undefined && (
                            <ButtonItem
                              item={item}
                              itemSize={itemSize}
                              isActive={isActive}
                            />
                          )}
                        {item.type === 'button' &&
                          item.sortIdx !== undefined && (
                            <DraggableButtonItem
                              item={item}
                              itemSize={itemSize}
                              isActive={isActive}
                              index={item.sortIdx}
                            />
                          )}
                        {item.type === 'submenu' && (
                          <div
                            data-cy={`sidebar-item-${item.key}`}
                            className='flex flex-col relative overflow-hidden'
                          >
                            <button
                              type='button'
                              style={{
                                minHeight:
                                  itemSize === 'medium' ? '40px' : '32px',
                              }}
                              onClick={() => {
                                setActiveSubmenu((prev) =>
                                  prev === item.key ? null : item.key,
                                );
                                if (
                                  item.href &&
                                  location.pathname !== item.href
                                ) {
                                  navigate(`${item.href}`);
                                }
                              }}
                              className='flex rounded-xl w-full py-2 pl-2 hover:bg-gray-100 items-center font-semibold text-bmblue'
                            >
                              {item.icon && (
                                <div className='w-[24px] mr-4'>{item.icon}</div>
                              )}
                              {item.label}
                              <ChevronRightIcon
                                className={classNames(
                                  isSubmenuOpen
                                    ? 'rotate-90 text-bmblue'
                                    : 'text-bmblue',
                                  'h-10 w-10 ml-auto shrink-0',
                                )}
                                aria-hidden='true'
                              />
                            </button>
                            {isSubmenuOpen && (
                              <ul
                                id='sidebar-reports'
                                className='overflow-y-auto mt-4 px-2 max-h-[22rem] scroll-bg-transparent'
                              >
                                {item.children}
                              </ul>
                            )}
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </nav>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  },
);
