import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Area, Line, LineConfig } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { Empty, Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { ChartCard } from '../../ChartCard';
import { AbsorptionGroup, Frequency, Layout } from '../../../store/types';
import { BLUE_2, BLUE_3, BLUE_4, BLUE_5, GREY_1 } from '../../../styles/constants';
import { getLineOptions } from '../../../utils/defaultChartConfig';
import { getPeriod } from '../../../utils/utils';
import { useStoreActions, useStoreState } from '../../../hooks';
import { SaleAbsorptionData } from '../../../api/mockData/projectData';

type AbsorptionData = {
  month: string;
  absorption: number;
  layout?: string;
};

type AbsorptionPeriodData = {
  period: string;
  absorption: number;
  layout?: string,
};

export const SaleAbsorption: FC = () => {
  const { t } = useTranslation();
  const { projectId } = useParams();
  const language = useStoreState((state) => state.user.profile?.language);
  const reserved_as_sold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const { fetchProjectSaleAbsorption } = useStoreActions((actions) => actions.project);
  const [freqFilter, setFreqFilter] = useState<SegmentedValue>(Frequency.MONTHLY);
  const [groupFilter, setGroupFilter] = useState<SegmentedValue>(AbsorptionGroup.SEPARATE);
  const [layoutFilter, setLayoutFilter] = useState<SegmentedValue>(Layout.OVERALL);
  const [data, setData] = useState<SaleAbsorptionData | null>(null);
  const { totalUnits } = data || { totalUnits: 0 };

  useEffect(() => {
    if (projectId) {
      fetchProjectSaleAbsorption({ project_id: Number(projectId), reserved_as_sold: !!reserved_as_sold })
      .then((res: SaleAbsorptionData) =>
        setData(res),
      );
    }
  }, [fetchProjectSaleAbsorption, projectId, reserved_as_sold]);

  const getChart = (config: LineConfig, isLayouts: boolean): ReactNode => {
    if (isLayouts) {
      return <Line {...config} />;
    }
    return <Area {...config} />;
  };

  const accumulateAbsorption = (
    accData: AbsorptionData[] | AbsorptionPeriodData[],
  ): AbsorptionData[] | AbsorptionPeriodData[] => {
    let accumulatedSum = 0;
    if ((accData as AbsorptionPeriodData[])[0]?.period !== undefined) {
      return (accData as AbsorptionPeriodData[]).map((item) => {
        accumulatedSum += item.absorption;
        return {
          period: item.period,
          absorption: accumulatedSum,
          ...(item.layout && { layout: item.layout }),
        };
      });
    }
      return (accData as AbsorptionData[]).map((item) => {
        accumulatedSum += item.absorption;
        return {
          month: item.month,
          absorption: accumulatedSum,
          ...(item.layout && { layout: item.layout }),
        };
      });
  };

  const transformedData: AbsorptionData[] = useMemo(() => {
    if (data && data.units_sold) {
      const mappedData = layoutFilter === Layout.OVERALL
      ? data.units_sold.overall.map((d) => ({
        month: dayjs(d.month).format('YYYY-MM-DD'),
        absorption: (d.count / totalUnits) * 100,
      }))
      : data.units_sold.by_layout.map((d) => ({
        month: dayjs(d.month).format('YYYY-MM-DD'),
        absorption: (d.count / totalUnits) * 100,
        layout: d.layout.toUpperCase(),
      }));
      return mappedData;
    }
    return [];
  }, [layoutFilter, data, totalUnits]);

  const periodData = useMemo(() => transformedData
      .map((d): AbsorptionPeriodData | null => {
        if (freqFilter !== Frequency.MONTHLY) {
          const period = getPeriod(d.month, freqFilter as 'quarterly' | 'semiannualy');
          return {
            period,
            absorption: d.absorption,
            layout: d.layout,
          };
        }
        return null;
      })
      .filter((d): d is AbsorptionPeriodData => d !== null)
      .reduce<AbsorptionPeriodData[]>((acc, curr) => {
        const existingItem = acc.find((item) => item.period === curr?.period && item.layout === curr?.layout);
        if (existingItem) {
          existingItem.absorption += (curr?.absorption || 0);
        } else {
          acc.push({ ...curr });
        }
        return acc;
      }, []), [transformedData, freqFilter]);

  const transformedMonthly = groupFilter === AbsorptionGroup.CUMULATIVE ? accumulateAbsorption(transformedData) : transformedData;
  const transformedPeriod = groupFilter === AbsorptionGroup.CUMULATIVE ? accumulateAbsorption(periodData) : periodData;

  const layoutOrder = ['LAYOUT_1', 'LAYOUT_1_5', 'LAYOUT_2', 'LAYOUT_3', 'LAYOUT_4'];
  const availableLayouts = Array.from(
    new Set(transformedData.map((d) => d.layout).filter((layout): layout is string => !!layout)),
  ).sort((a, b) => layoutOrder.indexOf(a) - layoutOrder.indexOf(b));

  const config = getLineOptions({
    data: freqFilter === 'monthly' ? transformedMonthly : transformedPeriod,
    xField: freqFilter === 'monthly' ? 'month' : 'period',
    yField: 'absorption',
    seriesField: layoutFilter === 'overall' ? undefined : 'layout',
    color: layoutFilter === 'overall' ? '#828282' : [BLUE_5, BLUE_4, BLUE_3, BLUE_2, GREY_1],
    xAxis: {
      label: {
        formatter: (value) => freqFilter === 'monthly' ? dayjs(value).format('MMM YY') : value,
        offset: 10,
        autoRotate: true,
      },
    },
    yAxis: {
      min: 0,
      label: {
        formatter: (value) => `${value}%`,
      },
      grid: null,
    },
    line: {
      color: '#828282',
      size: 3,
    },
    areaStyle: {
      fill: '#828282',
      fillOpacity: 0.2,
    },
    meta: {
      layout: {
        formatter: (value: string) => t(`enums.house_parts.layout_class.${value.toUpperCase()}`),
        values: availableLayouts,
      },
    },
    legend: {
      marker: {
        symbol: 'circle',
        style: {
          r: 12,
        },
      },
      padding: [20, 0, 0, 0],
      position: 'bottom',
    },
    tooltip: {
      title: (title) => freqFilter === 'monthly' ?
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }) : title,
      customContent: (title, items) => {
        let htmlString = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
          items.forEach((item) => {
            htmlString +=
              `<div class="bmt-item">
                  ${item.data.layout ?
                    `<div class="bmt-color" style="background-color: ${
                      item?.color
                    }"></div>
                    <div class="bmt-label">${t(`enums.house_parts.layout_class.${item.data.layout}`)}:</div>` : ''}
                    <div class="bmt-value">${item.data.absorption.toFixed(2)}%</div>
              </div>`;
          },
        );
        htmlString += '</div></div>';
        return htmlString;
      },
    },
  });

  const handleChangeFreq = (value: SegmentedValue): void => {
    setFreqFilter(value);
  };

  const handleChangeGroup = (value: SegmentedValue): void => {
    setGroupFilter(value);
  };

  const handleChangeLayout = (value: SegmentedValue): void => {
    setLayoutFilter(value);
  };

  return (
    <ChartCard
      className='comparison'
      title={t('project.absorption.title', 'Sale Absorption')}
      zoomInHeader
      subtitle={t(
        'project.absorption.subtitle',
        'The history of sold units as a percentage of total project units.',
      )}
      chart={
        transformedData.length > 0 ? (
          getChart(config, layoutFilter === 'layouts')
        ) : (
          <Empty className='center' image={Empty.PRESENTED_IMAGE_SIMPLE} />
        )
      }
      controls={
        <div className='segmented-controls-wrapper'>
          <Segmented
            value={freqFilter}
            options={[
              {
                value: Frequency.MONTHLY,
                label: t('sales.targets.subtitle.monthly'),
              },
              {
                value: Frequency.QUARTERLY,
                label: t('sales.targets.subtitle.quarterly'),
              },
              {
                value: Frequency.SEMIANNUALY,
                label: t('sales.targets.subtitle.semiannualy'),
              },
            ]}
            onChange={handleChangeFreq}
          />
          <Segmented
            value={groupFilter}
            options={[
              {
                value: AbsorptionGroup.SEPARATE,
                label: t('enums.separate', 'Separate'),
              },
              {
                value: AbsorptionGroup.CUMULATIVE,
                label: t('enums.cumulative', 'Cumulative'),
              },
            ]}
            onChange={handleChangeGroup}
          />
          <Segmented
            value={layoutFilter}
            options={[
              {
                value: Layout.OVERALL,
                label: t('dashboard.sale_progress.overall', 'Overall'),
              },
              {
                value: Layout.LAYOUTS,
                label: t('dashboard.sale_progress.layouts', 'Layouts'),
              },
            ]}
            onChange={handleChangeLayout}
          />
        </div>
      }
    />
  );
};
