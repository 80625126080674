/** @jsxImportSource @emotion/react */
import { Popover, PopoverProps } from 'antd';
import { FC } from 'react';
import { Link, LinkProps } from 'react-router-dom';
import { ArrowsPointingOutIcon } from '@heroicons/react/24/outline';
import { styles } from './styles';

interface Props {
  content: PopoverProps['content'];
  to: LinkProps['to'];
}

export const MaximizeIcon: FC<Props> = ({ content, to }) => (
  <Popover content={content}>
    <div css={styles.container}>
      <Link css={styles.link} to={to} target='_blank'>
        <ArrowsPointingOutIcon className='w-8 bm-icon' />
      </Link>
    </div>
  </Popover>
);
