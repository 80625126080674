/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/** @jsxImportSource @emotion/react */
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Checkbox, Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { fetchBlockSaleSpeedData } from '../../../../api';
import { useFlatLayout, useRegionFormatting, useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { styles } from '../styles';
import { CompetitorSelect } from '../CompetitorSelect';
import { Data, SaleSpeedChart } from './SaleSpeedChart';
import { SaleSpeedData } from '../../../../api/types';
import { sortByString, getUniqueValues, getLastUpdateText, getReportExportFileName, translateBlockChartValue } from '../../../../utils/utils';
import { Availability } from '../../../../store/types';
import { CardPopover } from '../CardPopover';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { UpdateDates } from '../../../../api/enums';
import { PaginatedChartCard } from '../../../PaginatedChartCard';

enum FilterEnum {
  OVERALL,
  LAYOUTS,
}

type CountsByType = {
  [key: string]: number;
};

export const SaleSpeed: FC = () => {
  const [data, setData] = useState<SaleSpeedData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [showPrivate, setShowPrivate] = useState(false);
  const [showB2B, setShowB2B] = useState(true);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const competitors = useStoreState(
    (state) => state.market.selectedCompetitors,
  );
  const {
    t,
    i18n: { language },
  } = useTranslation();
  const { getLayout } = useFlatLayout();
  const { filterValue, handleChange } = useFilter(FilterEnum.OVERALL);
  const isOverall = filterValue === FilterEnum.OVERALL;
  const translateValue = (value: string): string =>
    isOverall ? translateBlockChartValue(value, t) : getLayout(value);
  const { displayValues } = useStoreState((state) => state.filters);
  const filterAvailability = useFilter(Availability.SOLD);
  const { sortLayouts } = useRegionFormatting();
  const pngRef = useRef(null);
  const [modalData, setModalData] = useState();
  const isSold = filterAvailability.filterValue === Availability.SOLD;
  const [isModalVisible, setIsModalVisible] = useState(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getSaleCount = (counts: CountsByType): number => {
    let count = 0;
    if (showPrivate) {
      count += counts.private ?? 0;
    }
    if (showB2B) {
      count += counts.b2b ?? 0;
    }
    count += counts.public ?? 0;
    return count;
  };

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockSaleSpeedData(
        paramsPM.dashboardId,
        paramsPM.date,
        competitors,
        pricePerSmCalculation,
      )
        .then((response) => {
          const receivedData = [...response.data].filter(
            (d) => typeof d.availability === 'string',
          );
          const layouts = getUniqueValues(receivedData.map((v) => v.layout));
          const dates = getUniqueValues(receivedData.map((v) => v.date));
          dates.forEach((date) => {
            layouts.forEach((layout) => {
              if (
                receivedData.findIndex(
                  (rd) => rd.date === date && rd.layout === layout,
                ) === -1
              ) {
                receivedData.push({
                  date,
                  value: 0,
                  layout,
                  project_name: 'current_dashboard',
                  series: 'current_dashboard',
                  count: 0,
                  counts_by_type: {},
                  availability: Availability.AVAILABLE,
                });
              }
            });
          });
          const transformedData = receivedData
          .map(({ layout, series, count, counts_by_type, ...rest }) => {
            // for competitors counts_by_type is not provided
            let countsByType = counts_by_type;
            if (!countsByType && 'sale_type' in rest && typeof rest.sale_type === 'string' && 'value' in rest && typeof rest.value === 'number') {
              countsByType = { [rest.sale_type]: rest.value };
            }
            return {
              layout: layout ? layout.toLowerCase() : null,
              series: !series || series === 'current_dashboard' ? 'current_dashboard' : series,
              count: countsByType ? getSaleCount(countsByType) : count,
              counts_by_type: countsByType || {},
              ...rest,
            };
          })
            .sort(
              (
                { date: dateA, layout: layoutA },
                { date: dateB, layout: layoutB },
              ) => {
                if (dateA === dateB) {
                  return sortLayouts(String(layoutA), String(layoutB));
                }
                return sortByString(dateA, dateB);
              },
            );
          setData(transformedData);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, competitors, sortLayouts, pricePerSmCalculation, showPrivate, showB2B]);

  const chartData: Data[] = useMemo(() => {
    const filteredData = data.filter((d) => dayjs(d.date).isBefore(dayjs().subtract(UpdateDates.LAST_5_DAYS, 'days')));
    switch (filterValue) {
      case FilterEnum.OVERALL:
        return filteredData
          .map((value) => ({
            ...value,
            series:
              value.series === 'current_dashboard'
                ? value.availability
                : value.project_name,
            value: value.count,
          }))
          .reduce<Data[]>((prev, curr) => {
            const index = prev.findIndex(
              (p) =>
                p.date === curr.date &&
                p.series === curr.series &&
                p.availability === curr.availability,
            );
            if (index > -1) {
              const newArr: Data[] = [...prev];
              const newCnt = newArr[index].count ?? 0 + 1;
              const prevValue = newArr[index].value;
              if (prevValue !== null && curr.value !== null) {
                newArr[index].value =
                  (prevValue * (newArr[index].count ?? 0) + curr.value) /
                  newCnt;
              }
              newArr[index].count = newCnt;
              // Summing counts_by_type
              const updatedCountsByType = { ...newArr[index].counts_by_type };
              if (curr.counts_by_type) {
                for (const key in curr.counts_by_type) {
                  if (Object.prototype.hasOwnProperty.call(curr.counts_by_type, key)) {
                    if (updatedCountsByType[key]) {
                      updatedCountsByType[key] += curr.counts_by_type[key];
                    } else {
                      updatedCountsByType[key] = curr.counts_by_type[key];
                    }
                  }
                }
              }
              newArr[index].counts_by_type = updatedCountsByType;
              return newArr;
            }
            return [...prev, { ...curr, count: 1 }];
          }, [])
          .filter((d) => d.availability === filterAvailability.filterValue);
      case FilterEnum.LAYOUTS:
        return filteredData
          .filter(
            (d) =>
              d.series === 'current_dashboard' && typeof d.layout === 'string',
          )
          .map((v) => ({ ...v, value: v.count }))
          .filter((d) => d.availability === filterAvailability.filterValue);

      default:
        return [];
    }
  }, [filterValue, data, filterAvailability.filterValue]);

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('market.reports.sale_speed.title'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <PaginatedChartCard
        chart='Column'
        title={t('market.reports.sale_speed.title', 'Sale Speed')}
        subtitle={`${t(
          'market.reports.sale_speed.subtitle',
          'The number of sold units in the last 30 days in the selected projects.',
        )} ${getLastUpdateText(UpdateDates.LAST_5_DAYS, t)}`}
        extra={
          !isLoading && <CardPopover
            showValues
            onDownloadPNG={() => downloadPNG(fileName, pngRef)}
            extraComponents={[
              <div className='actions' role='presentation' onClick={() => setShowPrivate(!showPrivate)}>
                <div>
                  {t('market.reports.sale_speed.show_private', 'Show private')}
                </div>
                <Checkbox checked={showPrivate} />
              </div>,
              <div className='actions' role='presentation' onClick={() => setShowB2B(!showB2B)}>
                <div>
                  {t('market.reports.sale_speed.show_b2b', 'Show B2B')}
                </div>
                <Checkbox checked={showB2B} />
              </div>,
            ]}
          />
        }
        infoPopoverContent={
          <div css={styles.saleSpeed.popoverContainer}>
            <p>
              {t(
                'market.reports.sale_speed.popover.description',
                "This chart doesn't display reservations as sales regardless of your settings.",
              )}
            </p>
          </div>
        }
        data={chartData || null}
        isSold={filterAvailability.filterValue === Availability.SOLD}
        isOverall={isOverall}
        CustomComponent={SaleSpeedChart}
        showPrivate={showPrivate}
        showB2B={showB2B}
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        modalData={modalData}
        controls={
          <div className='segmented-controls-wrapper'>
            <Segmented
              style={{ marginRight: '1.2rem' }}
              value={filterAvailability.filterValue}
              options={[
                {
                  value: Availability.SOLD,
                  label: t('enums.state.sales'),
                },
                {
                  value: Availability.RESERVED,
                  label: t('enums.state.reservations'),
                },
              ]}
              onChange={filterAvailability.handleChange}
            />
            <Segmented
              value={filterValue}
              options={[
                {
                  value: FilterEnum.OVERALL,
                  label: t('dashboard.sale_progress.overall', 'Overall'),
                },
                {
                  value: FilterEnum.LAYOUTS,
                  label: t('dashboard.sale_progress.layouts', 'Layouts'),
                },
              ]}
              onChange={handleChange}
            />
            {isOverall && (
              <CompetitorSelect />
            )}
          </div>
        }
      />
    </div>
  );
};
