import { FC, useMemo } from 'react';
import { useStoreActions, useStoreState } from '../../hooks';
import styles from './PriceUpdateNotifications.module.css';
import { NotificationCard } from './NotificationCard';

export const PriceUpdateNotifications: FC = () => {
  // @ts-ignore TODO: Fix typing
  // const updateData = useStoreState((state) => state.priceList.updateData) as PriceListUpdateData;
  const overviewData = useStoreState((state) => state.filters.overviewData);
  const { setProjectId } = useStoreActions((actions) => actions.filters);
  const { isLoading } = useStoreState((state) => state.filters);

  const handleSeeMore = (selectProjectId: number): void => {
    if (!isLoading) {
      setProjectId(selectProjectId);
    }
  };

  return (
    <div className={styles.notificationsContainer}>
      {overviewData?.updates.map((update) => (
        <NotificationCard
          key={update.name}
          projectName={update.name || ''}
          userSeen={update.user_seen}
          handleHasSeen={() => handleSeeMore(update.project_id)}
        />
      ))}
    </div>
  );
};
