/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
import { FC, memo, useEffect, useMemo, useRef, useState } from 'react';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { Datum } from '@antv/g2plot';
import { fetchBlockPriceHistoryData } from '../../../../api';
import { useFlatLayout, useRegionFormatting, useStoreState } from '../../../../hooks';
import { useFilter } from '../useFilter';
import { Data, PriceHistoryChart } from './PriceHistoryChart';
import { styles } from '../styles';
import { CompetitorSelect } from '../CompetitorSelect';
import { PriceHistoryPrimaryData } from '../../../../api/types';
import { getReportExportFileName, sortByString, translateBlockChartValue } from '../../../../utils/utils';
import { State } from '../../../../api/enums';
import { useReservedData } from '../../../../hooks/useReservedData';
import { Availability } from '../../../../store/types';
import { PricePerSmCalcType } from '../../../TopBar/SettingsPopover';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { PaginatedChartCard } from '../../../PaginatedChartCard';
import { getLineOptions } from '../../../../utils/defaultChartConfig';
import { AVAILABLE as AVAILABLE_COLOR, BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5, GREY_1, GREY_2, GREY_3, SOLD as SOLD_COLOR } from '../../../../styles/constants';

enum FilterEnum {
  OVERALL,
  LAYOUTS,
}

export enum AvailabilityFilterEnum {
  SOLD = 'sold',
  AVAILABLE = 'available',
  ALL = 'all'
}

export enum PriceType {
  PRICE = 'flat_prices_price',
  PRICE_PER_SM = 'avg_price'
}

const getChart = (
  availability: AvailabilityFilterEnum,
  isOverall: boolean,
): 'Line' | 'Area' => {
  if (availability === AvailabilityFilterEnum.ALL || !isOverall) {
    return 'Line';
  }
  return 'Area';
};

export const PriceHistory: FC = memo(() => {
  const [data, setData] = useState<PriceHistoryPrimaryData[]>([]);
  const [isLoading, setLoading] = useState(true);
  const paramsPM = useStoreState((state) => state.filters.paramsPM);
  const competitors = useStoreState(
    (state) => state.market.selectedCompetitors,
  );
  const isSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const pricePerSmCalculation = useStoreState(
    (state) => state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { filterValue, handleChange } = useFilter(FilterEnum.OVERALL);
  const filterAvailability = useFilter(AvailabilityFilterEnum.AVAILABLE);
  const [priceType, setPriceType] = useState<PriceType>(PriceType.PRICE_PER_SM);
  const { calculateVatPrice, areaUnit, sortLayouts } = useRegionFormatting();
  const { mapReservedData } = useReservedData();
  const isOverall = filterValue === FilterEnum.OVERALL;
  const pngRef = useRef(null);

  const chartData: Data[] = useMemo(() => {
    switch (filterValue) {
      case FilterEnum.OVERALL:
        return mapReservedData(data)
          .filter((d) => d.layout === 'all_layouts')
          .filter((d) => d.avg_price !== null)
          .filter((d) => {
            // Do not display deprecated 'reserved' units
            if (d.availability === 'reserved') {
              return false;
            }
            // Toggle: RESERVED AS SOLD
            // 1. hide 'available_reserved', because the toggle is on for 'reserved_sold'
            // 2. hide 'sold', because the reserved units should be displayed as sold instead
            if ((d.availability === 'available_reserved' || d.availability === Availability.SOLD) && isSold) {
              return false;
            }
            // Toggle: AVAILABLE AS RESERVED
            // 1. hide 'reserved_sold', because the toggle is on for 'available_reserved'
            // 2. hide 'available', because the reserved units
            // should be displayed as available instead
            if ((d.availability === 'reserved_sold' || d.availability === Availability.AVAILABLE) && !isSold) {
              return false;
            }
            // Filter out 'not_seen'
            if (d.availability === 'not_seen') {
              return false;
            }

            return true;
          })
          .reduce<Data[]>((prev, curr) => {
            const index = prev.findIndex(
              (p) =>
                p.date === curr.date &&
                p.series === curr.series &&
                p.availability === curr.availability,
            );
            let newAvailability = curr.availability;
            if (isSold && curr.availability === 'reserved_sold') newAvailability = Availability.SOLD;
            if (!isSold && curr.availability === 'available_reserved') newAvailability = Availability.AVAILABLE;
            curr.availability = newAvailability;
            if (index > -1) {
              const newArr: Data[] = [...prev];
              const newCnt = newArr[index].count + 1;
              const prevAvgPrice = newArr[index].avg_price;
              if (prevAvgPrice !== null && curr.avg_price !== null) {
                newArr[index].avg_price =
                  (prevAvgPrice * newArr[index].count + curr.avg_price) /
                  newCnt;
              }
              newArr[index].count = newCnt;
              return newArr;
            }
            return [...prev, { ...curr, count: 1 }];
          }, [])
          .map(
            ({
              series,
              availability,
              avg_price,
              date,
              project_name,
              ...rest
            }) => ({
              series:
                series === 'current_dashboard' ? availability : project_name,
              availability,
              avg_price: calculateVatPrice(avg_price, 120),
              date,
              project_name,
              ...rest,
            }),
          );

      case FilterEnum.LAYOUTS: {
        const layoutsData = data.filter((d) => d.layout !== 'all_layouts' && d.series === 'current_dashboard');
        const allLayouts = layoutsData
          .filter((d) => {
            if (d.availability !== State.AVAILABLE && d.availability !== 'available_reserved') {
              return false;
            }

            return isSold
              ? d.availability === State.AVAILABLE
              : d.availability === 'available_reserved';
          }).map((v) => ({ ...v, avg_price: calculateVatPrice(v.avg_price, 120), count: 1 }));
        const soldData = (isSold ? layoutsData.filter((d) => d.availability === 'reserved_sold') : layoutsData.filter((d) => d.availability === 'sold')).map((v) => ({ ...v, avg_price: calculateVatPrice(v.avg_price, 120), count: 1 }));
        const availableData = (!isSold ? layoutsData.filter((d) => d.availability === 'available_reserved') : layoutsData.filter((d) => d.availability === 'available')).map((v) => ({ ...v, avg_price: calculateVatPrice(v.avg_price, 120), count: 1 }));
        const getFilteredData = (): Data[] => {
          if (filterAvailability.filterValue === AvailabilityFilterEnum.SOLD) {
            return soldData;
          }
          if (filterAvailability.filterValue === AvailabilityFilterEnum.AVAILABLE) {
            return availableData;
          }
          return allLayouts;
        };
        const filteredData = getFilteredData();
        return filteredData;
      }
      default:
        return [];
    }
  }, [filterValue, mapReservedData, data, isSold, calculateVatPrice, filterAvailability.filterValue]);

  useEffect(() => {
    if (paramsPM) {
      setLoading(true);
      fetchBlockPriceHistoryData(
        paramsPM.dashboardId,
        paramsPM.date,
        competitors,
        pricePerSmCalculation,
      )
        .then((response) => {
          setData(
            response.data
              .map((receivedData) => ({
                ...receivedData,
                layout: receivedData.layout.toLowerCase(),
                // i really dont want to change the whole component, so this is a 'fix' to also handle full price data, not just price per sm
                avg_price: priceType === PriceType.PRICE_PER_SM ? receivedData.avg_price : receivedData.flat_prices_price,
              }))
              .sort(
                (
                  { date: dateA, layout: layoutA },
                  { date: dateB, layout: layoutB },
                ) => {
                  if (dateA === dateB) {
                    return sortLayouts(layoutA, layoutB);
                  }
                  return sortByString(dateA, dateB);
                },
              ),
          );
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [paramsPM, competitors, sortLayouts, pricePerSmCalculation, priceType]);

  const { formatCurrency } = useRegionFormatting();
  const { displayValues } = useStoreState((state) => state.filters);
  const { getLayout } = useFlatLayout();
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const minPrice = useMemo(() => {
    const minAvgPrice = data.reduce((min, item) => {
      if (item.avg_price === null) {
        return min; // Skip this item if the price is null
      }
      return Math.min(min, item.avg_price);
    }, Infinity);
    return Math.max(0, minAvgPrice * 0.95);
  }, [data]);

  const getColor = (): string[] => {
    if (isOverall) {
      if (filterAvailability.filterValue === AvailabilityFilterEnum.SOLD) {
        return [SOLD_COLOR];
      }
      if (filterAvailability.filterValue === AvailabilityFilterEnum.AVAILABLE) {
        return [AVAILABLE_COLOR];
      }
      // make sure that available is always blue
      if (data[0].series === 'available') {
        return [AVAILABLE_COLOR, SOLD_COLOR, BLUE_3, BLUE_2, BLUE_5];
      }
      return [SOLD_COLOR, AVAILABLE_COLOR, BLUE_3, BLUE_2, BLUE_5];
    }
    return [BLUE_5, BLUE_4, BLUE_3, BLUE_2, GREY_1, GREY_2, GREY_3, BLUE_1];
  };

  const translateValue = (value: string): string =>
    isOverall ? translateBlockChartValue(value, t) : getLayout(value);

  const labelFormatter = (() => {
    let index = 0;
    return ({ avg_price }: Datum) => {
      const label = index % 4 === 0 ? formatCurrency(Number(avg_price), undefined, undefined, priceType !== PriceType.PRICE_PER_SM) ?? '' : '';
      index++;
      return label;
    };
  })();

  const config = getLineOptions({
    smooth: true,
    data: data.sort((a, b) => {
      if (
        a.project_name === 'current_dashboard' &&
        b.project_name !== 'current_dashboard'
      ) {
        return -1;
      }
      if (
        a.project_name !== 'current_dashboard' &&
        b.project_name === 'current_dashboard'
      ) {
        return 1;
      }
      return 0;
    }),
    color: getColor(),
    xField: 'date',
    yField: 'avg_price',
    seriesField: isOverall ? 'series' : 'layout',
    label: displayValues ? {
      formatter: labelFormatter,
      style: {
        color: '#000',
      },
      position: 'top',
      rotate: -Math.PI / 3,
      offsetX: 15,
    } : undefined,
    tooltip: {
      formatter: (datum) => {
        const value = datum as PriceHistoryPrimaryData;
        return {
          name: isOverall ? String(value.series) : value.layout,
          value: formatCurrency(value.avg_price) ?? '-',
        };
      },
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          day: 'numeric',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${translateValue(item?.name)}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      type: 'time',
      label: {
        formatter: (value) => {
          const dateValue = new Date(value);
          return dateValue.toLocaleDateString(language, {
            month: 'long',
            day: 'numeric',
          });
        },
      },
      tickMethod: 'time',
    },
    yAxis: {
      label: {
        formatter: (value) => formatCurrency(Number(value)),
      },
      min: minPrice,
    },
    legend: {
      itemName: {
        formatter: translateValue,
      },
    },
  });

  const fileName = getReportExportFileName(paramsPM?.name || 'report', t('market.reports.price_history.title', 'price-history'));
  const allData = chartData;
  const overallData = chartData.filter((d) => d.availability === filterAvailability.filterValue);

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
      <PaginatedChartCard
        title={t('market.reports.price_history.title', 'Price History')}
        extra={!isLoading && <CardPopover showValues onDownloadPNG={() => downloadPNG(fileName, pngRef)} />}
        subtitle={t(
          'market.reports.price_history.subtitle',
          'The history of the average price per square meter of the selected projects.',
        )}
        chart={getChart(filterAvailability.filterValue as AvailabilityFilterEnum, isOverall)}
        config={config}
        data={
          filterAvailability.filterValue === AvailabilityFilterEnum.ALL
              ? allData
              : filterValue === FilterEnum.OVERALL
                ? overallData
                : allData
        }
        isOverall={isOverall}
        priceType={priceType}
        CustomComponent={PriceHistoryChart}
        controls={
          <div className='segmented-controls-wrapper'>
            <Segmented
              value={filterAvailability.filterValue}
              options={[
                {
                  value: AvailabilityFilterEnum.AVAILABLE,
                  label: t('enums.state.available'),
                },
                {
                  value: AvailabilityFilterEnum.SOLD,
                  label: isSold ? t('enums.state.sales_reservations') : t('enums.state.sales'),
                },
                {
                  value: AvailabilityFilterEnum.ALL,
                  label: t('enums.state.both'),
                },
              ]}
              onChange={filterAvailability.handleChange}
            />
            <Segmented
              value={filterValue}
              options={[
                {
                  value: FilterEnum.OVERALL,
                  label: t('dashboard.sale_progress.overall', 'Overall'),
                },
                {
                  value: FilterEnum.LAYOUTS,
                  label: t('dashboard.sale_progress.layouts', 'Layouts'),
                },
              ]}
              onChange={handleChange}
            />
            <Segmented
              value={priceType}
              options={[
                {
                  value: PriceType.PRICE_PER_SM,
                  label: t('common.price_per_unit', 'Price per {{unit}}', {
                    unit: areaUnit,
                  }),
                },
                {
                  value: PriceType.PRICE,
                  label: t('common.price', 'Price'),
                },
              ]}
              onChange={(val) => setPriceType(val as PriceType)}
            />
              {isOverall && (
                  <CompetitorSelect />
              )}
          </div>
        }
      />
    </div>
  );
});
