/** @jsxImportSource @emotion/react */
import { FC, useMemo } from 'react';
import { TableProps, Table } from 'antd';
import { useTranslation } from 'react-i18next';
import { BuildingOfficeIcon, ChartBarIcon, TagIcon } from '@heroicons/react/24/outline';
import { getSumFromNotNullValues, getAvg } from '../../../../../../utils/utils';
import { useFlatLayout, useRegionFormatting } from '../../../../../../hooks';
import { styles } from '../../styles';
import { ProjectDetailTableData, ProjectDetailTableProps } from '../types';
import { InfoPopover } from '../../../../../InfoPopover';
import { formatStringValue } from '../../../../../../utils/formatters';
import { useMarketDetails } from '../../../../../../hooks/useMarketDetails';
import { ReactComponent as LayoutIcon } from '../../../../../../assets/icons/layout.svg';
import { ReactComponent as FloorAreaIcon } from '../../../../../../assets/project-card/floor-area.svg';

interface Summary {
  price_per_sm: number;
  interior_sm: number;
  price: number;
}

export const ProjectDetailTable: FC<ProjectDetailTableProps> = ({
  priceList,
  floorCoefficient,
  customSummary = [],
  allowSorting = false,
  using_total_area = false,
  ...rest
}) => {
  const { isRentals } = useMarketDetails();
  const { t } = useTranslation();
  const { formatBigNumber, formatAreaUnits, areaUnit } = useRegionFormatting();
  const { getLayoutAbbr } = useFlatLayout();
  const summary: Summary = useMemo(
    () => ({
      price_per_sm: getAvg(priceList.map(({ price_per_sm }) => price_per_sm)),
      interior_sm: getAvg(priceList.map(({ floor_area }) => floor_area)),
      price: getAvg(priceList.map(({ price }) => price)),
    }),
    [priceList],
  );
  const columns: TableProps<ProjectDetailTableData>['columns'] = useMemo(
    () => [
      {
        title: (
          <div className='flex items-center justify-center space-x-2'>
            <LayoutIcon className='w-7 h-7 stroke-2' />
            <span>
              {t('project.table.layout', 'Layout')}
            </span>
          </div>
        ),
        dataIndex: 'layout',
        render: (value) => getLayoutAbbr(value),
        align: 'center',
        sorter: allowSorting ? (a, b) => {
          const parseLayout = (layout: string): number => {
            if (layout === 'unknown') return Infinity; // Handling unknown as infinity for sorting
            // Extracting digits and converting them to a floating point number
            const numberPart = layout.match(/\d+(\.\d+)?/); // This regex finds the first sequence of digits possibly followed by a decimal
            const numericValue = numberPart ? parseFloat(numberPart[0]) : Infinity; // If no number is found, treat as Infinity
            return numericValue;
          };
          const valueA = parseLayout(a.layout);
          const valueB = parseLayout(b.layout);
          return valueA - valueB;
        } : undefined,
      },
      {
        title: (
          <div className='flex items-center justify-center space-x-2'>
            <BuildingOfficeIcon className='w-8 h-8 stroke-2' />
            <span>
              {t('project.table.units', 'Units')}
            </span>
          </div>
        ),
        dataIndex: 'available',
        align: 'center',
        sorter: allowSorting ? (a, b) => a.available - b.available : undefined,
      },
      using_total_area ? {
        title: () =>
          <div className='flex items-center justify-center space-x-2'>
            <FloorAreaIcon className='w-8 h-8 stroke-2' />
            <span>
              {t('project.table.total_area_unit', 'Total area {{unit}}', {
                unit: areaUnit,
              })}
            </span>
            <InfoPopover
              popoverProps={{
                content: t('project.table.total_area_unit.info', 'The price per SM was calculated using the total area as the website doesn\'t contain the floor area.'),
              }}
            />
          </div>,
        dataIndex: 'interior_sm',
        render: (value) => formatAreaUnits(floorCoefficient ? value * floorCoefficient : value),
        align: 'center',
        sorter: allowSorting ? (a, b) => (a.interior_sm ?? 0) - (b.interior_sm ?? 0) : undefined,
      } : {
        title: () =>
          <div className='flex items-center justify-center space-x-2'>
            <FloorAreaIcon className='w-8 h-8 stroke-2' />
            <span>
              {t('project.table.interior_unit', 'Interior {{unit}}', {
                unit: areaUnit,
              })}
            </span>
            {floorCoefficient && floorCoefficient !== 1 && (
              <InfoPopover
                popoverProps={{
                  content: formatStringValue(t('project.table.interior_unit.info'), floorCoefficient, '{Num}'),
                }}
              />
            )}
          </div>,
        dataIndex: 'interior_sm',
        render: (value) => value ? formatAreaUnits(floorCoefficient ? value * floorCoefficient : value) : null,
        align: 'center',
        sorter: allowSorting ? (a, b) => (a.interior_sm ?? 0) - (b.interior_sm ?? 0) : undefined,
      },
      {
        title: () =>
          <div className='flex items-center justify-center space-x-2'>
            <ChartBarIcon className='w-8 h-8 stroke-2' />
            <span>
              {isRentals ? t('rentals.rent_per_sm', 'Rent per {{unit}}', {
                unit: areaUnit,
              }) : t('project.table.price_per_unit', 'AVG Price per {{unit}}', {
                unit: areaUnit,
              })}
            </span>
            {using_total_area && (
              <InfoPopover
                popoverProps={{
                  content: t('project.table.total_area_price_per_sm.info', 'The price per SM was calculated using the total area as the website doesn\'t contain the floor area.'),
                }}
              />
            )}
          </div>,
        dataIndex: 'price_per_sm',
        render: (value) => formatBigNumber(value),
        align: 'center',
        sorter: allowSorting ? (a, b) => (a.price_per_sm ?? 0) - (b.price_per_sm ?? 0) : undefined,
      },
      {
        title: (
          <div className='flex items-center justify-center space-x-2'>
            <TagIcon className='w-8 h-8 stroke-2' />
            <span>
              {t('project.table.price', 'Price')}
            </span>
          </div>
        ),
        dataIndex: 'price',
        render: (value) => formatBigNumber(value),
        align: 'center',
        sorter: allowSorting ? (a, b) => (a.price ?? 0) - (b.price ?? 0) : undefined,
      },
    ],
    [allowSorting, areaUnit, floorCoefficient, formatAreaUnits, formatBigNumber, getLayoutAbbr, isRentals, t],
  );
  return (
    <Table<ProjectDetailTableData>
      css={styles.table}
      rowKey='layout'
      columns={columns}
      pagination={false}
      size='small'
      summary={(pageData) => (
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} />
          <Table.Summary.Cell index={1} align='center'>
            <b>{customSummary.find((obj) => obj.key === 'available')?.value ?? getSumFromNotNullValues(pageData.map((pd) => pd.available))}</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} align='center'>
            <b>{customSummary.find((obj) => obj.key === 'interior_sm')?.value ?? formatAreaUnits(summary.interior_sm)}</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} align='center'>
            <b>{customSummary.find((obj) => obj.key === 'price_per_sm')?.value ?? summary.price_per_sm}</b>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={4} align='center'>
            <b>{customSummary.find((obj) => obj.key === 'price')?.value ?? summary.price}</b>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      )}
      {...rest}
    />
  );
};
