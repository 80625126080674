import { Scatter, ScatterConfig } from '@ant-design/plots';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Empty, Segmented } from 'antd';
import { SegmentedValue } from 'antd/lib/segmented';
import { Availability } from '../../../store/types';
import { AVAILABLE, BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5, SOLD } from '../../../styles/constants';
import { ChartCard } from '../../ChartCard';
import { useRegionFormatting, useStoreState } from '../../../hooks';

export const ScatterPlot: FC = () => {
  const { t } = useTranslation();
  const { formatCurrency } = useRegionFormatting();
  const reservedAsSold = useStoreState((state) => state.user.profile?.reserved_as_sold);
  const priceListDataRaw = useStoreState((state) => state.project.data?.price_list);
  const [filterValue, setFilterValue] = useState<SegmentedValue>(Availability.AVAILABLE);

  const reservedAvailability = reservedAsSold ? Availability.SOLD : Availability.AVAILABLE;
  const floorAreaUnit = 'm2';

  const data = useMemo(() => {
    const priceListData = priceListDataRaw?.filter((item) => item.price_per_sm !== null);
    if (priceListData) {
      return priceListData.map((item) => ({
        id: item.id,
        availability: item.availability === Availability.RESERVED ? reservedAvailability : item.availability,
        price_per_sm: item.price_per_sm,
        floor_area: item.floor_area,
        exterior_area: item.exterior_area,
        layout: item.layout,
      }))
      .sort((a, b) => {
        const getLayoutValue = (layout: string): number => {
        const parts = layout.split('_');
        return parseFloat(`${parts[1]}.${parts[2] || '0'}`);
    };

    const layoutA = getLayoutValue(a.layout);
    const layoutB = getLayoutValue(b.layout);
    return layoutA - layoutB;
    });
    }
    return [];
  }, [priceListDataRaw, reservedAvailability]);

  const colors = useMemo(() => {
    const availabilities = [...new Set(data.map((d) => d.availability))];
    const result = [];
    for (const a of availabilities) {
      switch (a) {
        case Availability.AVAILABLE:
          result.push(AVAILABLE);
          break;
        case Availability.SOLD:
          result.push(SOLD);
          break;
        default:
          break;
      }
    }
    return result;
  }, [data]);

  const config: ScatterConfig = {
    data: filterValue === 'both' ? data : data.filter((d) => d.availability === filterValue),
    xField: 'floor_area',
    yField: 'price_per_sm',
    colorField: filterValue === 'both' ? 'availability' : 'layout',
    color: filterValue === 'both' ? colors : [BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5],
    shape: 'circle',
    size: 5,
    legend: {
      itemName: {
        formatter: (value) => filterValue === 'both' ? t(`enums.state.${value}`) : t(`enums.house_parts.layout_class.${value.toUpperCase()}`),
      },
      marker: {
        symbol: 'circle',
        style: {
          r: 12,
        },
      },
      padding: [20, 0, 0, 0],
      position: 'bottom',
    },
    yAxis: {
      label: {
        formatter: (value) => formatCurrency(Number(value)),
      },
      grid: null,
    },
    xAxis: {
      label: {
        formatter: (value) => `${value} ${floorAreaUnit}`,
        offset: 10,
      },
      grid: null,
    },
    tooltip: {
      customContent: (title, items) => {
        if (items && items.length) {
          const item = items[0];
          return `<div style="padding: 10px; border-radius: 5px; background: white; display: flex; flex-direction: column; row-gap: 5px">
                    <div>${item.data.id}</div>
                    <div><strong>${item.data.floor_area} ${floorAreaUnit}</strong></div>
                    <div><strong>${formatCurrency(item.data.price_per_sm)}</strong></div>
                  </div>`;
        }
        return '';
      },
      crosshairs: {
        type: undefined,
      },
    },
    regressionLine: {
      type: 'quad',
      style: {
        stroke: '#ccc',
        lineWidth: 2,
        lineDash: [4, 4],
      },
    },
  };

  const handleChange = (value: SegmentedValue): void => {
    setFilterValue(value);
  };

  return (
      <ChartCard
        className='comparison'
        title={t('project.scatter.title', 'Scatter Plot')}
        zoomInHeader
        subtitle={t(
          'project.scatter.subtitle',
          'The relationship between floor area and price per SM.',
        )}
        chart={
          data.length > 0 ? (
            <Scatter className='chart-container' {...config} />
          ) : (
            <Empty className='center' image={Empty.PRESENTED_IMAGE_SIMPLE} />
          )
        }
        controls={
          <Segmented
            value={filterValue}
            options={[
              {
                value: Availability.AVAILABLE,
                label: t('enums.state.available'),
              },
              {
                value: Availability.SOLD,
                label: t('enums.state.sold'),
              },
              {
                value: 'both',
                label: t('enums.state.both'),
              },
            ]}
            onChange={handleChange}
          />
        }
      />
  );
};
