import { ColumnType } from 'antd/lib/table';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { formatDifference, getNumericSorter } from '../../utils';
import { Formatters } from '../../hooks/useRegionFormatting';
import { PriceListTableData } from '../../api/mockData/priceListData';
import { sortByString } from '../../utils/utils';
import { AvailabilityBadge } from '../AvailabilityBadge/AvailablityBadge';
import { K, M } from '../../constants';

const smallCol = 100;
const mediumCol = 130;
const largeCol = 150;

export const getUpdateColumns = (
  t: TFunction,
  formatCurrency: Formatters['formatCurrency'],
  formatAreaUnits: Formatters['formatAreaUnits'],
  layouts: string[],
  availabilities: string[],
  getLayout: (value: string, dynamicPricing?: boolean) => string,
  getLayoutAbbr: (value: string, dynamicPricing?: boolean) => string,
  showPricePerSM: boolean,
  sortLayouts: Formatters['sortLayouts'],
  isRent: boolean,
  developerId?: number,
): ColumnType<PriceListTableData>[] => {
  const columns: ColumnType<PriceListTableData>[] = [
    {
      dataIndex: 'internal_id',
      title: t('price_list.table_column_headers.id'),
      render: (value, { id }) => (
        <Link to={`/price-list/${id}`}>
          <b>{value}</b>
        </Link>
      ),
      sorter: (a, b) => a.internal_id.localeCompare(b.internal_id),
      width: smallCol,
    },
    {
      dataIndex: 'layout',
      title: () => (
        <Tooltip title={t('price_list.table_column_headers.layout')}>
          {t('price_list.table_column_headers.layout')}
        </Tooltip>
      ),
      render: (value) => getLayoutAbbr(value, true),
      ellipsis: { showTitle: true },
      sorter: ({ layout: layout_a }, { layout: layout_b }) =>
        sortLayouts(layout_a, layout_b),
      filters: layouts.map((value) => ({
        text: getLayout(value, true),
        value,
      })),
      onFilter: (value, record) => record.layout === value,
      width: mediumCol,
    },
    {
      dataIndex: 'floor',
      title: () => (
        <Tooltip title={t('price_list.table_column_headers.floor')}>
          {t('price_list.table_column_headers.floor')}
        </Tooltip>
      ),
      ellipsis: { showTitle: true },
      sorter: getNumericSorter('floor'),
      width: smallCol,
    },
    {
      dataIndex: 'floor_area',
      title: () => (
        <Tooltip title={t('price_list.table_column_headers.floor_area')}>
          {t('price_list.table_column_headers.floor_area')}
        </Tooltip>
      ),
      render: (value) => formatAreaUnits(value),
      ellipsis: { showTitle: true },
      sorter: getNumericSorter('floor_area'),
      width: mediumCol,
    },
    {
      dataIndex: 'exterior_area',
      title: () => (
        <Tooltip title={t('price_list.table_column_headers.exterior_area')}>
          {t('price_list.table_column_headers.exterior_area')}
        </Tooltip>
      ),
      render: (value) => formatAreaUnits(value),
      ellipsis: { showTitle: true },
      sorter: getNumericSorter('exterior_area'),
      width: mediumCol,
    },
    {
      dataIndex: 'availability',
      title: () => (
        <Tooltip title={t('price_list.table_column_headers.availability')}>
          {t('price_list.table_column_headers.availability')}
        </Tooltip>
      ),
      render: (value) => (
        <AvailabilityBadge unitAvailability={value} type='table' />
      ),
      ellipsis: true,
      sorter: (
        { availability: availability_a },
        { availability: availability_b },
      ) =>
        sortByString(
          t(`enums.state.${availability_a}`),
          t(`enums.state.${availability_b}`),
        ),
      filters: availabilities.map((value) => ({
        text: t(`enums.state.${value}`),
        value,
      })),
      onFilter: (value, record) => record.availability === value,
      width: largeCol,
    },
    {
      dataIndex: 'current_price',
      title: () => (
        <Tooltip
          title={t(
            `price_list.table_column_headers.current_${
              isRent ? 'rent' : 'price'
            }`,
            `Current ${isRent ? 'Rent' : 'Price'}`,
          )}
        >
          {t(
            `price_list.table_column_headers.current_${
              isRent ? 'rent' : 'price'
            }`,
            `Current ${isRent ? 'Rent' : 'Price'}`,
          )}
        </Tooltip>
      ),
      align: 'right',
      render: (value, { current_price_per_sm }) => {
        const price = showPricePerSM ? current_price_per_sm : value;
        return formatCurrency(
          price,
          false,
          Math.abs(price) > M,
          Math.abs(price) < M && Math.abs(price) > K,
        );
      },
      ellipsis: true,
      sorter: getNumericSorter(
        showPricePerSM ? 'current_price_per_sm' : 'current_price',
      ),
    },
    {
      dataIndex: 'recommended_price',
      title: () => (
        <Tooltip
          title={t(
            `price_list.table_column_tooltips.recommended_${
              isRent ? 'rent' : 'price'
            }`,
          )}
        >
          {t(
            `price_list.table_column_headers.recommended_${
              isRent ? 'rent' : 'price'
            }`,
          )}
        </Tooltip>
      ),
      align: 'right',
      render: (value, { recommended_price_per_sm }) => {
        const price = showPricePerSM ? recommended_price_per_sm : value;
        return formatCurrency(
          price,
          false,
          Math.abs(price) > M,
          Math.abs(price) < M && Math.abs(price) > K,
        );
      },
      sorter: getNumericSorter(
        showPricePerSM ? 'recommended_price_per_sm' : 'recommended_price',
      ),
      ellipsis: true,
    },
    {
      dataIndex: 'feature_update',
      title: () => (
        <Tooltip
          title={t(
            'price_list.table_column_headers.feature_update',
            'Feature Update',
          )}
        >
          {t(
            'price_list.table_column_headers.feature_update',
            'Feature Update',
          )}
        </Tooltip>
      ),
      render: (value) => (value ? formatDifference(value) : null),
      sorter: getNumericSorter('feature_update'),
      align: 'right',
      ellipsis: true,
    },
    {
      dataIndex: 'total_update',
      title: () => (
        <Tooltip
          title={t(
            'price_list.table_column_headers.total_update',
            'Tot. Update',
          )}
        >
          {t('price_list.table_column_headers.total_update', 'Tot. Update')}
        </Tooltip>
      ),
      render: (value) => (value ? formatDifference(value) : null),
      sorter: getNumericSorter('total_update'),
      ellipsis: true,
      align: 'right',
    },
    {
      dataIndex: 'columnFilter',
      title: () => <span />,
      width: '5rem',
    },
  ];

  return columns;
};
