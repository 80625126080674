import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { getProject, getProjectFloorOverview, getProjectPriceHistory, getProjectSaleAbsorption, getProjectSaleSpeedPerLayout } from '../api';
import { ExtendedProjectDetailTableData, StoreModel } from './types';
import { FloorOverviewParams, PriceHistoryParams, PriceHistoryProjectDataRaw, Project, SaleAbsorptionParams } from '../api/mockData/projectData';
import { aggregatePriceListData, logError } from '../utils/utils';
import { PricePerSMCalcEnum } from '../api/enums';

export interface ProjectStore {
  data?: Project;
  aggregatedTableData: Computed<
    ProjectStore,
    ExtendedProjectDetailTableData[],
    StoreModel
  >;
  setProject: Action<ProjectStore, Project>;
  fetchProject: Thunk<ProjectStore, number, unknown, StoreModel>;
  fetchProjectSaleProgress: Thunk<ProjectStore, number, unknown, StoreModel>;
  fetchProjectSaleAbsorption: Thunk<ProjectStore, SaleAbsorptionParams, unknown, StoreModel>;
  fetchProjectPriceHistory: Thunk<ProjectStore, PriceHistoryParams, unknown, StoreModel>;
  fetchProjectFloorOverview: Thunk<ProjectStore, FloorOverviewParams, unknown, StoreModel>;
}

export const projectStore: ProjectStore = {
  aggregatedTableData: computed(
    [(state) => state.data?.price_list ?? []],
    (data) => aggregatePriceListData(data),
  ),
  setProject: action((state, newProject: Project) => {
    state.data = newProject;
  }),
  fetchProject: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
    } = helpers.getStoreActions();
    const {
      user: { profile },
    } = helpers.getStoreState();
    setGlobalLoading(true);
    try {
      const { data } = await getProject(
        payload,
        profile?.price_per_sm_calculation ??
          PricePerSMCalcEnum.WITHOUT_EXTERIOR,
        profile?.VAT_included,
        profile?.reserved_as_sold,
      );
      actions.setProject(data);
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setIsLoaded(true);
    }
  }),
  fetchProjectSaleProgress: thunk(async (_actions, payload) => {
    try {
      const { data } = await getProjectSaleSpeedPerLayout(payload);
      return data;
    } catch (error) {
      logError(error);
    }
    return [];
  }),
  fetchProjectSaleAbsorption: thunk(async (_actions, payload) => {
    try {
      const { data } = await getProjectSaleAbsorption(payload);
      return data;
    } catch (error) {
      logError(error);
    }
    return [];
  }),
  fetchProjectPriceHistory: thunk(async (_actions, payload) => {
    try {
      const { data } = await getProjectPriceHistory(payload);
      const transformedData = data.map((d: PriceHistoryProjectDataRaw) => {
        if (d.availability === 'available_reserved') {
          return {
            availability: 'available',
            date: d.date,
            avg_price: d.flat_prices_price,
            avg_price_per_sm: d.avg_price,
            layout: d.layout.toLowerCase(),
          };
        }
        if (d.availability === 'reserved_sold') {
          return {
            availability: 'sold',
            date: d.date,
            avg_price: d.flat_prices_price,
            avg_price_per_sm: d.avg_price,
            layout: d.layout.toLowerCase(),
          };
        }
        return {
          availability: d.availability,
          date: d.date,
          avg_price: d.flat_prices_price,
          avg_price_per_sm: d.avg_price,
          layout: d.layout.toLowerCase(),
        };
      });
      return transformedData;
    } catch (error) {
      logError(error);
    }
    return [];
  }),
  fetchProjectFloorOverview: thunk(async (_actions, payload) => {
    try {
      const { data } = await getProjectFloorOverview(payload);
      return data;
    } catch (error) {
      logError(error);
    }
    return [];
  }),
};
