import { FC, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ColumnConfig } from '@ant-design/plots';
import { Segmented } from 'antd';
import { useTranslation } from 'react-i18next';
import { SegmentedValue } from 'antd/lib/segmented';
import dayjs from 'dayjs';
import { useStoreActions, useStoreState } from '../../../hooks';
import { ProjectSaleSpeed } from '../../../api/mockData/projectData';
import { Availability } from '../../../store/types';
import { BLUE_1, BLUE_2, BLUE_3, BLUE_4, BLUE_5, GREY_1, GREY_2, GREY_3 } from '../../../styles/constants';
import { getLastUpdateText } from '../../../utils/utils';
import { UpdateDates } from '../../../api/enums';
import { PaginatedChartCard } from '../../PaginatedChartCard';

type TransformedData = {
    date: string;
    count: number;
    sale_type: string;
}

enum FilterEnum {
  LAYOUTS,
  OVERALL,
}

export const SaleSpeed: FC = () => {
  const { t } = useTranslation();
  const language = useStoreState((state) => state.user.profile?.language);
  const { projectId } = useParams();
  const { fetchProjectSaleProgress } = useStoreActions((actions) => actions.project);
  const [data, setData] = useState<ProjectSaleSpeed[]>([]);
  const [availabilityValue, setAvailabilityValue] = useState<SegmentedValue>(Availability.SOLD);
  const [filterValue, setFilterValue] = useState<SegmentedValue>(FilterEnum.OVERALL);
  const isOverall = filterValue === FilterEnum.OVERALL;
  const containsPrivateSales = data.find((item) => item.sale_type === 'private');
  const { displayValues } = useStoreState((state) => state.filters);

  useEffect(() => {
    if (projectId) {
      fetchProjectSaleProgress(Number(projectId)).then((res: ProjectSaleSpeed[]) => {
        setData(res);
      });
    }
  }, [fetchProjectSaleProgress, projectId]);

  const transformByDate = (dataByAvailability: ProjectSaleSpeed[]): TransformedData[] => Object.values(
      dataByAvailability.reduce((acc, item) => {
        const key = `${item.date}-${item.sale_type}`;

        if (!acc[key]) {
          acc[key] = {
            date: item.date,
            sale_type: item.sale_type,
            count: 0,
          };
        }

        acc[key].count += item.count;

        return acc;
      }, {} as Record<string, TransformedData>),
    );

  const dataByAvailability = useMemo(() => data
    .filter((item) => item.availability === availabilityValue)
    .filter((d) => dayjs(d.date).isBefore(dayjs().subtract(UpdateDates.LAST_5_DAYS, 'days')))
    .sort((a, b) => dayjs(a.date).diff(dayjs(b.date))), [availabilityValue, data]);

  const getColor = (value: 'sold' | 'reserved'): string | string[] => {
    if (value === 'sold') {
      return containsPrivateSales ? [GREY_2, GREY_1] : GREY_1;
    }
    return containsPrivateSales ? [BLUE_1, BLUE_2] : BLUE_1;
  };
  const filteredData = isOverall ? transformByDate(dataByAvailability) : dataByAvailability;
  const layoutOrder = ['LAYOUT_1', 'LAYOUT_1_5', 'LAYOUT_2', 'LAYOUT_3', 'LAYOUT_4', 'UNKNOWN'];
  const availableLayouts = Array.from(
    new Set(dataByAvailability.map((d) => d.layout).filter((layout): layout is string => !!layout)),
  ).sort((a, b) => layoutOrder.indexOf(a) - layoutOrder.indexOf(b));

  const config: Omit<ColumnConfig, 'data'> = {
    xField: 'date',
    yField: 'count',
    seriesField: isOverall ? 'sale_type' : 'layout',
    isStack: true,
    // eslint-disable-next-line no-nested-ternary
    color: isOverall
      ? availabilityValue === Availability.SOLD
        ? getColor('sold')
        : getColor('reserved')
      : [BLUE_5, BLUE_4, BLUE_3, BLUE_2, GREY_1, GREY_2, GREY_3, BLUE_1],
    label: displayValues
      ? {
          position: isOverall ? 'top' : 'middle',
          style: {
            fill: '#000000',
            opacity: 0.6,
            padding: 2,
          },
          layout: [{ type: 'adjust-color' }],
        }
      : undefined,
    xAxis: {
      label: {
        formatter: (date: string) => dayjs(date).format('MMM YY'),
        autoRotate: true,
      },
    },
    meta: {
      layout: {
        values: availableLayouts,
      },
    },
    yAxis: {
      grid: null,
      max: Math.max(...transformByDate(dataByAvailability).map((item) => item.count)) * 1.5,
    },
    marginRatio: 0.3,
    columnStyle: {
      // eslint-disable-next-line no-nested-ternary
      radius: isOverall
        ? containsPrivateSales
          ? [0, 0, 0, 0]
          : [7, 7, 0, 0]
        : [0, 0, 0, 0],
    },
    tooltip: {
      title: (title) =>
        new Date(title).toLocaleDateString(language, {
          month: 'long',
          year: 'numeric',
        }),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${item?.color
            }"></div>
              <div class="bmt-label">${isOverall ? t(`common.sale_type.${item?.data.sale_type}`) : t(`enums.house_parts.layout_class.${item.data.layout}`)}:</div>
              <div class="bmt-value">${item?.data.count}</div>
            </div>
            `;
        });
        return htmlStr;
      },
    },
    legend: {
      itemName: {
        formatter: (value) => isOverall ? t(`common.sale_type.${value}`) : t(`enums.house_parts.layout_class.${value}`),
      },
      padding: [20, 0, 0, 0],
      position: 'bottom',
      marker: {
        symbol: 'circle',
        style: {
          r: 12,
        },
      },
    },
  };

  return (
    <PaginatedChartCard
      className='comparison'
      title={t('market.reports.sale_speed.title', 'Sale Speed')}
      zoomInHeader
      subtitle={`${t('market.reports.sale_speed.subtitle', 'The history of the number of sold units.')} ${getLastUpdateText(UpdateDates.LAST_5_DAYS, t)}`}
      infoPopoverContent={
        <div className='max-w-[300px]'>
          <p>
            {t(
              'market.reports.sale_speed.popover.description',
              "This chart doesn't display reservations as sales regardless of your settings.",
            )}
          </p>
        </div>
      }
      chart='Column'
      config={config}
      data={filteredData}
      controls={
        <div className='segmented-controls-wrapper'>
        <Segmented
          value={availabilityValue}
          options={[
            {
              value: Availability.SOLD,
              label: t('enums.state.sold', 'Sold'),
            },
            {
              value: Availability.RESERVED,
              label: t('enums.state.reserved', 'Reserved'),
            },
          ]}
          onChange={setAvailabilityValue}
        />
          <Segmented
            value={filterValue}
            options={[
              {
                value: FilterEnum.OVERALL,
                label: t('dashboard.sale_progress.overall', 'Overall'),
              },
              {
                value: FilterEnum.LAYOUTS,
                label: t('dashboard.sale_progress.layouts', 'Layouts'),
              },
          ]}
            onChange={setFilterValue}
          />
        </div>
      }
    />
  );
};
