import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';
import * as Dialog from '@radix-ui/react-dialog';
import { XMarkIcon } from '@heroicons/react/24/solid';

type CardTitleProps = {
  className?: string;
};

export const CardTitle: FC<PropsWithChildren<CardTitleProps>> = ({
  children,
  className,
}) => (
  <div className={clsx(className, 'flex gap-4 items-center font-bold text-bmblue')}>
    {children}
  </div>
);

type CardHeaderProps = {
  className?: string;
};

export const CardHeader: FC<PropsWithChildren<CardHeaderProps>> = ({
  className,
  children,
}) => (
  <div
    className={clsx(
      className,
      'px-6 py-8 flex items-start justify-between w-full',
    )}
  >
    {children}
  </div>
);

type CardContentProps = {
  className?: string;
};

export const CardContent: FC<PropsWithChildren<CardContentProps>> = ({
  className,
  children,
}) => <div className={clsx(className, 'px-4 pb-5 sm:px-6 sm:pb-6')}>{children}</div>;

type CardOptionsProps = {
  className?: string;
};

export const CardOptions: FC<PropsWithChildren<CardOptionsProps>> = ({
  className,
  children,
}) => (
  <div className={clsx(className, 'flex items-center gap-4 relative z-5')}>
    {children}
  </div>
);

type CardProps = {
  divider?: boolean;
  className?: string;
  open?: boolean;
  centerContent?: boolean;
  onOpenChange?: () => void;
};

export const Card: FC<PropsWithChildren<CardProps>> = ({
  divider = false,
  className,
  children,
  open,
  centerContent = true,
  onOpenChange,
}) => (
  <div
    className={clsx(
      className,
      divider && 'divide-y',
      centerContent && 'flex flex-col justify-between',
      'divide-gray-200 overflow-hidden rounded-3xl h-full bg-white shadow text-bmblue',
    )}
  >
    <Dialog.Root open={open} onOpenChange={onOpenChange}>
      {children}
      {centerContent && <div />}
      <Dialog.Portal>
        <Dialog.Overlay className='fixed inset-0 z-[100] bg-[rgba(0,0,0,0.45)] data-[state=open]:animate-overlayShow' />
        <Dialog.Content className='text-[1.4rem] z-[101] fixed left-1/2 top-1/2 max-h-[85vh] w-[90vw] max-w-[1200px] -translate-x-1/2 -translate-y-1/2 p-4 rounded-3xl bg-white shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none data-[state=open]:animate-contentShow'>
          {children}
          <Dialog.Close asChild>
            <XMarkIcon
              className='absolute right-8 top-8 stroke-2 w-10 h-10 text-[#DBDBDB] cursor-pointer'
              aria-label='close'
            />
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  </div>
);
