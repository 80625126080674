/** @jsxImportSource @emotion/react */
import { forwardRef, ReactNode } from 'react';
import { Card as AntdCard, CardProps as AntdCardProps } from 'antd';
import { styles } from './styles';
import { InfoPopover } from '../InfoPopover';
import { NoDataContainer } from '../NoDataContainer';

export interface CardProps extends AntdCardProps {
  subtitle?: ReactNode;
  titleExtra?: ReactNode;
  titleClassname?: string;
  infoPopoverTitle?: string;
  infoPopoverContent?: ReactNode;
  dataMissing?: boolean;
  dataMissingMessage?: string;
}

export const Card = forwardRef<HTMLDivElement, CardProps>(
  (
    {
      infoPopoverContent,
      infoPopoverTitle,
      title,
      subtitle,
      titleExtra,
      titleClassname,
      dataMissing = false,
      dataMissingMessage,
      ...rest
    },
    ref, // Receive the forwarded ref here
  ) => (
    dataMissing ? (
      <AntdCard ref={ref} css={styles.card}>
        <NoDataContainer message={dataMissingMessage} />
      </AntdCard>
    ) : (
      <AntdCard
        ref={ref} // Attach the forwarded ref to the AntdCard
        css={styles.card}
        extra={undefined}
        title={
          (title || subtitle || titleExtra) && (
            <div className='flex flex-col w-full'>
              <div css={styles.titleContainer} className={titleClassname}>
                <div css={styles.title}>{title}</div>
                <div css={styles.extra}>{titleExtra}</div>
              </div>
              <div css={styles.subtitle}>
                {subtitle}
                {(infoPopoverTitle || infoPopoverContent) && (
                  <InfoPopover
                    popoverProps={{
                      content: infoPopoverContent,
                      title: infoPopoverTitle,
                    }}
                  />
                )}
              </div>
            </div>
          )
        }
        {...rest}
      />
    )
  ),
);
