import { FC } from 'react';
import { EllipsisHorizontalIcon } from '@heroicons/react/24/solid';
import { Checkbox, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const handlePropagation = (e?: MouseEvent): void => {
  e?.stopPropagation();
};

type MoreOptionsProps = {
  setShowAvailable: () => void;
  showAvailabile: boolean;
  setShowSoldAndReserved: () => void;
  showSoldAndReserved: boolean;
};

export const MoreOptions: FC<MoreOptionsProps> = ({
  setShowAvailable,
  showAvailabile,
  setShowSoldAndReserved,
  showSoldAndReserved,
}) => {
  const { t } = useTranslation();

  const handleAvailableToggle = (): void => {
    if (showAvailabile && !showSoldAndReserved) {
      return;
    }
    setShowAvailable();
  };

  const handleSoldAndReservedToggle = (): void => {
    if (showSoldAndReserved && !showAvailabile) {
      return;
    }
    setShowSoldAndReserved();
  };

  return (
    <div className='flex gap-4'>
      <Tooltip
        placement='bottom'
        trigger='click'
        title={
          <div className='flex gap-4 flex-col'>
            <div onClick={() => handleAvailableToggle()}>
              <span className='pr-4'>
                {t(
                  'dashboard.positioning.more_options.available',
                  'Show Available Units',
                )}
              </span>
              <Checkbox checked={showAvailabile} />
            </div>
            <div onClick={() => handleSoldAndReservedToggle()}>
              <span className='pr-4'>
                {t(
                  'dashboard.positioning.more_options.sold_reserved',
                  'Show Sold and Reserved Units',
                )}
              </span>
              <Checkbox checked={showSoldAndReserved} />
            </div>
          </div>
        }
      >
        <div role='button' tabIndex={0} onClick={() => handlePropagation()}>
          <EllipsisHorizontalIcon className='stroke-2 w-10 h-10' />
        </div>
      </Tooltip>
    </div>
  );
};
