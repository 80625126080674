import {
  ArrowsPointingOutIcon,
  ArrowsPointingInIcon,
  MinusIcon,
  PlusIcon,
} from '@heroicons/react/24/outline';
import { DrawingManager } from '@react-google-maps/api';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Button, Popover } from 'antd';
import {
  Dispatch,
  FC,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { ReactComponent as RecenterSvg } from '../../assets/map-controls/recenter.svg';
import styles from './MapControls.module.css';
import { SimpleProjectsTable } from '../addReport/SimpleProjectsTable';
import { useStoreState } from '../../hooks';
import { Mode } from '../../store/types';
import { Selection } from '../addReport/Selection';
import { Actions } from '../market/Actions';
import { useTerritorySelection } from '../../hooks/useTerritorySelection';
import { ControlButtonsGroup } from './MapEditControls';
import { MapProjectStatusTags, MapType } from './MapProjectStatusTags';
import { SimplifiedProjects } from '../../store/market';
import { TerritoryInput } from '../addReport/TerritoryInput';

type Props = {
  mapParam: google.maps.Map;
  handleRecenter?: (mapParam: google.maps.Map) => void;
  defaultZoom?: number;
  handleModalOpen?: () => void;
  drawingManagerRef?: MutableRefObject<DrawingManager | null>;
  setIsHovered?: Dispatch<SetStateAction<string | null>>;
  openModal?: boolean;
  projects?: SimplifiedProjects[];
  mapTypeForTags?: MapType;
};

export const MapControls: FC<Props> = ({
  mapParam,
  handleRecenter,
  defaultZoom = 11,
  handleModalOpen,
  drawingManagerRef,
  openModal,
  setIsHovered,
  projects,
  mapTypeForTags,
}) => {
  const { t } = useTranslation();
  const { handleTerritorySelect } = useTerritorySelection();
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [mapType, setMapType] = useState<'roadmap' | 'satellite'>('roadmap');
  const { mode } = useStoreState((state) => state.market);
  const isReading = mode === Mode.READ;

  const handleFullscreenToggle = (): void => {
    const mapElement = mapParam.getDiv();
    if (!isFullscreen) {
      if (mapElement.requestFullscreen) {
        mapElement.requestFullscreen();
      }
      setIsFullscreen(true);
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
      setIsFullscreen(false);
    }
  };

  const handleFullscreenChange = (): void => {
    if (!document.fullscreenElement) {
      setIsFullscreen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullscreenChange);
    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
    };
  }, []);

  const handleMapTypeChange = (type: 'roadmap' | 'satellite'): void => {
    setMapType(type);
    mapParam.setMapTypeId(type);
  };

  const handleZoom = (type: 'in' | 'out'): void => {
    const currentZoom = mapParam.getZoom() || defaultZoom;
    mapParam.setZoom(type === 'in' ? currentZoom + 1 : currentZoom - 1);
  };

  const setDrawingMode = (
    drawingMode: google.maps.drawing.OverlayType | null,
  ): void => {
    drawingManagerRef?.current?.state.drawingManager?.setDrawingMode(
      drawingMode,
    );
  };

  return (
    <>
      {!isReading ? (
        <div className='flex absolute justify-center top-10 right-10 left-10 gap-10 items-center pointer-events-none flex-wrap-reverse'>
          <div className='flex justify-center items-center gap-10'>
            <ControlButtonsGroup setDrawingMode={setDrawingMode} />
            <TerritoryInput onSelect={handleTerritorySelect} />
          </div>
          <MapProjectStatusTags mapType={mapTypeForTags} />
        </div>
      ) : (
        <>
          <div className='flex absolute justify-left w-[80%] top-10 right-10 left-10 gap-10 items-center pointer-events-none flex-wrap-reverse'>
            <MapProjectStatusTags mapType={mapTypeForTags} />
          </div>
          <Button
            type='ghost'
            className={styles.fullScreenBtn}
            onClick={handleFullscreenToggle}
          >
            {isFullscreen ? (
              <ArrowsPointingInIcon className={styles.icon} />
            ) : (
              <ArrowsPointingOutIcon className={styles.icon} />
            )}
          </Button>
        </>
      )}
      <div className='absolute flex left-10 bottom-10 justify-between right-10 pointer-events-none'>
        <div className='flex gap-4 flex-wrap-reverse'>
          {!isReading ? (
            <Selection
              setIsHovered={setIsHovered}
              mapParam={mapParam}
              projects={projects}
            />
          ) : null}
          <Button.Group
            className={clsx(styles.zoomBtnGrp, 'pointer-events-auto -z-1')}
          >
            <Button
              className={styles.mapBtn}
              onClick={() => handleMapTypeChange('roadmap')}
              style={{ fontWeight: mapType === 'roadmap' ? 700 : 400 }}
            >
              {t('market.reports.map_controls.map', 'Map')}
            </Button>
            <div className='bg-bmlightgrey w-1' />
            <Button
              className={styles.mapBtn}
              onClick={() => handleMapTypeChange('satellite')}
              style={{ fontWeight: mapType === 'satellite' ? 700 : 400 }}
            >
              {t('market.reports.map_controls.satelite', 'Satelite')}
            </Button>
          </Button.Group>
        </div>
        <div className='flex items-end'>{!isReading && <Actions />}</div>
        <div className='flex items-end justify-between'>
          <div className='flex gap-4 pointer-events-none flex-wrap justify-end'>
            <Popover
              content={t(
                'market.reports.map_controls.tooltips.recenter',
                'Recenter',
              )}
            >
              <Button
                type='ghost'
                className={clsx(styles.centerBtn, 'pointer-events-auto')}
                onClick={() => handleRecenter && handleRecenter(mapParam)}
              >
                <RecenterSvg className={styles.icon} />
              </Button>
            </Popover>
            <Button.Group
              className={clsx('pointer-events-auto', styles.zoomBtnGrp)}
            >
              <Button
                className={styles.zoomBtn}
                onClick={() => handleZoom('out')}
              >
                <MinusIcon className={styles.icon} />
              </Button>
              <div className='bg-bmlightgrey w-1' />
              <Button
                className={styles.zoomBtn}
                onClick={() => handleZoom('in')}
              >
                <PlusIcon className={styles.icon} />
              </Button>
            </Button.Group>
            {!isReading && (
              <SimpleProjectsTable
                handleModalOpen={handleModalOpen}
                openModal={openModal ?? false}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};
