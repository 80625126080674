import { FC, useEffect, useState } from 'react';
import { Button, Col, Modal, Row, message } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MagnifyingGlassIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Page, PriceListTable } from '../components';
import {
  useStoreActions,
  useStoreState,
  useBreakpoints,
} from '../hooks';
import {
  PriceListDetailFilters,
  PriceOverviewUpdate,
} from '../components/priceListDetail';
import { Paths } from '../routes/Paths';
import { BackLink } from '../components/BackLink';
import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import { PriceListDetailPagination } from './PriceListDetailPagination';
import { transformPricingPriceListData } from '../utils/utils';
import { DetailPreviewCardUpdated } from '../components/priceListDetail/DetailPreviewCard/DetailPreviewCardUpdated';
import { PriceBreakdownUpdated } from '../components/priceListDetail/PriceBreakdown/PriceBreakdownUpdated';
import { GoogleAnalyticsChartUpdated } from '../components/priceListDetail/GoogleAnalyticsChartUpdated';
import { PricingPriceListDetailData } from '../api/mockData/pricingPriceListDetaildata';
import { ComparisonBreakdownUpdated } from './PriceListComparison/ComprisionBreakdownUpdated';
import { ComparisonBreakdownUnitUpdated } from './PriceListComparison/ComparisonBreakdownUnitUpdated';

export const PriceListDetailPage: FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const { unitId } = useParams();
  const [isComparisonOpen, setIsComparisonOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUnits, setSelectedUnits] = useState<number[]>(
    unitId ? [Number(unitId)] : [],
  );
  const { fetchComparisonDataUpdated } = useStoreActions(
    (state) => state.priceListDetail,
  );
  const detailData = useStoreState(
    (state) => state.priceListDetail.pricePricingDetailData,
  );
  const tableDataIds = useStoreState((state) => state.priceList.data)?.map(
    (unit) => unit.id,
  );
  const tableDataIdsUpdated = useStoreState(
    (state) => state.priceList.pricingData,
  )?.units.map((unit) => unit.id);
  const [comparisonDataUpdated, setComparisonDataUpdated] = useState<
    PricingPriceListDetailData[]
  >([]);
  const {
    showPredictionUpdated,
    comparisonPredictionUpdated,
  } = useStoreState((state) => state.priceListDetail);
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );

  const { t } = useTranslation();
  const fetchData = useStoreActions(
    (actions) => actions.priceListDetail.fetchData,
  );
  const VAT_included = useStoreState(
    (state) => state.user.profile?.VAT_included,
  );

  const selectCompare = (id: number, action: 'add' | 'remove'): void => {
    if (action === 'add') {
      if (selectedUnits.length === 3) {
        message.info('You can only compare up to 3 units');
        return;
      }
      setSelectedUnits([...selectedUnits, id]);
    } else {
      setSelectedUnits(selectedUnits.filter((unit) => unit !== id));
    }
  };

  const confirmCompare = async (): Promise<void> => {
    if (selectedUnits.length < 2) {
      message.info('You need to select at least 1 unit');
      return;
    }
    if (!detailData) {
      message.error('Data not found');
      return;
    }
    setIsLoading(true);
    try {
      const fetchIds = selectedUnits.filter((unit) => unit !== Number(unitId));
      const responseUpdated = await fetchComparisonDataUpdated(fetchIds);
      if (responseUpdated && detailData) {
        setComparisonDataUpdated([detailData, ...responseUpdated]);
      }
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setIsComparisonOpen(false);
    }
  };

  useEffect(() => {
    if (!!comparisonDataUpdated.length) {
      confirmCompare();
    }
  }, [VAT_included]);

  const closeCompare = (): void => {
    setIsComparisonOpen(false);
    setSelectedUnits(unitId ? [Number(unitId)] : []);
  };

  const cancelCompare = (): void => {
    setSelectedUnits(unitId ? [Number(unitId)] : []);
    setComparisonDataUpdated([]);
  };

  useEffect(() => {
    const convertedUnitId = Number(unitId);
    if (!Number.isNaN(convertedUnitId)) {
      fetchData(convertedUnitId);
    }
  }, [fetchData, unitId, pricePerSmCalculation, VAT_included]);

  const priceDataUpdated = detailData
    ? {
        recommendedPrice: detailData?.common_data.recommended_price ?? 0,
        priceUpdate: detailData?.common_data.recommended_price_update ?? 0,
        recommendedPricePsm:
          detailData?.common_data.recommended_price_per_sm ?? 0,
        priceUpdatePsm:
          detailData?.common_data.recommended_price_update_per_sm ?? 0,
        priceUpdatePercentage:
          (detailData?.common_data?.update_percentage ?? 0) * 100,
      }
    : {
        recommendedPrice: 0,
        priceUpdate: 0,
        recommendedPricePsm: 0,
        priceUpdatePsm: 0,
        priceUpdatePercentage: 0,
      };

  const showPredictionUnitsUpdated = [
    showPredictionUpdated && unitId && Number(unitId),
    ...comparisonPredictionUpdated,
  ];

  return (
    <Page
      pageClassname='price-list-detail overflow-x-auto'
      title={
        <div className='flex space-x-5 items-center'>
          <BackLink to={Paths.PRICE_LIST}>
            <ArrowLeftOutlined /> {t('price_list.title')}
          </BackLink>
          {' / '}
          {t('price_list.detail.title', {
            id: null,
          })}{' '}
          {detailData?.common_data?.name}
          {(tableDataIds?.length || tableDataIdsUpdated?.length) && (
            <PriceListDetailPagination
              tableDataIds={
                tableDataIds?.length ? tableDataIds : tableDataIdsUpdated || []
              }
            />
          )}
          {!isSmallScreen && (
            <div className='flex items-center space-x-2'>
              <Button
                className='bm-default-btn'
                onClick={() => setIsComparisonOpen(true)}
              >
                <div className='flex space-x-1 items-center'>
                  <MagnifyingGlassIcon className='w-7 h-7 stroke-[3px]' />
                  <span className='font-semibold'>
                    {t('market.projects.add_report_form.compare', 'Compare')}
                  </span>
                </div>
              </Button>
              {!!comparisonDataUpdated.length && (
                <Button
                  className='bm-default-btn'
                  onClick={() => cancelCompare()}
                >
                  <div className='flex space-x-1 items-center'>
                    <XMarkIcon className='w-7 h-7 stroke-[3px]' />
                    <span className='font-semibold'>
                      {t('common.close', 'Close')}
                    </span>
                  </div>
                </Button>
              )}
            </div>
          )}
        </div>
      }
      filters={!isSmallScreen && <PriceListDetailFilters />}
    >
      {!!comparisonDataUpdated.length ? (
        <div className='relative 2xl:justify-center flex space-x-10'>
          <ComparisonBreakdownUpdated />
          {!!comparisonDataUpdated.length &&
            comparisonDataUpdated.map((unit) => (
              <ComparisonBreakdownUnitUpdated
                unit={unit}
                showPrediction={showPredictionUnitsUpdated.includes(
                  unit.common_data.id,
                )}
              />
            ))}
        </div>
      ) : (
        <Row className='wrapper' gutter={[16, 16]}>
          {detailData && (
            <Col xs={24} xl={9}>
              <DetailPreviewCardUpdated
                data={transformPricingPriceListData(detailData)}
              />
            </Col>
          )}
          {detailData && detailData?.price_breakdown && (
            <Col xs={24} xl={15}>
              <PriceBreakdownUpdated
                priceData={priceDataUpdated}
                data={detailData?.price_breakdown}
                isHouse={detailData.common_data.unit_type === 'house'}
                price={detailData?.common_data.current_price}
              />
            </Col>
          )}
          <Col xs={24}>
            <Row gutter={[16, 16]}>
              {detailData?.price_overview && (
                <Col xs={24} xl={12}>
                  <PriceOverviewUpdate data={detailData?.price_overview} />
                </Col>
              )}
              <Col xs={24} xl={12}>
                <GoogleAnalyticsChartUpdated
                  data={detailData?.google_analytics}
                  internalId={detailData?.common_data?.name}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Modal
        footer={
          <div className='flex space-x-5 items-center justify-center w-full'>
            <Button className='bm-default-btn' onClick={() => closeCompare()}>
              {t('common.close', 'Close')}
            </Button>
            <Button
              className='bm-submit-btn'
              loading={isLoading}
              disabled={isLoading}
              onClick={() => confirmCompare()}
            >
              {t('market.projects.add_report_form.compare', 'Compare')}
            </Button>
          </div>
        }
        open={isComparisonOpen}
        centered
        width='80%'
        onCancel={() => setIsComparisonOpen(false)}
      >
        <PriceListTable
          isCompare
          selectCompare={selectCompare}
          selectedUnits={selectedUnits}
        />
      </Modal>
    </Page>
  );
};
