/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { LinkOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import { Link, generatePath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TagIcon } from '@heroicons/react/24/solid';
import { LinkIcon, MapPinIcon, UserGroupIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { Paths } from '../../routes/Paths';
import { MaximizeIcon } from '../MaximizeIcon';
import styles from './ProjectCard.module.css';
import { MarketProjectExtended } from '../../store/types';
import {
  MapProjectStatusTag,
  TAGS_MOCK,
} from '../MapControls/MapProjectStatusTags';
import { Export } from '../project/Export';

interface Props {
  data: Pick<
    MarketProjectExtended,
    | 'project_id'
    | 'project_name'
    | 'project_url'
    | 'address'
    | 'developer'
    | 'news'
    | 'discounts'
    | 'project_phase'
  >;
  onClose?: () => void;
}

export const ProjectCardHeader: FC<Props> = ({
  data: {
    address,
    developer,
    project_id,
    project_name,
    project_url,
    news,
    discounts,
    project_phase,
  },
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div className={styles.title}>
          {onClose
            ? (
              <Link
                to={generatePath(Paths.PROJECT_DETAIL, {
                  projectId: project_id,
                })}
                target='_blank'
              >
                {project_name}
              </Link>)
            : (
              <span>{project_name}</span>
            )}
        </div>
        {onClose ? (
          <div className='project-link-container'>
          {project_url && (
            <Popover
              content={t(
              'project.links.project_website',
              'Open the project website',
            )}
            >
              <div className='project-link'>
                <a href={project_url} target='_blank' rel='noopener noreferrer'>
                  <LinkIcon className='bm-icon w-8' />
                </a>
              </div>
            </Popover>
          )}
          <MaximizeIcon
            content={t(
              'project.links.project_detail',
              'Open detail of the project',
            )}
            to={generatePath(Paths.PROJECT_DETAIL, {
              projectId: project_id,
            })}
          />
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <XMarkIcon
            className='bm-icon w-8 cursor-pointer'
            onClick={() => onClose()}
          />
          </div>
        ) : (<Export />)}
      </div>
      <div className='flex items-center space-x-2'>
        {project_phase && (
          <MapProjectStatusTag
            tag={project_phase}
            color={
              TAGS_MOCK.find((tag) => tag.name === project_phase)?.color ??
              '#5A72B1'
            }
            isClickable={false}
            isActive={false}
          />
        )}
        {discounts && !!discounts[0]?.discount_text && (
          <div>
            <div className='project_card_badge_container border'>
              <div className='daily_news_list_item_trans'>
                <div className='daily_news_icon'>
                  <TagIcon className='w-7 h-7 stroke-2' />
                </div>
                <div>
                  <b>{discounts[0].discount_text}</b>
                </div>
              </div>
            </div>
          </div>
        )}
        {news && (
          <div>
            <div className='project_card_badge_container border'>
              <div className='daily_news_list_item_trans'>
                <div className='daily_news_icon'>
                  <TagIcon className='w-7 h-7 stroke-2' />
                </div>
                <div style={{ paddingRight: '1em' }}>
                  <b>{news}</b>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className='flex space-x-4 items-center'>
        {address && (
          <div className='font-bold flex space-x-1 items-center w-1/2'>
            <MapPinIcon className='w-8 h-8 stroke-2' />
            <span className='truncate text-ellipsis'>
              {address}
            </span>
          </div>
        )}
        <div className='font-bold flex space-x-1 items-center w-1/2'>
          <UserGroupIcon className='w-8 h-8 stroke-2' />
          <span>
            {developer}
          </span>
        </div>
      </div>
    </div>
  );
};
