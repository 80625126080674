/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react/macro';
import { CalendarIcon, ChevronDownIcon } from '@heroicons/react/24/outline';
import { DatePicker, Spin } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoreActions, useStoreState } from '../../hooks';
import { formatDate } from '../../utils';
import { BOLD_FONT_WEIGHT, GREY_2 } from '../../styles/constants';
import { capitalize, getUpdateFrequencyDates } from '../../utils/utils';
import { getMarketDashboardDates } from '../../api';

const styles = {
  datePicker: css({
    input: {
      cursor: 'pointer',
      width: '90px !important',
      fontWeight: BOLD_FONT_WEIGHT,
      fontSize: '13.5rem',
      padding: 0,
    },
  }),
  updateFrequencyWrapper: css({
    padding: '0.5rem 2.5rem 1rem 2.5rem',
    color: GREY_2,
    fontWeight: '400',
    fontSize: '12px',
  }),
  updateFrequency: css({
    fontWeight: '700',
  }),
  updateFrequencyInfo: css({
    lineHeight: '16px',
    marginTop: '0.5rem',
    marginBottom: '1rem',
  }),
  updateFrequencyEmail: css({
    color: GREY_2,
    textDecoration: 'underline',
  }),
  wrapper: css({
    display: 'flex',
    alignItems: 'center',
  }),
};

type Props = {
  hideSuffixIcon?: boolean;
};

export const DatePickerFilter: FC<Props> = ({ hideSuffixIcon = false }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false); // Manage loading state
  const [initialParamsSet, setInitialParamsSet] = useState(false);
  const { paramsPM, overviewData } = useStoreState((state) => state.filters);
  const { market_dashboard_frequency = 'daily' } = overviewData || {};
  const setParamsPM = useStoreActions((actions) => actions.filters.setParamsPM);
  const { fetchDashboardDates } = useStoreActions((actions) => actions.market);
  const { dashboardDates } = useStoreState((state) => state.market);

  const validDates = useMemo(() => dashboardDates, [dashboardDates]);

  const handleCalendarOpenChange = (open: boolean): void => {
    if (open && paramsPM?.dashboardId) {
      setLoading(true);
      fetchDashboardDates({ dashboardId: paramsPM.dashboardId }).finally(() =>
        setLoading(false),
      );
    }
  };

  const compareDates = validDates[validDates.length - 1] === paramsPM?.date;

  useEffect(() => {
    if (
      !initialParamsSet &&
      paramsPM &&
      validDates.length > 0 &&
      !compareDates
    ) {
      setParamsPM({
        dashboardId: paramsPM.dashboardId,
        date: validDates[validDates.length - 1],
        name: paramsPM.name,
      });
      setInitialParamsSet(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMarketChange = (value: Dayjs | null): void => {
    if (value && paramsPM) {
      setParamsPM({
        dashboardId: paramsPM.dashboardId,
        date: formatDate(value.toDate()),
        name: paramsPM.name,
      });
    }
  };

  const handleMarketDisabledDate = (value: Dayjs): boolean =>
    !!!validDates.some((date) => formatDate(value.toDate()) === date);

  return (
    <>
      <CalendarIcon className='filters-icon' />
      <DatePicker
        css={styles.datePicker}
        bordered={false}
        value={dayjs.utc(paramsPM?.date ?? validDates[validDates.length - 1])}
        onChange={handleMarketChange}
        disabledDate={handleMarketDisabledDate}
        showToday={false}
        format='MMM D, YYYY'
        allowClear={false}
        suffixIcon={
          !hideSuffixIcon && (
            <ChevronDownIcon className='filters-chevron-icon' />
          )
        }
        renderExtraFooter={() =>
          loading ? (
            <div css={styles.updateFrequencyWrapper}>
              <Spin />
            </div>
          ) : (
            <div css={styles.updateFrequencyWrapper}>
              <div>
                {t('header.frequency.title', 'Update Frequency')}:{' '}
                <span css={styles.updateFrequency}>
                  {t(
                    `header.frequency.values.${
                      market_dashboard_frequency || 'daily'
                    }`,
                    capitalize(market_dashboard_frequency || 'daily'),
                  )}
                </span>
              </div>
              {!!market_dashboard_frequency &&
                market_dashboard_frequency !== 'daily' && (
                  <div css={styles.updateFrequencyInfo}>
                    {t(
                      'header.frequency.info',
                      'For more frequent updates reach out to',
                    )}{' '}
                    <a
                      css={styles.updateFrequencyEmail}
                      href='mailto:info@builtmind.com'
                    >
                      info@builtmind.com
                    </a>
                    .
                  </div>
                )}
            </div>
          )}
        onOpenChange={handleCalendarOpenChange}
      />
    </>
  );
};
