import { Action, action, computed, Computed, thunk, Thunk } from 'easy-peasy';
import { getPricingPriceListDetailData } from '../api';
import { StoreModel } from './types';
import { PriceListDetailData } from '../api/mockData/priceListDetailData';
import { logError } from '../utils/utils';
import { PriceDetailFilter } from '../api/enums';
import { PricingPriceListDetailData } from '../api/mockData/pricingPriceListDetaildata';

export interface PriceListDetailStore {
  pricePricingDetailData?: PricingPriceListDetailData;
  comparisonData?: PriceListDetailData[];
  showPredictionUpdated: Computed<PriceListDetailStore, boolean, StoreModel>;
  comparisonPredictionUpdated: Computed<
    PriceListDetailStore,
    number[],
    StoreModel
  >;
  setPricingPriceDetailData: Action<
    PriceListDetailStore,
    PricingPriceListDetailData
  >;
  setComparisonData: Action<PriceListDetailStore, PriceListDetailData[]>;
  fetchData: Thunk<
    PriceListDetailStore,
    number,
    unknown,
    StoreModel,
    Promise<void>
  >;
  fetchComparisonDataUpdated: Thunk<
    PriceListDetailStore,
    number[],
    unknown,
    StoreModel,
    Promise<PricingPriceListDetailData[] | undefined>
  >;
  priceFilter?: PriceDetailFilter;
  setPriceDetailFilter: Action<PriceListDetailStore, PriceDetailFilter>;
  comparisonDataUpdated?: PricingPriceListDetailData[];
  setComparisonDataUpdated: Action<
    PriceListDetailStore,
    PricingPriceListDetailData[]
  >;
}

export const priceListDetailStore: PriceListDetailStore = {
  showPredictionUpdated: computed(
    [
      (state) => state.pricePricingDetailData,
      (state, globalState) => globalState.user.profile?.prediction_availability,
    ],
    (data, predictionAvalability) => {
      if (data && predictionAvalability) {
        return predictionAvalability.includes(data.common_data.availability);
      }
      return false;
    },
  ),
  comparisonPredictionUpdated: computed(
    [
      (state) => state.comparisonDataUpdated,
      (state, globalState) => globalState.user.profile?.prediction_availability,
    ],
    (comparisonData, predictionAvalability) => {
      if (comparisonData && predictionAvalability) {
        return comparisonData
          .filter((cd) =>
            predictionAvalability.includes(cd.common_data.availability),
          )
          .map((cd) => cd.common_data.id);
      }
      return [];
    },
  ),
  setPricingPriceDetailData: action((state, data) => {
    state.pricePricingDetailData = data;
  }),
  setComparisonData: action((state, data) => {
    state.comparisonData = data;
  }),
  setComparisonDataUpdated: action((state, data) => {
    state.comparisonDataUpdated = data;
  }),
  fetchData: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
      filters: { setLoading },
    } = helpers.getStoreActions();
    const {
      filters: { projectId },
    } = helpers.getStoreState();

    try {
      setGlobalLoading(true);
      setLoading(true);
      if (projectId) {
        const pricingData = await getPricingPriceListDetailData(
          projectId,
          payload,
        );
        actions.setPricingPriceDetailData(pricingData.data);
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setLoading(false);
      setIsLoaded(true);
    }
  }),
  // eslint-disable-next-line consistent-return
  fetchComparisonDataUpdated: thunk(async (actions, payload, helpers) => {
    const {
      global: { setGlobalLoading, setIsLoaded },
      filters: { setLoading },
    } = helpers.getStoreActions();
    const {
      filters: { projectId },
    } = helpers.getStoreState();

    try {
      setGlobalLoading(true);
      setLoading(true);
      if (projectId) {
        const comparisonData = await Promise.all(
          payload.map(async (id) => {
            const { data } = await getPricingPriceListDetailData(projectId, id);
            return data;
          }),
        );
        actions.setComparisonDataUpdated(comparisonData);
        return comparisonData;
      }
    } catch (error) {
      logError(error);
    } finally {
      setGlobalLoading(false);
      setLoading(false);
      setIsLoaded(true);
    }
    return [];
  }),
  setPriceDetailFilter: action((state, data) => {
    state.priceFilter = data;
  }),
};
