/** @jsxImportSource @emotion/react */
import { FC, ReactNode, RefObject } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { Card, CardProps } from '../Card';
import { Zoom } from '../Zoom';
import { styles } from './styles';

interface Props extends CardProps {
  chart: ReactNode;
  controls?: ReactNode;
  infoPopoverContent?: ReactNode;
  infoPopoverTitle?: string;
  showZoom?: boolean;
  zoomInHeader?: boolean;
  ref?: RefObject<HTMLDivElement>;
  onPaginateLeft?: () => void;
  onPaginateRight?: () => void;
}

export const ChartCard: FC<Props> = ({
  chart,
  controls,
  infoPopoverContent,
  infoPopoverTitle,
  zoomInHeader = true,
  showZoom = true,
  extra,
  ref,
  onPaginateLeft,
  onPaginateRight,
  ...rest
}) => (
  <Card
    ref={ref}
    css={styles.card}
    infoPopoverContent={infoPopoverContent}
    infoPopoverTitle={infoPopoverTitle}
    // @ts-ignore
    dataMissing={chart === null}
    {...rest}
    titleExtra={
      <div className='flex items-center space-x-2'>
        {showZoom && (
          <div css={styles.searchContainer}>
            <Zoom>
              <Card css={styles.card} {...rest}>
                <div css={styles.controlsContainer}>{controls}</div>
                <div css={styles.chartContainer}>{chart}</div>
              </Card>
            </Zoom>
          </div>
        )}
        {extra}
      </div>
    }
  >
    <div css={styles.controlsContainer}>
      {controls}
      {!zoomInHeader && showZoom && (
        <div css={styles.searchContainer}>
          <Zoom>
            <Card css={styles.card} {...rest}>
              <div css={styles.controlsContainer}>{controls}</div>
              <div css={styles.chartContainer}>{chart}</div>
            </Card>
          </Zoom>
        </div>
      )}
    </div>
    {onPaginateRight || onPaginateLeft ? (
      <div css={styles.chartContainerPaginated}>
        {onPaginateLeft && <ChevronLeftIcon className='absolute left-0 top-[40%] chevron-paginate' onClick={onPaginateLeft} />}
        {chart}
        {onPaginateRight && <ChevronRightIcon className='absolute right-0 top-[40%] chevron-paginate' onClick={onPaginateRight} />}
      </div>
    ) : (
      <div css={styles.chartContainer}>{chart}</div>
    )}
  </Card>
);
