import { FC, useState } from 'react';
import { t } from 'i18next';
import { Card, Divider, Pagination, Spin } from 'antd';
import { PieChart } from 'react-minimal-pie-chart';
import { Square3Stack3DIcon } from '@heroicons/react/24/outline';
import { DefaultCardOptions } from './card/cardOptions/DefaultCardOptions';
import { useStoreState } from '../../hooks';
import { AVAILABLE, SOLD } from '../../styles/constants';
import { formatPercent } from '../../utils';

type FloorOverviewChartPieProps = {
  sold: number;
  total: number;
};

const FloorOverviewChartPie: FC<FloorOverviewChartPieProps> = ({
  total,
  sold,
}) => (
  <div className='grid grid-cols-2 items-center gap-4'>
    <div className='w-[24px] justify-self-end'>
      <PieChart
        lineWidth={60}
        startAngle={-90}
        animate
        className='w-[24px]'
        data={[
          { value: sold, color: AVAILABLE },
          { value: total - sold, color: SOLD },
        ]}
      />
    </div>
    <span className='font-semibold text-[1.2rem] text-left'>
      {formatPercent(sold / total, { round: 0 })}
    </span>
  </div>
);

export const FloorOverviewUpdated: FC = () => {
  const floor_overview = useStoreState(
    (state) => state.dashboard.dashboardData?.floor_overview,
  );
  const floor_count_overview = useStoreState(
    (state) => state.dashboard.dashboardData?.floor_count_overview,
  );
  const { includeReservations, dashboardLoading } = useStoreState(
    (state) => state.dashboard,
  );

  const isHouse = !!floor_count_overview;

  const data =
    floor_overview ??
    floor_count_overview?.map((f) => ({ ...f, floor: f.floor_count })) ??
    [];

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 12; // Floors to display per page

  const currentFloors = data?.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  const column1 = currentFloors ? currentFloors.slice(0, 6) : [];
  const column2 = currentFloors ? currentFloors.slice(6) : [];

  return (
    <Card bordered={false} className='flex flex-col h-full rounded-[1.6rem]'>
      <div className='flex items-center justify-between pb-8'>
        <div className='flex gap-4 items-center'>
          <div className='flex items-center bg-[#9EB9DA] rounded-full p-2'>
            <Square3Stack3DIcon className='stroke-2 w-10 h-10' />
          </div>
          <div className='font-bold'>{t('dashboard.floor_overview.title')}</div>
        </div>
        <DefaultCardOptions moreOptions />
      </div>
      {!dashboardLoading ? (
        <div className='flex flex-col items-center'>
          {/* Grid for Floors */}
          <div
            className={`grid grid-cols-${
              !!!column2.length ? '1' : '2'
            } gap-4 pt-8 text-center`}
          >
            {/* Column 1 */}
            <div className='grid grid-rows-7 h-[250px]'>
              {!!column1.length && (
                <div className='font-bold grid grid-cols-[1fr_2fr] gap-2 relative text-center whitespace-nowrap max-w-[300px]'>
                  <div>
                    {t(
                      `dashboard.floor_overview.columns.${
                        isHouse ? 'floor_count' : 'floor'
                      }`,
                    )}
                  </div>
                  <div>
                    {includeReservations
                      ? t('enums.state.sold_reserved')
                      : t('enums.state.sold')}
                  </div>
                  <Divider className='absolute' />
                </div>
              )}
              {column1.map(({ floor, availability_counts }) => (
                <div
                  key={floor}
                  className='grid grid-cols-[1fr_2fr] gap-2 text-center max-w-[300px]'
                >
                  <div>{floor}</div>
                  <div>
                    <FloorOverviewChartPie
                      sold={
                        includeReservations
                          ? availability_counts.sold +
                            availability_counts.reserved
                          : availability_counts.sold
                      }
                      total={availability_counts.total}
                    />
                  </div>
                </div>
              ))}
            </div>

            {!!column2.length && (
              <div className='grid grid-rows-7 h-[250px]'>
                <div className='font-bold grid grid-cols-[1fr_2fr] gap-2 relative text-center whitespace-nowrap max-w-[300px]'>
                  <div>{t('dashboard.floor_overview.columns.floor')}</div>
                  <div>
                    {includeReservations
                      ? t('enums.state.sold_reserved')
                      : t('enums.state.sold')}
                  </div>
                  <Divider className='absolute' />
                </div>
                {column2.map(({ floor, availability_counts }) => (
                  <div
                    key={floor}
                    className='grid grid-cols-[1fr_2fr] gap-2 text-center max-w-[300px]'
                  >
                    <div>{floor}</div>
                    <div>
                      <FloorOverviewChartPie
                        sold={
                          includeReservations
                            ? availability_counts.sold +
                              availability_counts.reserved
                            : availability_counts.sold
                        }
                        total={availability_counts.total}
                      />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
          {/* Pagination */}
          {data.length > itemsPerPage ? (
            <div className='flex justify-center mt-4'>
              <Pagination
                current={currentPage}
                total={data?.length || 0}
                pageSize={itemsPerPage}
                onChange={(page) => setCurrentPage(page)}
                showSizeChanger={false}
              />
            </div>
          ) : null}
        </div>
      ) : (
        <div className='flex w-full justify-center items-center'>
          <Spin spinning />
        </div>
      )}
    </Card>
  );
};
