import {
  ArrowsPointingOutIcon,
  ChevronDoubleUpIcon,
  ArrowsPointingInIcon,
  ChevronDoubleDownIcon,
} from '@heroicons/react/24/solid';
import {
  BuildingOfficeIcon,
  MagnifyingGlassIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Input, Popover } from 'antd';
import clsx from 'clsx';
import { FC, useState } from 'react';
import { useStoreActions, useStoreState } from '../../hooks';
import { IncludeIcon } from '../market/IncludeIcon';

type Props = {
  handleModalOpen?: () => void;
  openModal: boolean;
};

export const SimpleProjectsTable: FC<Props> = ({
  handleModalOpen,
  openModal,
}) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [includeAll, setIncludeAll] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { simplifiedProjects } = useStoreState((state) => state.market);

  const { handleInclude } = useStoreActions((actions) => actions.market);

  const handleIncludeAll = (): void => {
    handleInclude(simplifiedProjects.map(({ project_id }) => project_id));
  };

  const filteredSimplifiedProjects = simplifiedProjects.filter((project) =>
    project.project_name
      .toLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .startsWith(
        searchValue
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, ''),
      ),
  );

  const handleOpen = (): void => {
    setOpen((prev) => !prev);
  };

  return (
    <div className='relative pointer-events-auto min-h-[40px]'>
      {/* Dropdown Content */}
      {open && (
        <div className='absolute bottom-full left-0 w-full bg-white h-[500px] drop-shadow-lg border border-bmgrey rounded-t-[30px] pt-4 flex flex-col overflow-hidden truncate'>
          <div className='flex gap-4 border-b border-bmlightgrey px-8 py-2 text-bmblue font-bold'>
            <IncludeIcon value={includeAll} onClick={handleIncludeAll} />
            <Input
              className='outline-none border-none focus:outline-none'
              prefix={
                <MagnifyingGlassIcon color='#BDBDBD' className='w-[24px]' />
              }
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              placeholder={t('menu.search')}
            />
          </div>
          <div className='overflow-y-scroll flex flex-col'>
            {filteredSimplifiedProjects.map(
              ({ project_name, include, project_id }) => (
                <div
                  className={clsx(
                    include ? 'bg-[#E0E8F0]' : 'bg-white',
                    'flex gap-4 border-t border-bmlightgrey px-8 py-2 text-bmblue',
                  )}
                >
                  <>
                    <IncludeIcon
                      value={include || false}
                      onClick={() => handleInclude([project_id])}
                    />
                    <span className='cursor-pointer hover:text-[#9EB9DA] line-clamp-3'>
                      {project_name}
                    </span>{' '}
                  </>
                </div>
              ),
            )}
          </div>
        </div>
      )}

      {/* Main Content */}
      <div
        className={clsx(
          'px-8 py-2 flex justify-between bg-white drop-shadow-xl gap-[64px] items-center border-[1px] border-bmgrey h-full',
          !open ? 'rounded-[30px]' : 'rounded-bl-[30px] rounded-br-[30px]',
        )}
      >
        <div className='flex gap-[14px]'>
          <BuildingOfficeIcon width={24} color='#313B5E' className='stroke-2' />
          <span className='font-bold text-bmblue'>
            {t('market.reports.map_controls.projects', 'Projects')}
          </span>
        </div>
        <div className='flex gap-[14px]'>
          <Popover
            content={
              <span>
                {t(
                  `market.reports.map_controls.tooltips.${
                    !openModal ? 'open' : 'close'
                  }`,
                  !openModal ? 'Open' : 'Close',
                )}
              </span>
            }
          >
            <div>
              {!openModal ? (
                <ArrowsPointingOutIcon
                  className='text-bmlightgrey stroke-2 cursor-pointer'
                  width={24}
                  onClick={handleModalOpen}
                />
              ) : (
                <ArrowsPointingInIcon
                  className='text-bmlightgrey stroke-2 cursor-pointer'
                  width={24}
                  onClick={handleModalOpen}
                />
              )}
            </div>
          </Popover>
          <Popover
            content={
              <span>
                {t(
                  `market.reports.map_controls.tooltips.${
                    !open ? 'maximize' : 'minimize'
                  }`,
                  !open ? 'Maximize' : 'Minimize',
                )}
              </span>
            }
          >
            <div>
              {!open ? (
                <ChevronDoubleUpIcon
                  width={24}
                  className='stroke-2 cursor-pointer'
                  color='#BDBDBD'
                  onClick={handleOpen}
                />
              ) : (
                <ChevronDoubleDownIcon
                  width={24}
                  className='stroke-2 cursor-pointer'
                  color='#BDBDBD'
                  onClick={handleOpen}
                />
              )}
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
};
