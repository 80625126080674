/** @jsxImportSource @emotion/react */
import { FC, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import clsx from 'clsx';
import { InfoPopover } from '../../../InfoPopover';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { styles } from './styles';
import { PriceDetailFilter } from '../../../../api/enums';

interface Props {
  className?: string;
  houseAreaKey?: string;
  housePartKey: string | null;
  showMinus?: boolean;
  price?: number;
  popoverTitle?: ReactNode;
  valueContent: ReactNode;
  layoutValue?: boolean;
  popoverContent?: ReactNode;
  updatePrice?: number;
}

export const PriceBreakdownRow: FC<Props> = ({
  className,
  houseAreaKey,
  housePartKey,
  showMinus,
  popoverTitle,
  price: row_price,
  valueContent,
  layoutValue = false,
  popoverContent,
  updatePrice,
}) => {
  const { showPredictionUpdated } = useStoreState(
    (state) => state.priceListDetail,
  );

  const { formatCurrency } = useRegionFormatting();
  const { t } = useTranslation();
  const priceFilter = useStoreState(
    (state) => state.priceListDetail.priceFilter,
  );
  const isPercentage = priceFilter === PriceDetailFilter.PERCENTAGE;
  const showPrices = showPredictionUpdated;
  const price = isPercentage ? (row_price ?? 0) * 100 : row_price;
  const update = isPercentage ? (updatePrice ?? 0) * 100 : updatePrice;

  return (
    <div className={clsx(className, 'grid grid-cols-4 gap-10')}>
      <div css={styles.title}>
        {houseAreaKey && <b>{t(`enums.house_areas.${houseAreaKey}`)}</b>}
      </div>
      <div className='flex justify-between'>
        {housePartKey ? (
          <>
            <div>{t(`enums.house_parts.${housePartKey}`)}</div>
            <div css={styles.infoButton}>
              <InfoPopover
                popoverProps={{
                  content: popoverContent ?? (
                    <div css={styles.popoverContainer}>
                      <p>{t(`price_list.hints.${housePartKey}.desc`)}</p>
                    </div>
                  ),
                  title: popoverTitle ?? t(`enums.house_parts.${housePartKey}`),
                }}
              />
            </div>
          </>
        ) : null}
      </div>
      <div className='flex justify-between'>
        <div css={styles.value}>
          {layoutValue
            ? t(`enums.house_parts.layout_class.${valueContent}`)
            : valueContent}
        </div>
        <div css={styles.price}>
          {!!price && showPrices && (
            <>
              {showMinus && '-'}
              {isPercentage
                ? `${price.toFixed(1) ?? 0} %`
                : formatCurrency(price)}
            </>
          )}
        </div>
      </div>
      {/* Positive update price */}
      {!!update && update > 0 && showPrices && (
        <div className='flex justify-between'>
          <div css={styles.chevronPositive}>
            <CaretUpFilled />
          </div>
          <div css={styles.updatePricePositive}>
            {`+ ${
              isPercentage
                ? `${update.toFixed(1) ?? 0} %`
                : formatCurrency(
                    update,
                    false,
                    false,
                    false,
                    houseAreaKey === 'rounding',
                  )
            }`}
          </div>
        </div>
      )}
      {/* Negative update price */}
      {(!!update || houseAreaKey === 'rounding') &&
        update !== undefined &&
        update <= 0 &&
        showPrices && (
          <div className='flex justify-between'>
            <div css={styles.chevronNegative}>
              {!!update && <CaretDownFilled />}
            </div>
            <div css={styles.updatePriceNegative}>
              <>
                {`${
                  isPercentage
                    ? `${update.toFixed(1) ?? 0} %`
                    : formatCurrency(
                        update,
                        false,
                        false,
                        false,
                        houseAreaKey === 'rounding',
                      )
                }`}
              </>
            </div>
          </div>
        )}
    </div>
  );
};
