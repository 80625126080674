import { FC } from 'react';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Empty } from 'antd';
import { useTranslation } from 'react-i18next';
import { useFlatLayout, useStoreState } from '../../../../hooks';
import { SOLD } from '../../../../styles/constants';
import { translateBlockChartValue } from '../../../../utils/utils';
import { getColumnOptions } from '../../../../utils/defaultChartConfig';
import { Availability } from '../../../../store/types';
import { DaysOnMarketData } from '../../../../api/types';

interface Props {
  data: DaysOnMarketData[];
  isOverall: boolean;
  date: string | undefined;
}
export const DaysOnMarketChart: FC<Props> = ({ data, isOverall, date }) => {
  const { getLayout } = useFlatLayout();
  const { displayValues } = useStoreState((state) => state.filters);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const normalizedInputDate = date ? date.slice(0, 7) : undefined;
  const specificMonthData = normalizedInputDate
    ? data.find((d) => d.month.slice(0, 7) === normalizedInputDate)
        ?.by_layout || []
    : [];

  const chartData = isOverall
    ? data.map((d) => ({ ...d, series: Availability.SOLD }))
    : specificMonthData.map((layout) => ({
        ...layout,
        month: layout.layout,
        mean_days_on_market: layout.mean_days_on_market,
        series: Availability.SOLD,
      }));

  const translateValue = (value: string): string =>
    isOverall ? translateBlockChartValue(value, t) : getLayout(value);
  const config: ColumnConfig = getColumnOptions({
    smooth: true,
    data: chartData,
    color: SOLD,
    seriesField: 'series',
    xField: isOverall ? 'month' : 'layout',
    yField: 'mean_days_on_market',
    label: displayValues
      ? {
          position: 'top',
          style: {
            fill: '#000000',
            opacity: 0.6,
            padding: 2,
          },
        }
      : undefined,
    tooltip: {
      formatter: (datum) => {
        const isLayoutMode = 'layout' in datum;
        const label = isLayoutMode ? datum.layout : datum.month;
        const value = datum.mean_days_on_market ?? '-';
        return {
          name: label,
          value,
        };
      },
      title: (title) =>
        isOverall
          ? new Date(title).toLocaleDateString(language, {
              month: 'long',
              year: 'numeric',
            })
          : translateValue(title),
      customContent: (title, items) => {
        let htmlStr = `<div class="bm-tooltip"><div class="bmt-title">${title}</div><div class="bmt-items">`;
        items.forEach((item) => {
          htmlStr += `<div class="bmt-item">
              <div class="bmt-color" style="background-color: ${
                item?.color
              }"></div>
              <div class="bmt-label">${t('charts.days')}:</div>
              <div class="bmt-value">${item?.value}</div>
            </div>`;
        });
        htmlStr += '</div></div>';
        return htmlStr;
      },
    },
    xAxis: {
      label: {
        formatter: (value) =>
          isOverall
            ? new Date(value).toLocaleDateString(language, {
                month: 'short',
                year: 'numeric',
              })
            : translateValue(value),
      },
      line: { style: { lineWidth: 2 } },
      tickLine: { style: { lineWidth: 2 } },
    },
    yAxis: {
      max: Math.max(...chartData.map((d) => d.mean_days_on_market)) * 1.1,
      label: {
        formatter: (value) => value,
      },
    },
    legend: {
      itemName: {
        formatter: (value) =>
          isOverall
            ? translateBlockChartValue(value, t)
            : t('charts.state.sold', 'Sold'),
      },
      style: {
        fontSize: 14,
      },
      marker: {
        symbol: 'circle',
        style: {
          r: 12,
        },
      },
    },
  });
  return data.length > 0 ? (
    <Column {...config} />
  ) : (
    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  );
};
