// Define the type for columns
export interface Column {
  column_name: string;
  included: boolean;
}

// Default column data (this could be dynamic, or fetched from an API)
export const defaultColumns: Column[] = [
  { column_name: 'address', included: true },
  { column_name: 'layout', included: true },
  { column_name: 'floor', included: true },
  { column_name: 'availability', included: true },
  { column_name: 'developer', included: true },
  { column_name: 'price', included: true },
  { column_name: 'price_per_sm', included: true },
  { column_name: 'floor_area', included: true },
  { column_name: 'total_area', included: true },
  { column_name: 'city', included: true },
  { column_name: 'exterior_area', included: true },
  { column_name: 'unique_string', included: true },
  { column_name: 'project_name', included: true },
  { column_name: 'first_seen', included: true },
  { column_name: 'building', included: true },
  { column_name: 'category', included: true },
  { column_name: 'DOM', included: true },
  { column_name: 'orientation', included: true },
  { column_name: 'url', included: true },
  { column_name: 'parking_indoor_price', included: true },
  { column_name: 'parking_outdoor_price', included: true },
  { column_name: 'original_price', included: true },
  { column_name: 'equivalent_area', included: true },
  { column_name: 'sold_date', included: true },
  { column_name: 'sale_type', included: true },
  { column_name: 'id', included: true },
  { column_name: 'balcony_area', included: true },
  { column_name: 'garden_area', included: true },
  { column_name: 'terrace_area', included: true },
  { column_name: 'district', included: true },
  { column_name: 'municipality', included: true },
  { column_name: 'permit_regular', included: true },
  { column_name: 'postal_code', included: true },
  { column_name: 'gps_latitude', included: true },
  { column_name: 'gps_longitude', included: true },
  { column_name: 'ride_to_center', included: true },
  { column_name: 'public_transport_to_center', included: true },
  { column_name: 'building_use', included: true },
  { column_name: 'renovation', included: true },
  { column_name: 'heating', included: true },
  { column_name: 'windows', included: true },
  { column_name: 'partition_walls', included: true },
  { column_name: 'payments', included: true },
];

export const projectPageColumns: Column[] = [
  { column_name: 'id', included: true },
  { column_name: 'layout', included: true },
  { column_name: 'floor', included: true },
  { column_name: 'availability', included: true },
  { column_name: 'price', included: true },
  { column_name: 'price_per_sm', included: true },
  { column_name: 'floor_area', included: true },
  { column_name: 'total_area', included: true },
  { column_name: 'exterior_area', included: true },
  { column_name: 'equivalent_area', included: true },
  { column_name: 'baths', included: true },
  { column_name: 'garages', included: true },
  { column_name: 'building', included: true },
  { column_name: 'category', included: true },
  { column_name: 'DOM', included: true },
  { column_name: 'orientation', included: true },
  { column_name: 'first_seen', included: true },
  { column_name: 'url', included: true },
  { column_name: 'original_price', included: true },
];
