import dayjs from 'dayjs';
import { PlanData } from '../../../../api/mockData/sales';
import { getPeriod } from '../../../../utils/utils';

export const paginatePlan = (array: PlanData[], results: number, frequency: 'monthly' | 'quarterly' | 'semiannualy'): Record<number, PlanData[]> => {
  if (frequency === 'monthly') {
    const currentMonth = dayjs().month();
    let pageNumber = 1;
    let isCurrentMonthReached = false;

    return array.reduce((pagesCount: Record<number, PlanData[]>, item) => {
      const itemMonth = dayjs(item.month).month();

      if (!pagesCount[pageNumber]) {
        pagesCount[pageNumber] = [];
      }

      if (itemMonth === currentMonth) {
        if (!isCurrentMonthReached) {
          isCurrentMonthReached = true;
          if (pagesCount[pageNumber].length !== 0) {
            pageNumber += 1;
            pagesCount[pageNumber] = [];
          }
        }
      }

      if (pagesCount[pageNumber].length >= results) {
        pageNumber += 1;
        pagesCount[pageNumber] = [];
      }

      pagesCount[pageNumber].push(item);

      return pagesCount;
    }, {});
  }

  const groupedData: Record<string, PlanData[]> = {};
  array.forEach((item) => {
    if (!item.month) return;
    const period = getPeriod(item.month, frequency);
    if (!groupedData[period]) {
      groupedData[period] = [];
    }
    groupedData[period].push(item);
  });
  const mergedArray = Object.keys(groupedData).map((period) => {
    const items = groupedData[period];
    const merged: Partial<PlanData> = { period: period as string };
    merged.sales_plan_id = items[0].sales_plan_id;
    merged.plan_speed = items.reduce((acc, item) => acc + item.plan_speed, 0);
    merged.normal_speed = items.reduce((acc, item) => acc + item.normal_speed, 0);
    merged.realized_floor_area = items.reduce((acc, item) => acc + (item.realized_floor_area || 0), 0);
    merged.realized_revenue = items.reduce((acc, item) => acc + (item.realized_revenue || 0), 0);
    merged.realized_units = items.reduce((acc, item) => acc + item.realized_units, 0);
    merged.price_effect = items.reduce((acc, item) => acc + item.price_effect, 0) / items.length;

    return merged as PlanData;
  });

  return mergedArray.reduce((pagesCount: Record<number, PlanData[]>, item, index) => {
    const pageNumber = Math.floor(index / results) + 1;
    const pageNo = pagesCount[pageNumber] || [];
    pageNo.push(item);
    return { ...pagesCount, [pageNumber]: pageNo };
  }, {});
};

export const getPage = (pagesResults: Record<number, PlanData[]>): number => Object.values(pagesResults).findIndex((item) => item.find((i) => i.month === dayjs().format('YYYY-MM-01'))) + 1 || 1;

export const isCurrentPeriod = (
  period: string,
  frequencyPeriod: 'quarterly' | 'semiannualy',
): boolean => {
  const currentDate = dayjs();
  const currentYear = currentDate.year();
  const currentQuarter = Math.ceil((currentDate.month() + 1) / 3);
  const currentHalfYear = currentDate.month() < 6 ? 1 : 2;

  const inputYear = 2000 + parseInt(period.slice(-2), 10);
  let inputPeriod = 0;

  if (frequencyPeriod === 'quarterly') {
    inputPeriod = parseInt(period[1], 10);
    const isCurrent = inputYear === currentYear && inputPeriod === currentQuarter;
    return isCurrent;
  }

  if (frequencyPeriod === 'semiannualy') {
    inputPeriod = parseInt(period[1], 10);
    const isCurrent = inputYear === currentYear && inputPeriod === currentHalfYear;
    return isCurrent;
  }

  return false;
};

export const getFrequencyData = (array: PlanData[], frequency: 'quarterly' | 'semiannualy'): PlanData[] => {
  const getPeriodString = (month: string, frequencyPeriod: 'quarterly' | 'semiannualy'): string => {
    const date = dayjs(month);
    const year = date.year();
    const yearText = year.toString().slice(-2);
    if (frequencyPeriod === 'quarterly') {
      const quarter = Math.ceil((date.month() + 1) / 3);
      return `Q${quarter}/${yearText}`;
    }
      const halfYear = date.month() < 6 ? 1 : 2;
      return `H${halfYear}/${yearText}`;
  };

  const groupedData: Record<string, PlanData[]> = {};
  array.forEach((item) => {
    if (!item.month) return;
    const period = getPeriodString(item.month, frequency);
    if (!groupedData[period]) {
      groupedData[period] = [];
    }
    groupedData[period].push(item);
  });
  const mergedArray = Object.keys(groupedData).map((period) => {
    const items = groupedData[period];
    const merged: Partial<PlanData> = { period: period as string };
    merged.sales_plan_id = items[0].sales_plan_id;
    merged.plan_speed = items.reduce((acc, item) => acc + item.plan_speed, 0);
    merged.normal_speed = items.reduce((acc, item) => acc + item.normal_speed, 0);
    merged.realized_floor_area = items.reduce((acc, item) => acc + (item.realized_floor_area || 0), 0);
    merged.realized_revenue = items.reduce((acc, item) => acc + (item.realized_revenue || 0), 0);
    merged.realized_units = items.reduce((acc, item) => acc + item.realized_units, 0);
    merged.price_effect = items.reduce((acc, item) => acc + item.price_effect, 0) / items.length;

    return merged as PlanData;
  });

  return mergedArray;
};
