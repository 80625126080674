import { css } from '@emotion/react/macro';
import { BLUE_5, BOLD_FONT_WEIGHT, GREY_1 } from '../../styles/constants';

export const styles = {
  card: css({
    borderRadius: '1.6rem',
    '& .ant-card-head': {
      padding: '0.8rem 2.4rem',
    },
  }),
  titleContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  }),
  title: css({
    color: BLUE_5,
    fontWeight: BOLD_FONT_WEIGHT,
    fontSize: '2.4rem',
  }),
  extra: css({
    margin: 'auto 0',
  }),
  subtitle: css({ color: GREY_1, fontSize: '1.3rem', textWrap: 'wrap', display: 'flex', alignItems: 'center' }),
};
