import { FC, useEffect, useRef, useState } from 'react';
import { Col, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Page } from '../components';
import {
  useBreakpoints,
  useRegionFormatting,
  useStoreActions,
  useStoreState,
} from '../hooks';
import { Paths } from '../routes/Paths';
import {
  Preview,
  UnitMix,
  Comparison,
} from '../components/project';
import { BackLink } from '../components/BackLink';
import { Project } from '../api/mockData/projectData';
import { getProject } from '../api';
import { PricePerSmCalcType } from '../components/TopBar/SettingsPopover';
import { PDFExport } from '../components/project/Export';
import { downloadPDF } from './market/pdfDownload';
import { slugifyString } from '../utils/utils';
import { ScatterPlot } from '../components/project/ScatterPlot';
import { SaleAbsorption } from '../components/project/SaleAbsorption';
import { SaleSpeed } from '../components/project/SaleSpeed';
import { FloorOverview } from '../components/project/FloorOverview';
import { SaleDetail } from '../components/project/SaleDetail';
import { PriceHistory } from '../components/project/PriceHistory';
import { MarketProjectExtended } from '../store/types';
import { ProjectCard } from '../components/ProjectCard';
import { ProjectExport } from '../components/project/ProjectExport';
import { VATSwitch } from '../components/VATSwitch/VATSwitch';

export const ProjectDetailPage: FC = () => {
  const { isSmallScreen } = useBreakpoints();
  const [project, setProject] = useState<MarketProjectExtended | null>(null);
  const [data, setData] = useState<MarketProjectExtended | null>(null);
  const { projectId } = useParams();
  const pdfPage1Ref = useRef(null);
  const pdfPage2Ref = useRef(null);
  const [isLoadingDownload, setIsLoadingDownload] = useState(true);
  const pricePerSmCalculation = useStoreState(
    (state) =>
      state.user.profile?.price_per_sm_calculation as PricePerSmCalcType,
  );
  const { profile } = useStoreState((state) => state.user);
  useEffect(() => {
    const fetchData = async (): Promise<Project | null> => {
      const convertedProjectId = Number(projectId);
      if (!Number.isNaN(convertedProjectId)) {
        const fetchedData = await getProject(
          convertedProjectId,
          pricePerSmCalculation,
          profile?.VAT_included,
          profile?.reserved_as_sold,
        );
        setData(fetchedData.data as MarketProjectExtended);
      }
      return null;
    };
    fetchData();
  }, [projectId, pricePerSmCalculation]);
  const { calculateVatPrice, getMinMaxProjectPrice } = useRegionFormatting();
  useEffect(() => {
    if (!data) setProject(null);
    const { minPrice, maxPrice } = getMinMaxProjectPrice(data);
    const projectToLoad = !data
      ? null
      : {
          ...data,
          min_price_per_sm: minPrice,
          max_price_per_sm: maxPrice,
          discounts:
            data?.discounts
              ?.map((d) => ({
                date_start: new Date(d.date_start).getTime(),
                discount_text: d.discount_text,
              }))
              .sort((a, b) => b.date_start - a.date_start)
              .map((d) => ({ ...d, date_start: d.date_start.toString() })) ??
            [],
          parking_indoor_price: calculateVatPrice(data.parking_indoor_price, 1),
          parking_outdoor_price: calculateVatPrice(
            data.parking_outdoor_price,
            1,
          ),
          cellar_price: calculateVatPrice(data.cellar_price, 119),
        };
    setProject(projectToLoad);
  }, [data, calculateVatPrice, profile, projectId]);

  const showProject = !!data;
  const { t } = useTranslation();
  const fetchProject = useStoreActions(
    (actions) => actions.project.fetchProject,
  );
  useEffect(() => {
    const convertedProjectId = Number(projectId);
    if (!Number.isNaN(convertedProjectId)) {
      fetchProject(convertedProjectId);
    }
  }, [fetchProject, projectId]);

  useEffect(() => {
    setTimeout(() => setIsLoadingDownload(false), 5000);
  });

  const fileName = `bm-project-${slugifyString(data?.project_name || '')}`;

  return (
    <Page
      pageClassname='project-detail'
      title={
        <div className='flex items-center space-x-2'>
          <BackLink to={Paths.MARKET_PROJECTS}>
            <ArrowLeftOutlined /> {t('market.title')}
          </BackLink>
          {project && <div>/</div>}
          {project && <div>{project.project_name}</div>}
        </div>
      }
      filters={
        !isSmallScreen && (
          <Row justify='end'>
            <VATSwitch />
            <PDFExport
              onDownloadPDF={() => downloadPDF(fileName, pdfPage1Ref, pdfPage2Ref)}
              isLoadingDownload={isLoadingDownload}
              marginRight
            />
            <ProjectExport data={data?.price_list} />
          </Row>
        )
      }
    >
      {project && (
        <div className='space-y-5'>
          <Row gutter={[16, 16]} ref={pdfPage1Ref}>
            <Col xs={24} xl={12} xxl={8}>
              {showProject && <Preview data={project} />}
            </Col>
            <Col xs={24} xl={12} xxl={8}>
              {showProject && <ProjectCard data={project} />}
            </Col>
            <Col xs={24} xl={12} xxl={8}>
              <UnitMix />
            </Col>
            <Col xs={24} xl={12}>
              <SaleSpeed />
            </Col>
            <Col xs={24} xl={12}>
              <PriceHistory />
            </Col>
          </Row>
          <Row gutter={[16, 16]} ref={pdfPage2Ref}>
            <Col xs={24} xl={12}>
              <SaleAbsorption />
            </Col>
            <Col xs={24} xl={12}>
              <ScatterPlot />
            </Col>
            <Col xs={24} xl={12} xxl={8}>
              <Comparison />
            </Col>
            <Col xs={24} xl={12} xxl={8}>
              <FloorOverview />
            </Col>
            <Col xs={24} xl={12} xxl={8}>
              <SaleDetail />
            </Col>
          </Row>
        </div>
      )}
    </Page>
  );
};
