/** @jsxImportSource @emotion/react */
import { Col, Row } from 'antd';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRegionFormatting, useStoreActions, useStoreState } from '../../../../hooks';
import { Availability } from '../../../../store/types';
import {
  aggregatePriceListData,
  getMinMax,
  getReportExportFileName,
  getSumFromNotNullValues,
} from '../../../../utils/utils';
import { Card } from '../../../Card';
import { ProjectDetailTable } from '../../../map/ProjectDetail/Content/ProjectDetailTable';
import { styles } from './styles';
import { Chevron } from './Chevron';
import { statisticsApi } from '../../../../api/secondary';
import { CardPopover } from '../CardPopover';
import { downloadPNG } from '../../../../pages/market/pngDownload';
import { useMarketDetails } from '../../../../hooks/useMarketDetails';

export const SupplyOverview: FC = () => {
  const [isLoading, setLoading] = useState(true);
  const { isRentals } = useMarketDetails();
  const reportId = useStoreState((state) => state.secondaryMarket.activeReportId);
  const supplyOverviewData = useStoreState((state) => state.secondaryMarket.supplyOverview);
  const { paramsSM } = useStoreState((state) => state.filters);
  const { fetchSupplyOverview } = useStoreActions(
    (actions) => actions.secondaryMarket,
  );
  const [oldAvailable, setOldAvailable] = useState(0);
  const [oldPricePerSm, setOldPricePerSm] = useState<number | null>(0);
  const { formatCurrency, formatBigNumber, calculateVatPrice, sortByLayouts, formatAreaUnits, areaUnit } =
    useRegionFormatting();
  const { t } = useTranslation();
  const pngRef = useRef(null);

  const {
    tableData,
    priceListData,
  } = useMemo(() => {
    const mappedPricelist = supplyOverviewData?.availableUnitsLayout.map((data) => ({
      id: '',
      layout: `layout_${data.countOfRegularRooms}`,
      floor: null,
      available: data.units,
      availability: Availability.AVAILABLE,
      price: calculateVatPrice(
        data.price,
        120,
      ),
      price_per_sm: calculateVatPrice(
        data.pricePerSm,
        120,
      ),
      original_price: 0,
      floor_area: data.interiorSm,
      total_area: 0,
      exterior_area: null,
      equivalent_area: 0,
      baths: 0,
      garages: 0,
      building: '',
      category: '',
      orientation: '',
      DOM: 0,
      first_seen: '',
      url: '',
    })) || [{
      id: '',
      layout: '',
      floor: null,
      available: 0,
      availability: Availability.AVAILABLE,
      price: null,
      price_per_sm: null,
      original_price: 0,
      floor_area: 0,
      total_area: 0,
      exterior_area: null,
      equivalent_area: 0,
      baths: 0,
      garages: 0,
      building: '',
      category: '',
      orientation: '',
      DOM: 0,
      first_seen: '',
      url: '',
    }];
    const aggregatedTableData = aggregatePriceListData(mappedPricelist);
    return {
      tableData: sortByLayouts(aggregatedTableData)
        .map((obj) => ({
          ...obj,
          available: mappedPricelist.find((list) => list.layout === obj.layout)!.available,
        })),
      priceListData: mappedPricelist
        .map((obj) => ({
          ...obj,
          price: calculateVatPrice(Number(obj.price),
          Number(obj.floor_area),
          Number(obj.exterior_area),
          ),
          price_per_sm: calculateVatPrice(
            Number(obj.price_per_sm),
            Number(obj.floor_area),
            Number(obj.exterior_area),
          ),
        })),
      totalAvailableUnits: getSumFromNotNullValues(
        aggregatedTableData
          .map((pd) => pd.available),
      ),
      minMax: getMinMax(
        mappedPricelist
          .map((obj) =>
            calculateVatPrice(
              Number(obj.price_per_sm), Number(obj.floor_area), Number(obj.exterior_area),
            ),
          ),
        formatCurrency,
      ),
      projectNames: [''],
    };
  }, [supplyOverviewData?.availableUnitsLayout, sortByLayouts, formatCurrency, calculateVatPrice]);

  useEffect(() => {
    if (reportId && paramsSM?.date) {
      const date = new Date(paramsSM.date);
      const month = date.getMonth() - 1;
      const year = date.getFullYear();
      const day = date.getDay();
      const oldDate = new Date(year, month, day).toISOString().split('T')[0];
      setLoading(true);
      fetchSupplyOverview({ reportId, date: paramsSM.date })
        .finally(() => {
          setLoading(false);
        });

      statisticsApi.getAvailableUnits({ reportId, date: oldDate })
        .then((data) => setOldAvailable(data.availableUnits));

      statisticsApi.getAvailableUnitsPricePerSm({ reportId, date: oldDate })
        .then((data) => setOldPricePerSm(Number(data.availableUnitsPricePerSm)));
    }
  }, [fetchSupplyOverview, reportId, paramsSM]);

  const fileName = getReportExportFileName(paramsSM?.name || 'report', t('daily_news.kpi.supply', 'Daily News'));

  return (
    <div ref={pngRef} style={{ height: '100%' }}>
    <Card
      css={styles.card}
      loading={isLoading}
      dataMissing={supplyOverviewData === undefined}
    >
        <CardPopover onDownloadPNG={() => downloadPNG(fileName, pngRef)} />
        <Row className='reports_overview_header'>
        <Col xs={6}>
          <Row justify='center' className='title bold'>
            {t('daily_news.kpi.supply')}
          </Row>
          <Row justify='center' className='icon'>
            <div className='supply daily_news_kpi_icon'>
              <img
                src='/assets/building_icon_dark_blue_compressed.png'
                alt='house'
              />
            </div>
          </Row>
        </Col>
        <Col xs={9}>
          <Row justify='center'>
            {t('daily_news.kpi.available_units')}
          </Row>
          <Row justify='center' className='title big'>
            {supplyOverviewData?.totals?.units}
          </Row>
          <Row justify='center' className='subtitle'>
            <Chevron
              number={
                oldAvailable === 0 ? 0 : ((
                  ((supplyOverviewData?.totals?.units ?? 0) - oldAvailable)
                  / Math.abs(oldAvailable)) * 100)
                }
            />
          </Row>
        </Col>
        <Col xs={9}>
          <Row justify='center'>
            {isRentals
              ? t('rentals.rent_per_sm', 'Rent per {{unit}}', { unit: areaUnit })
              : t('daily_news.kpi.price_per_sm', 'Price per {{unit}}', { unit: areaUnit })}
          </Row>
          <Row justify='center' className='title big'>
            {formatBigNumber(calculateVatPrice(supplyOverviewData?.totals?.pricePerSm || null, 120))}
          </Row>
          <Row justify='center' className='subtitle'>
            <Chevron
              number={
                oldPricePerSm === 0 ? 0 : (
                  ((supplyOverviewData?.totals?.pricePerSm ?? 0) - (oldPricePerSm ?? 0))
                  / Math.abs(Number(oldPricePerSm))) * 100
                }
            />
          </Row>
        </Col>
        </Row>
      <div>
        <ProjectDetailTable
          loading={isLoading}
          priceList={priceListData}
          dataSource={tableData}
          customSummary={[
            {
              key: 'price',
              value: formatCurrency(
                calculateVatPrice(supplyOverviewData?.totals?.price || null, 120),
                ),
              },
              {
                key: 'price_per_sm',
                value: formatCurrency(
                  calculateVatPrice(supplyOverviewData?.totals?.pricePerSm || null, 120),
                  ),
                },
                {
                  key: 'available',
                  value: supplyOverviewData?.totals?.units || null,
                },
                {
                  key: 'interior_sm',
                  value: formatAreaUnits(supplyOverviewData?.totals?.interiorSm || null),
                },
              ]}
        />
      </div>
    </Card>
    </div>
    );
  };
