/* eslint-disable no-nested-ternary */
/** @jsxImportSource @emotion/react */
import { FC } from 'react';
import { Divider, Tooltip } from 'antd';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import { ChartBarIcon, ClockIcon, TagIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { ProjectType } from '../../../store/types';
import { styles } from '../styles';
import { useRegionFormatting, useStoreState } from '../../../hooks';
import { MinimalPieChart } from '../../../components/MinimalPieChart';
import { formatPercent } from '../../../utils';
import { K, M } from '../../../constants';

type SubtitleContainerUpdatedProps = {
  sale_start: string;
  sale_end: string;
  current_revenue: number;
  update_date: string;
  revenue_change_percentage: number;
  total_units: number;
  unavailable_units: number;
  time_progress_percentage: number;
  revenue_change: number;
  recommended_revenue: number;
};

export const SubtitleContainerUpdated: FC<SubtitleContainerUpdatedProps> = ({
  revenue_change_percentage,
  total_units,
  unavailable_units,
  sale_end,
  sale_start,
  time_progress_percentage,
  current_revenue,
  update_date,
  revenue_change,
  recommended_revenue,
}) => {
  const { t } = useTranslation();
  const { formatCurrency } = useRegionFormatting();
  const { projectType } = useStoreState((state) => state.filters);
  const isRent = projectType === ProjectType.RENT;

  const safeDate = (dateStr: string | undefined): Date | undefined => {
    if (!dateStr) return undefined;
    const parsedDate = new Date(dateStr);
    return Number.isNaN(parsedDate.getTime()) ? undefined : parsedDate;
  };

  const formattedSaleEnd = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: undefined,
    year: 'numeric',
  }).format(safeDate(sale_end));

  const formattedSaleEndTooltip = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(safeDate(sale_end));

  const formattedSaleStart = new Intl.DateTimeFormat('en-US', {
    month: '2-digit',
    day: undefined,
    year: 'numeric',
  }).format(safeDate(sale_start));

  const formattedSaleStartTooltip = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(safeDate(sale_start));

  const shortUpdateDateTooltip = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(safeDate(update_date));

  const formattedUpdateDate = new Intl.DateTimeFormat('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  }).format(safeDate(update_date));

  return (
    <div className='flex items-center justify-center gap-4 sm:gap-12 whitespace-nowrap'>
      <Tooltip
        autoAdjustOverflow
        overlay={
          <div className='flex gap-8'>
            <div className='font-bold'>
              <p>{t('enums.state.sold_reserved', 'Sold + Reserved')}</p>
              <p>{t('enums.state.available', 'Available')}</p>
            </div>
            <div>
              <p>
                {unavailable_units}/{total_units}
              </p>
              <p>
                {total_units - unavailable_units}/{total_units}
              </p>
            </div>
            <div>
              <p>
                {formatPercent(unavailable_units / total_units, {
                  round: 0,
                })}
              </p>
              <p>
                {formatPercent(
                  (total_units - unavailable_units) / total_units,
                  {
                    round: 0,
                  },
                )}
              </p>
            </div>
          </div>
        }
      >
        <div className='flex flex-col items-center'>
          <TagIcon className='w-8 stroke-2' />
          <span className='font-semibold pt-4'>
            {isRent
              ? t('price_update.subtitle_container.leased', 'Leased')
              : t(
                  'price_update.subtitle_container.sold_and_reserved',
                  'Sold + Reserved',
                )}
          </span>
          <div className='flex items-center gap-4 pt-1'>
            <MinimalPieChart total={total_units} sold={unavailable_units} />
            <span className='font-semibold text-[1.6rem]'>
              {formatPercent(unavailable_units / total_units, {
                round: 0,
              })}
            </span>
          </div>
          <span className='text-[1.2rem] pt-4'>{formattedUpdateDate}</span>
        </div>
      </Tooltip>
      <Divider type='vertical' css={styles.divider} />
      <Tooltip
        autoAdjustOverflow
        overlay={
          <div className='flex gap-8'>
            <div className='font-bold'>
              <p>{t('enums.state.start')}</p>
              <p>{t('enums.state.last_update')}</p>
              <p>{t('enums.state.end')}</p>
            </div>
            <div>
              <p>{formattedSaleStartTooltip}</p>
              <p>{shortUpdateDateTooltip}</p>
              <p>{formattedSaleEndTooltip}</p>
            </div>
            <div className='text-right'>
              <p>0%</p>
              <p>
                {formatPercent(time_progress_percentage, {
                  round: 0,
                })}
              </p>
              <p>
                {formatPercent(1, {
                  round: 0,
                })}
              </p>
            </div>
          </div>
        }
      >
        <div className='flex flex-col items-center'>
          <ClockIcon className='w-8 stroke-2' />
          <span className='font-semibold pt-4'>
            {isRent
              ? t(
                  'price_update.subtitle_container.full_occupancy_target',
                  'Full Occupancy Target',
                )
              : t(
                  'price_update.subtitle_container.time_progress',
                  'Time Progress',
                )}
          </span>
          <div className='flex items-center gap-4 pt-1'>
            <MinimalPieChart
              total={100}
              sold={time_progress_percentage * 100}
            />
            <span className='font-semibold text-[1.6rem]'>
              {formatPercent(time_progress_percentage, { round: 0 })}
            </span>
          </div>
          <span className='text-[1.2rem] pt-4'>{`${formattedSaleStart} → ${formattedSaleEnd}`}</span>
        </div>
      </Tooltip>
      <Divider type='vertical' css={styles.divider} />
      <Tooltip
        autoAdjustOverflow
        overlay={
          <div className='flex gap-8'>
            <div className='font-bold'>
              <p>{t('price_update.tooltip.current_revenue')}</p>
              <p>{t('price_update.tooltip.recommended_revenue')}</p>
              <p>{t('price_update.tooltip.revenue_change')}</p>
            </div>
            <div>
              <p>
                {formatCurrency(
                  current_revenue,
                  false,
                  Math.abs(current_revenue) > M,
                  Math.abs(current_revenue) < M &&
                    Math.abs(current_revenue) > K,
                )}
              </p>
              <p>
                {formatCurrency(
                  recommended_revenue,
                  false,
                  Math.abs(recommended_revenue) > M,
                  Math.abs(recommended_revenue) < M &&
                    Math.abs(recommended_revenue) > K,
                )}
              </p>
              <p>
                {formatCurrency(
                  revenue_change,
                  false,
                  Math.abs(revenue_change) > M,
                  Math.abs(revenue_change) < M && Math.abs(revenue_change) > K,
                )}
              </p>
            </div>
            <div className='text-right grid grid-rows-3'>
              <p> </p>
              <p> </p>
              <p>
                {revenue_change_percentage * 100 > 1 && '+'}
                {formatPercent(revenue_change_percentage)}
              </p>
            </div>
          </div>
        }
      >
        <div className='flex flex-col items-center'>
          <ChartBarIcon className='w-8 stroke-2' />
          <span className='font-semibold pt-4'>
            {isRent
              ? t(
                  'price_update.subtitle_container.annual_revenue_change',
                  'Annual Revenue Change',
                )
              : t(
                  'price_update.subtitle_container.revenue_increase',
                  'Revenue Increase',
                )}
          </span>
          <div className='flex items-center gap-4 pt-1'>
            <span className='font-semibold text-[1.6rem]'>
              {formatCurrency(
                revenue_change,
                false,
                Math.abs(revenue_change) > M,
                Math.abs(revenue_change) < M && Math.abs(revenue_change) > K,
              )}
            </span>
          </div>
          <span className='text-[1.2rem] pt-4'>
            {revenue_change_percentage * 100 > 0 && (
              <CaretUpFilled css={styles.subtitleRevenue} />
            )}
            {revenue_change_percentage * 100 < 0 && (
              <CaretDownFilled css={styles.subtitleRevenue} />
            )}
            {formatPercent(revenue_change_percentage)}
          </span>
        </div>
      </Tooltip>
    </div>
  );
};
