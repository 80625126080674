import { FC } from 'react';
import { VATSwitch } from '../VATSwitch/VATSwitch';
import { ProjectSelect } from '../ProjectSelect';

export const DashboardFilters: FC = () => (
  <div className='flex items-center'>
    <VATSwitch />
    <ProjectSelect />
  </div>
);
