import { FC } from 'react';
import { PriceBreakdownRow } from './PriceBreakdownRow';
import { useRegionFormatting } from '../../../hooks';

interface Props {
  recommendedPrice: number;
  isPercentage: boolean;
  priceUpdate: number;
  roundedUpdate: number;
}
export const Rounding: FC<Props> = ({
  recommendedPrice,
  isPercentage,
  priceUpdate,
  roundedUpdate,
}) => {
  const { applyRounding } = useRegionFormatting();

  const roundedPriceUpdate = parseFloat(priceUpdate.toFixed(1));
  const roundedUpdateValue = parseFloat((roundedUpdate * 100).toFixed(1));
  const roundedPercentageUpdate = roundedUpdateValue - roundedPriceUpdate;

  const roundedValue = applyRounding(recommendedPrice) - recommendedPrice;
  return (
    <PriceBreakdownRow
      className='row-spacing'
      houseAreaKey='rounding'
      housePartKey={null}
      valueContent={null}
      updatePrice={isPercentage ? roundedPercentageUpdate / 100 : roundedValue}
    />
  );
};
