import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Card, Spin, Tooltip } from 'antd';
import { PresentationChartLineIcon } from '@heroicons/react/24/outline';
import { PieChart } from 'react-minimal-pie-chart';
import { AVAILABLE, SOLD } from '../../../../styles/constants';
import { useRegionFormatting, useStoreState } from '../../../../hooks';
import { formatPercent } from '../../../../utils';
import { ProjectType } from '../../../../store/types';
import { DefaultCardOptions } from '../../card/cardOptions/DefaultCardOptions';

export const Metrics: FC = () => {
  const { t } = useTranslation();
  const { formatBigNumber } = useRegionFormatting();
  const { projectType } = useStoreState((state) => state.filters);
  const { availability_counts, current_revenue, recommended_price_update } =
    useStoreState(
      (state) =>
        state.dashboard.dashboardData?.key_metrics ?? {
          availability_counts: {
            sold: 0,
            reserved: 0,
            total: 0,
          },
          current_revenue: 0,
          recommended_price_update: 0,
        },
    );
  const { includeReservations, dashboardLoading } = useStoreState(
    (state) => state.dashboard,
  );

  const showUpdate = useMemo(
    () => recommended_price_update > 10,
    [recommended_price_update],
  );

  const { sold, reserved, total } = availability_counts;
  const isRent = projectType === ProjectType.RENT;
  const soldSum = includeReservations ? sold + reserved : sold;
  const title = includeReservations
    ? t('dashboard.kpi.sold_reserved', 'Sold + Reserved')
    : t('dashboard.kpi.sold', 'Sold');

  return (
    <Card bordered={false} className='flex flex-col h-full rounded-[1.6rem]'>
      <div className='flex items-center justify-between pb-8'>
        <div className='flex gap-4 items-center'>
          <div className='flex items-center bg-[#9EB9DA] rounded-full p-2 capitalize;'>
            <PresentationChartLineIcon className='stroke-2 w-10 h-10 ' />
          </div>
          <div className='font-bold'>{t('dashboard.kpi.key_metrics')}</div>
        </div>
        <DefaultCardOptions moreOptions />
      </div>
      {!dashboardLoading ? (
        <div
          className={clsx(
            showUpdate ? 'grid grid-cols-3' : 'grid grid-cols-2',
            'gap-4',
          )}
        >
          <div className='grid-rows-3 text-center'>
            <Tooltip
              placement='bottom'
              trigger='hover'
              title={
                <div>{t('dashboard.key_metrics.tooltip.sold_reserved')}</div>
              }
            >
              <span className='font-bold whitespace-nowrap'>
                {isRent ? t('dashboard.kpi.occupancy') : title}
              </span>
              <div className='flex gap-2 items-center text-center justify-center'>
                <div className='flex-shrink-0 w-[24px]'>
                  <PieChart
                    lineWidth={60}
                    startAngle={-90}
                    animate
                    className='w-[24px]'
                    data={[
                      { value: Math.round(soldSum), color: AVAILABLE },
                      { value: Math.round(total), color: SOLD },
                    ]}
                  />
                </div>
                <span className='font-bold text-[2.4rem]'>
                  {formatPercent(soldSum / total, { round: 0 })}
                </span>
              </div>
              <div className='text-[1.2rem]'>{`${soldSum}/${total}`}</div>
            </Tooltip>
          </div>

          <div className='grid-rows-3 text-center'>
            <Tooltip
              placement='bottom'
              trigger='hover'
              title={<div>{t('dashboard.key_metrics.tooltip.revenue')}</div>}
            >
              <div className='font-bold whitespace-nowrap'>
                {t('dashboard.kpi.revenue')}
              </div>
              <span className='font-bold text-[2.4rem]'>
                {formatBigNumber(current_revenue)}
              </span>
              <div className='text-[1.2rem]'>{t('dashboard.kpi.current')}</div>
            </Tooltip>
          </div>
          {showUpdate ? (
            <div className='grid-rows-3 text-center'>
              <Tooltip
                placement='bottom'
                trigger='hover'
                title={
                  <div>{t('dashboard.key_metrics.tooltip.price_update')}</div>
                }
              >
                <div className='font-bold whitespace-nowrap'>
                  {t('dashboard.kip.price_update')}
                </div>
                <span className='font-bold text-[2.4rem]'>
                  {formatBigNumber(recommended_price_update)}
                </span>
                <div className='text-[1.2rem]'>
                  {t('dashboard.kpi.recommended')}
                </div>
              </Tooltip>
            </div>
          ) : null}
        </div>
      ) : (
        <div className='flex w-full justify-center items-center'>
          <Spin spinning />
        </div>
      )}
    </Card>
  );
};
